import MaterialTable from 'material-table';
import React from 'react';
import moment from 'moment';
import { lightenHexColor } from '../../dwi-functions';
import { Box, TableRow, TableCell, TableBody, Tooltip, Typography } from '@material-ui/core';
import { NumericFormat } from 'react-number-format';
import { MdHelpOutline } from "react-icons/md";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { useToken } from '../../Login/TokenProvider';
import CurrencyFormat from 'react-currency-format';
import appVars from '../../appVars.json';

const fontSize = "'initial";











const TableCard = (props) => {
    const token = useToken();
    const admintoken = token.token;

    const calculateTotals = (filteredData) => {
        const totalRows = filteredData.length;
        const totalAmount = [];
        
        const totalImpressions = filteredData.reduce((sum, row) => sum + parseFloat(row.IMPRESSIONS), 0);
        const totalClicks = filteredData.reduce((sum, row) => sum + parseFloat(row.CLICKS), 0);
        const totalConversions = filteredData.reduce((sum, row) => sum + parseFloat(row.CONVERSIONS), 0);
        const totalRevenue = filteredData.reduce((sum, row) => sum + parseFloat(row.REVENUE), 0);
        const totalCost = filteredData.reduce((sum, row) => sum + parseFloat(row.COST), 0);

        // Avoid NaN by checking for zero and undefined values
        const avgCPC = totalClicks !== 0 ? totalCost / totalClicks : 0;
        const avgCPM = totalImpressions !== 0 ? (totalCost / totalImpressions) * 1000 : 0;
        const avgROAS = totalCost !== 0 ? totalRevenue / totalCost : 0;

        totalAmount.push(totalImpressions);
        totalAmount.push(totalClicks);
        totalAmount.push(totalClicks !== 0 ? (totalClicks / totalImpressions) * 100 : 0);
        totalAmount.push(totalConversions);
        totalAmount.push(totalClicks !== 0 ? (totalConversions / totalClicks) * 100 : 0);
        totalAmount.push(totalRevenue);
        totalAmount.push(totalConversions !== 0 ? totalRevenue / totalConversions : 0);
        totalAmount.push(avgCPC);
        totalAmount.push(avgCPM);
        totalAmount.push(totalCost);
        totalAmount.push(avgROAS);

        return totalAmount;
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: props.themeColor.primary,
            },
            secondary: {
                main: props.themeColor.secondary,
            },
        },
        props: {
            MuiTooltip: {
                arrow: true,
                placement: "bottom"
            }
        },
        overrides: {
            MuiInputAdornment: {
                root: {
                    color: props.themeColor.secondary,
                },
            },
            MuiIcon: {
                root: {
                    color: lightenHexColor(props.themeColor.secondary, 25),
                },
            },
            MuiOutlinedInput: {
                root: {
                    padding: 0,
                    background: lightenHexColor(props.themeColor.secondary, 45),
                },
                input: {
                    padding: 7,
                },
            },
            MuiPaper: {
                elevation2: {
                    boxShadow: 'none',
                },
                root: {
                    border: '0px solid ' + lightenHexColor(props.themeColor.secondary, 25),
                },
            },
            MuiTooltip: {
                tooltip: {
                    backgroundColor: lightenHexColor("#000000", 0),
                    color: "#fff",
                    fontSize: '.75rem',
                    border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0)
                },
                arrow: {
                    color: lightenHexColor("#000000", 0),
                }
            }
        },
    });

    const headerStyleObj = {
        textAlign: 'center',
        backgroundColor: lightenHexColor(props.themeColor.secondary, 0),
        color: lightenHexColor(props.themeColor.primary, 0),
        fontSize: fontSize,
        border: '1px solid ' + props.themeColor.secondary,
        paddingTop: 5,
        paddingBottom: 5,
        maxWidth: 75,
    };

    var cellStyle = {
        textAlign: 'center',
        padding: theme.spacing(1),
        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
    };


    var tableOptions = {
        rowStyle: (rowData, index) => ({
            textAlign: 'center',
            fontSize: fontSize,
            padding: 0,
            margin: 0,
            backgroundColor: index % 2 === 0 ? '#FFF' : lightenHexColor(props.themeColor.secondary, 50),
        }),
        cellStyle: { border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0), textAlign: 'center', padding: theme.spacing(1) },
        headerStyle: headerStyleObj,
        grouping: false,
        searchFieldVariant: 'outlined',
        exportButton: true,
        exportAllData: true,
        search: true,
        exportFileName: moment(new Date()).format('YYYYMMDD.hhmmss'),
        pageSize: props.count,
        pageSizeOptions: [10, 25, 50, 100],
        showTitle: false,
        toolbar: true,
        sort: true,
        ...props.options,
    };

    var cols = props.columns;
    // console.log('token', admintoken.token);












    cols.push(
        {
            title: (
                <Tooltip title={<div><Typography style={{ fontWeight: 900 }}>Impressions:</Typography><Typography>{appVars.tooltips.impression}</Typography></div>}>
                    <span>Impressions</span>
                </Tooltip>
            ),
            cellStyle: {
                textAlign: 'center',
                padding: theme.spacing(0),
                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
            },
            field: 'IMPRESSIONS', render: (rowdata) => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" />
        },
        {
            title: (
                <Tooltip title={<div><Typography style={{ fontWeight: 900 }}>Clicks:</Typography><Typography>{appVars.tooltips.click}</Typography></div>}>
                    <span>Clicks</span>
                </Tooltip>
            ),
            cellStyle: {
                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                textAlign: 'center',
                padding: theme.spacing(0),
                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
            },
            field: 'CLICKS',
            render: (rowdata) => (
                <div style={{ display: 'flex' }}>
                    <Box p={0} m={0} style={{ flex: '50%' }}>
                        <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                    </Box>
                </div>
            ),
        },
        {
            cellStyle: {
                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                textAlign: 'center',
                padding: theme.spacing(0),
                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
            },
            title: (
                <Tooltip title={<div><Typography style={{ fontWeight: 900 }}>CTR (click-through rate):</Typography><Typography>{appVars.tooltips.ctr}</Typography></div>}>
                    <span>CTR</span>
                </Tooltip>
            ),
            field: 'CTR',
            render: (rowdata) => (
                <div style={{ display: 'flex' }}>
                    <Box p={0} m={0} style={{ flex: '50%' }}>
                        <NumericFormat decimalScale={2} value={rowdata.CTR} displayType="text" suffix="%" />
                    </Box>
                </div>
            ),
        },
        {
            cellStyle: {
                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                textAlign: 'center',
                padding: theme.spacing(0),
                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
            },
            title: (
                <Tooltip title={<div><Typography style={{ fontWeight: 900 }}>Conversions:</Typography><Typography>{appVars.tooltips.conversion}</Typography></div>}>
                    <span>Conversions</span>
                </Tooltip>
            ),
            field: 'CONVERSIONS',
            render: (rowdata) => (
                <div style={{ display: 'flex' }}>
                    <Box p={0} m={0} style={{ flex: '50%' }}>
                        <NumericFormat thousandSeparator="," decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                    </Box>
                </div>
            ),
        },
        {
            cellStyle: {
                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                textAlign: 'center',
                padding: theme.spacing(0),
                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
            },
            title: (
                <Tooltip title={<div><Typography style={{ fontWeight: 900 }}>Conversions Rate:</Typography><Typography>{appVars.tooltips.conv_rate}</Typography></div>}>
                    <span>Conv %</span>
                </Tooltip>
            ),
            field: 'CONV_RATE',
            render: (rowdata) => (
                <div style={{ display: 'flex' }}>
                    <Box p={0} m={0} style={{ flex: '50%' }}>
                        <NumericFormat decimalScale={2} value={rowdata.CONV_RATE} displayType="text" suffix="%" />
                    </Box>
                </div>
            ),
        },
        {
            title: (
                <Tooltip title={<div><Typography style={{ fontWeight: 900 }}>Revenue:</Typography><Typography>{appVars.tooltips.revenue}</Typography></div>}>
                    <span>Revenue</span>
                </Tooltip>
            ),
            cellStyle: {

                textAlign: 'center',
                padding: theme.spacing(0),
                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
            }, field: 'REVENUE', type: 'currency', render: (rowdata) => <CurrencyFormat thousandSeparator="," value={parseFloat(rowdata.REVENUE).toFixed(2)} decimalScale={2} displayType="text" prefix="$" />
        },
        {
            title: (
                <Tooltip title={<div><Typography style={{ fontWeight: 900 }}>Average Gift:</Typography><Typography>{appVars.tooltips.avg_gift}</Typography></div>}>
                    <span>Avg Gift</span>
                </Tooltip>
            ),

            cellStyle: {

                textAlign: 'center',
                padding: theme.spacing(0),
                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
            }, field: 'avgGift', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={parseFloat(rowdata.avgGift).toFixed(2)} displayType="text" prefix="$" />
        },
        {
            title: (
                <Tooltip title={<div><Typography style={{ fontWeight: 900 }}>CPC (cost per click):</Typography><Typography>{appVars.tooltips.cpc}</Typography></div>}>
                    <span>CPC</span>
                </Tooltip>
            ),
            cellStyle: {

                textAlign: 'center',
                padding: theme.spacing(0),
                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
            },
            field: 'CPC',
            type: 'currency',
            render: (rowdata) => <NumericFormat hidden={(admintoken.token >= 0 ? false : true)} thousandSeparator="," value={rowdata.CPC} displayType="text" decimalScale={2} prefix="$" />,

        },
        {
            title: (
                <Tooltip title={<div><Typography style={{ fontWeight: 900 }}>CPM (cost per thousand impressions):</Typography><Typography>{appVars.tooltips.cpm}</Typography></div>}>
                    <span>CPM</span>
                </Tooltip>
            ),

            cellStyle: {

                textAlign: 'center',
                padding: theme.spacing(0),
                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
            },
            field: 'CPM',
            type: 'currency',
            render: (rowdata) => <NumericFormat hidden={(admintoken.token >= 0 ? false : true)} thousandSeparator="," value={rowdata.CPM} decimalScale={2} displayType="text" prefix="$" />,

        },
        {
            title: (
                <Tooltip title={<div><Typography style={{ fontWeight: 900 }}>Cost</Typography><Typography>{appVars.tooltips.cost}</Typography></div>}>
                    <span>Cost</span>
                </Tooltip>
            ),
            cellStyle: {

                textAlign: 'center',
                padding: theme.spacing(0),
                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
            },
            field: 'COST',
            type: 'currency',
            render: (rowdata) => <NumericFormat hidden={(admintoken.token >= 6 ? false : true)} thousandSeparator="," value={rowdata.COST} decimalScale={2} displayType="text" prefix="$" />,
            hidden: (admintoken.token >= 6 ? false : true)
        },
        {
            title: (
                <Tooltip title={<div><Typography style={{ fontWeight: 900 }}>ROAS (return on ad spend):</Typography><Typography>{appVars.tooltips.roas}</Typography></div>}>
                    <span>ROAS</span>
                </Tooltip>
            ),
            cellStyle: {

                textAlign: 'center',
                padding: theme.spacing(0),
                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
            },
            field: 'ROAS',
            type: 'number',

            render: (rowdata) => <NumericFormat hidden={(admintoken.token >= 0 ? false : true)} thousandSeparator="," decimalScale={2} value={rowdata.ROAS} displayType="text" />
        }
    );


















    return (
        <div>
            <ThemeProvider theme={theme}>
                <MaterialTable
                    {...props}
                    data={props.data}
                    columns={cols}
                    options={tableOptions}
                    title={props.title}
                    components={{
                        Body: (props2) => {
                            const totals = calculateTotals(props2.renderData);

                            return (
                                <>
                                    <TableBody>
                                        {props2.renderData.map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    {props2.columns.map((col, colIndex) => {

                                                        var k = '';

                                                        if (!props2.columns[colIndex].hidden) {
                                                            return (
                                                                <TableCell key={colIndex} style={col.cellStyle}>
                                                                    {props2.columns[colIndex].render ? props2.columns[colIndex].render(row) : row[props2.columns[colIndex].field]}
                                                                </TableCell>);
                                                        } else {


                                                            console.log(k);
                                                            return (

                                                                null

                                                            );
                                                        }
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                        <TableRow style={{ backgroundColor: lightenHexColor("#cecece", 0), textAlign: 'center' }}>
                                            <TableCell style={{ padding: theme.spacing(1), textAlign: 'right', border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0) }} colSpan={1}>Totals</TableCell>
                                            <TableCell style={cellStyle}>{<NumericFormat decimalScale={0} thousandSeparator="," value={totals[0]} displayType="text" />}</TableCell>
                                            <TableCell style={cellStyle}>{<NumericFormat decimalScale={0} thousandSeparator="," value={totals[1]} displayType="text" />}</TableCell>
                                            <TableCell style={cellStyle}>{<NumericFormat decimalScale={2} value={totals[1] / totals[0] * 100} displayType="text" suffix="%" />}</TableCell>
                                            <TableCell style={cellStyle}>{<NumericFormat decimalScale={0} thousandSeparator="," value={totals[3]} displayType="text" />}</TableCell>
                                            <TableCell style={cellStyle}>{<NumericFormat decimalScale={2} value={totals[3] / totals[1] * 100} displayType="text" suffix="%" />}</TableCell>
                                            <TableCell style={cellStyle}>{<NumericFormat decimalScale={2} thousandSeparator="," value={parseFloat(totals[5]).toFixed(2)} displayType="text" prefix="$" />}</TableCell>
                                            <TableCell style={cellStyle}>{<NumericFormat decimalScale={2} thousandSeparator="," value={totals[5] / totals[3]} displayType="text" prefix="$" />}</TableCell>
                                            {admintoken.token >= 0 ? <>
                                                <TableCell style={cellStyle}>{<NumericFormat decimalScale={2} thousandSeparator="," value={parseFloat(totals[7]).toFixed(2)} displayType="text" prefix="$" />}</TableCell>
                                                <TableCell style={cellStyle}>{<NumericFormat decimalScale={2} thousandSeparator="," value={parseFloat(totals[8]).toFixed(2)} displayType="text" prefix="$" />}</TableCell>
                                                {( admintoken.token >= 6 ? <TableCell style={cellStyle}>{<NumericFormat decimalScale={2} thousandSeparator="," value={parseFloat(totals[9]).toFixed(2)} displayType="text" prefix="$" />}</TableCell>:'')}
                                                <TableCell style={cellStyle}>{<NumericFormat decimalScale={2} thousandSeparator="," value={parseFloat(totals[10]).toFixed(2)} displayType="text" />}</TableCell>
                                            </> : null}
                                        </TableRow>
                                    </TableBody>
                                </>
                            );
                        },
                    }}
                />
            </ThemeProvider>
        </div>
    );
};

export default TableCard;
