import React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import {  lightenHexColor } from '../../dwi-functions';
import Chart from 'react-apexcharts'

const PieChartCard = (props) => {

    const labels = [];
    const series = []

    props.data.forEach(obj => {
        labels.push(obj.name);
        series.push(obj.count);
    });




    var options = {
        series: series,
        labels: labels,
        colors: (
            props.gender
                ? ["#54DEFD", "#D999B9", "#c4c4c4"]
                : ["#301a4b", "#54DEFD", "#86CB92", "#D999B9", "#FF6700"]
        )
    }




    return (
        <div>

            <Card>
                <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor.secondary, 0),padding:8 }} title={props.title}></CardHeader>
                <CardContent>
                    <Chart options={options} labels={labels} series={series} type="pie" width={'100%'} height={320} />
                </CardContent>
            </Card>
        </div>
    );
};

PieChartCard.propTypes = {

};

export default PieChartCard;
