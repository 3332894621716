import React, { useEffect, useState, useRef } from 'react';
import {
    Grid, Typography, MenuItem, FormControl, Snackbar, LinearProgress, Avatar, TextField, 
    Button, Dialog, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import _ from 'lodash';
import appVars from '../../../appVars.json';
import { Add } from '@material-ui/icons';
import moment from 'moment';
import MomentUtils from "@date-io/moment";
import { getCookie, lightenHexColor } from '../../../dwi-functions';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Editor } from '@tinymce/tinymce-react';

const fontSize = 'initial';

const Comments = (props) => {
    const location = useLocation();
    var id = location.pathname.split("/")[2];

    const theme = createTheme({
        palette: {
            primary: {
                main: props.themeColor.primary,
            },
            secondary: {
                main: props.themeColor.secondary,
            },
        },
        overrides: {
            MuiInputAdornment: {
                root: {
                    color: props.themeColor.primary
                }
            },
            MuiIcon: {
                root: {
                    color: props.themeColor.secondary
                }
            },
            MuiOutlinedInput: {
                root: {
                    padding: 0,
                    background: lightenHexColor(props.themeColor.primary, 75)
                },
                input: {
                    padding: 7
                }
            },
            MuiPaper: {
                elevation2: {
                    boxShadow: 'none'
                },
                root: {
                    border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 25),
                }
            }
        },
    });

    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState({});
    const [open, setOpen] = useState(false);
    const [editopen, setEditOpen] = useState(false);
    const [selectedComment, setSelectedComment] = useState({});
    const [selectedDate, setSelectedDate] = useState(props.sdate);
    const [viewType, setViewType] = useState('5af0a295-8b57-4ccb-8cc8-d54e5bb278e6');
    const admintoken = props.admintoken.token;
    const [msg, setMsg] = useState({
        show: false,
        message: ''
    });

    const editorRef = useRef(null);

    const log = () => {
        if (editorRef.current) {
            setComment({ ...comment, comment: editorRef.current.getContent() });
        }
    };
    const views = {
        '5af0a295-8b57-4ccb-8cc8-d54e5bb278e6': 'No',
        'a7edd5c0-7a77-49ed-86ad-6fe3066a0f59': 'Yes'
    };
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleViewTypeChange = (event) => {
        setViewType(event.target.value);
    };

    const columns = (admintoken >= 6 ?
        [
            { title: "Date", field: "createdAt", type: 'datetime', render: rowdata => <Typography style={{ textAlign: 'left', width: 250, fontSize: fontSize, padding: 0, margin: 0 }}>{new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit", timeZone: "UTC" }).format(new Date(rowdata.createdAt))} </Typography> },
            { title: "Owner", field: "view", render: rowdata => <Avatar variant="circular" src={appVars.api_url + '/imageFiles/' + rowdata.comment.owner + '.png'} /> },
            { title: "Show Client?", field: "view", lookup: views, render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, padding: 0, margin: 0 }}>{(rowdata.view === '5af0a295-8b57-4ccb-8cc8-d54e5bb278e6' ? 'No' : 'Yes')}</Typography> },
            { title: "Type", field: "type", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, padding: 0, margin: 0 }}>{rowdata.comment.type}</Typography> },
            { title: "Note", field: "comment", render: rowdata => <div style={{ textAlign: 'left', minWidth: 400, padding: 0, margin: 0 }} dangerouslySetInnerHTML={{ __html: rowdata.comment.comment }}></div> },
        ] :
        [
            { title: "Date", field: "createdAt", type: 'datetime', render: rowdata => <Typography style={{ textAlign: 'left', width: 250, fontSize: fontSize, padding: 0, margin: 0 }}>{new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit", timeZone: "UTC" }).format(new Date(rowdata.createdAt))} </Typography> },
            { title: "Owner", field: "view", render: rowdata => <Avatar variant="circular" src={appVars.api_url + '/imageFiles/' + rowdata.comment.owner + '.png'} /> },
            { title: "Note", field: "comment", render: rowdata => <div style={{ textAlign: 'left', minWidth: 400, padding: 0, margin: 0 }} dangerouslySetInnerHTML={{ __html: rowdata.comment.comment }}></div> },
        ]
    );

    const handleSave = () => {
        var k = {
            comment: editorRef.current ? editorRef.current.getContent() : '',
            type: props.type,
            owner: getCookie('_bu'),
            view: viewType,
            date: selectedDate
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "clientid": id,
            "comment": k,
            "createdAt": selectedDate,
            "view": viewType
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(appVars.api_url + "/comments", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

        setComment(k);
        fetchData(id);
        setOpen(false);
    };

    async function fetchData(idi) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "idi": idi,
            "sdate": props.sDate,
            "edate": props.eDate,
            "view": props.view
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res = await fetch(appVars.api_url + "/comments/get", requestOptions)
        res.json()
            .then(response => {

                let filteredData = props.type !== 'all' ?
                    _.filter(response, row => {
                        let commentObj = row.comment.type;
                        return _.includes(commentObj, props.type);
                    }) :
                    response;

                setComments(filteredData);
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMsg({ show: false, message: '' });
    };

    const handleEditSave = () => {
        var k = {
            comment: editorRef.current ? editorRef.current.getContent() : '',
            type: props.type,
            owner: props.admintoken.user,
            view: viewType,
            date: selectedDate
        };

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "clientid": id,
            "comment": k,
            "createdAt": selectedDate,
            "view": viewType,
            "pkRecID": selectedComment.pkRecID
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(appVars.api_url + "/comments", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

        setComment(k);
        fetchData(id);
        setEditOpen(false);
    };

    useEffect(() => {
        fetchData((props.id ? props.id : id));
    }, [props]);

    return (
        <div>
            <ThemeProvider theme={theme}>

                {(admintoken >= 6 ?
                    <Grid container style={{ margin: '1em auto' }}>
                        <Grid item sm={12}>
                            <Button variant="contained" style={{ backgroundColor: props.themeColor.secondary, color: props.themeColor.primary }} value="Add New" onClick={(e) => {
                                e.preventDefault();
                                setOpen(true);
                            }} startIcon={<Add></Add>}>Add Note</Button>
                        </Grid>
                    </Grid>
                    : "")}

                {(comments ?
                    <Grid container style={{ margin: '1em auto' }}>
                        <Grid item sm={12}>
                            <MaterialTable
                                actions={(admintoken >= 6 ? [
                                    {
                                        icon: 'delete',
                                        hidden: (admintoken >= 6 ? false : true),
                                        tooltip: 'Delete Comment',
                                        onClick: async (event, rowData) => {
                                            const myHeaders = new Headers();
                                            myHeaders.append("Content-Type", "application/json");

                                            const raw = JSON.stringify({
                                                "pkRecID": rowData.pkRecID
                                            });

                                            const requestOptions = {
                                                method: "POST",
                                                headers: myHeaders,
                                                body: raw,
                                                redirect: "follow"
                                            };

                                            const ress = await fetch(appVars.api_url + "/comments/delete", requestOptions)
                                            ress
                                                .json()
                                                .then((result) => {
                                                    setMsg({ show: true, message: result.status });
                                                    fetchData(rowData.clientid);
                                                })
                                                .catch((error) => console.error(error));
                                        }
                                    },
                                    {
                                        icon: 'edit',
                                        hidden: (admintoken >= 6 ? false : true),
                                        tooltip: 'Edit Comment',
                                        onClick: (event, rowData) => {
                                            setSelectedComment({ 'pkRecID': rowData.pkRecID, ...rowData.comment });
                                            setSelectedDate(rowData.createdAt);
                                            setEditOpen(true);
                                        }
                                    }
                                ] : '')}
                                options={{
                                    rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                    cellStyle: {
                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0), textAlign: 'center',
                                        padding: theme.spacing(1)
                                    },
                                    headerStyle: {
                                        textAlign: "center",
                                        backgroundColor: lightenHexColor(props.themeColor.secondary, 0),
                                        color: lightenHexColor(props.themeColor.primary, 0),
                                        fontSize: fontSize,
                                        border: '1px solid ' + props.themeColor.secondary,
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        maxWidth: 75
                                    },
                                    grouping: false,
                                    searchFieldVariant: "outlined",
                                    exportButton: true,
                                    exportAllData: true,
                                    search: true,
                                    paging: (comments.length > 10 ? true : false),
                                    exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                    pageSizeOptions: [10, 25, 50, 100],
                                    showTitle: false,
                                    toolbar: true,
                                    sort: true
                                }}
                                components={{
                                    Toolbar: props2 => (
                                        <div>
                                            <Grid container alignItems='center' style={{ background: "#fff", paddingLeft: 15, color: "#000" }}>
                                                <Grid item sm={6}>
                                                    <Typography variant='h6' color="secondary">{props2.title}</Typography>
                                                </Grid>
                                                <Grid item sm={6}>
                                                    <MTableToolbar {...props2} />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )
                                }}
                                localization={{
                                    body: {
                                        emptyDataSourceMessage: 'No notes to display'
                                    }
                                }}
                                title="Notes"
                                data={comments}
                                count={(comments.length > 10 ? 10 : comments.length)}
                                client={props.client}
                                themeColor={props.themeColor}
                                columns={columns}
                            />
                        </Grid>
                    </Grid>
                    : <Grid container spacing={2} alignItems="center">
                        <Grid alignItems='center' item sm={12} alignContent="center">
                            <Typography align='center'>Still Loading Metrics</Typography>
                            <LinearProgress style={{ margin: theme.spacing(1), color: props.themeColor.secondary }} />
                        </Grid>
                    </Grid>)}

                <Dialog maxWidth="md" fullWidth open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
                    <DialogTitle  style={{ background: props.themeColor.secondary, color: props.themeColor.primary, marginBottom: theme.spacing(1) }} id="form-dialog-title">Add Note</DialogTitle>
                    <DialogContent>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <Grid container spacing={1}>
                                <Grid item md={6} sm={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            select
                                            fullWidth
                                            size="small"
                                            label="Show Client?"
                                            id="view-type-select"
                                            value={viewType}
                                            variant="outlined"
                                            onChange={handleViewTypeChange}
                                        >
                                            <MenuItem value="">
                                                <em>Show Client?</em>
                                            </MenuItem>
                                            <MenuItem value={'5af0a295-8b57-4ccb-8cc8-d54e5bb278e6'}>No</MenuItem>
                                            <MenuItem value={'a7edd5c0-7a77-49ed-86ad-6fe3066a0f59'}>Yes</MenuItem>
                                        </TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <FormControl fullWidth>
                                        <DatePicker
                                            inputVariant="outlined"
                                            fullWidth
                                            size="small"
                                            variant="inline"
                                            format="YYYY-MM-DD"
                                            id="date-picker-inline"
                                            label="Note Date"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={12}>
                                    <FormControl fullWidth>
                                        <Editor
                                            id="comment"
                                            name="comment"
                                            label="Comment"
                                            onBlur={log}
                                            apiKey={appVars.tinyapi}
                                            onInit={(_evt, editor) => editorRef.current = editor}
                                            initialValue="<p>This is the initial content of the editor.</p>"
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                ],
                                                toolbar: 'undo redo | blocks | ' +
                                                    'bold italic forecolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:18px }'
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>


                        </MuiPickersUtilsProvider>




                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setOpen(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button variant="outlined" onClick={handleSave} color="secondary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog maxWidth="md" fullWidth open={editopen} onClose={() => setEditOpen(false)} aria-labelledby="form-dialog-title">
                    <DialogTitle 
                        style={{ background: props.themeColor.secondary, color: props.themeColor.primary, marginBottom: theme.spacing(1) }}
                        id="form-dialog-title">
                        Edit Note</DialogTitle>
                    <DialogContent>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <Grid container spacing={1}>
                                <Grid item sm={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            select
                                            fullWidth
                                            size="small"
                                            label="Show Client?"
                                            id="edit-view-type-select"
                                            value={viewType}
                                            variant="outlined"
                                            onChange={handleViewTypeChange}
                                        >
                                            <MenuItem value="">
                                                <em>Show Client?</em>
                                            </MenuItem>
                                            <MenuItem value={'5af0a295-8b57-4ccb-8cc8-d54e5bb278e6'}>No</MenuItem>
                                            <MenuItem value={'a7edd5c0-7a77-49ed-86ad-6fe3066a0f59'}>Yes</MenuItem>
                                        </TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6}>
                                    <FormControl fullWidth>
                                        <DatePicker
                                            inputVariant="outlined"
                                            fullWidth
                                            size="small"
                                            variant="inline"
                                            format="YYYY-MM-DD"
                                            id="edit-date-picker-inline"
                                            label="Note Date"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={12}>
                                    <FormControl fullWidth>
                                        <Editor
                                            id="comment"
                                            name="comment"
                                            label="Comment"
                                            onBlur={log}
                                            apiKey={appVars.tinyapi}
                                            onInit={(_evt, editor) => editorRef.current = editor}
                                            initialValue={selectedComment.comment || ''}
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                ],
                                                toolbar: 'undo redo | blocks | ' +
                                                    'bold italic forecolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:18px }'
                                            }}
                                        />
                                    </FormControl>





                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setEditOpen(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button variant="outlined" onClick={handleEditSave} color="secondary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={msg.show}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    message={msg.message}
                />
            </ThemeProvider>
        </div>
    );
};

export default Comments;
