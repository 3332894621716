import { Card, CardContent, CardHeader, Grid, Typography, LinearProgress, Tabs, Tab, Box } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import themeFile from '../../theme.json';
import ChartCard from '../../../chart-card';
import { NumericFormat } from 'react-number-format';
import TableCard from '../../../table-card';
import { lightenHexColor, getCookie } from '../../../../dwi-functions';
import moment from 'moment';
import Comments from '../../../../system/control/comments';
import appVars from '../../../../appVars.json';
const theme = createTheme(themeFile);

const fontSize = 'initial';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const StackAdapt = (props) => {
    const [creativeData, setCreativeData] = useState();
    const [campaignData, setCampaignData] = useState();
    const [prefilteredData, setPrefilteredData] = useState();
    const [data, setStackAdaptData] = useState();
    const [typeSelect, setTypeSelect] = useState([{ CAMPAIGN_TYPE: 'native' }]);
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(0);
    const [admintoken, setAdmin] = useState(sessionStorage.getItem('_buta'));


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    async function fetchReportData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "aid": props.advertiser_id,
            "sdate": props.sDate,
            "edate": props.eDate,
            "type": typeSelect
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res4 = await fetch(appVars.api_url + "/stackadapt/data", requestOptions);
        //  console.log(typeSelect);
        res4
            .json()
            .then((res4) => {
                setPrefilteredData(res4[0]);
                setStackAdaptData(res4[0]['data']);
                // setAccountData(res4[0]['accounts']);
                console.log(res4[0]);
                setTypes(res4[0]['types']);
                // setCustomers(res4[0]['accountnames']);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });

        const campTable = await fetch(appVars.api_url + "/stackadapt/campaigns", requestOptions);
        //    console.log(props);
        campTable
            .json()
            .then((campaigns) => {
                setCampaignData(campaigns);

            })
            .catch((err) => {
                console.log(err);
            });

        const creativeTable = await fetch(appVars.api_url + "/stackadapt/creatives", requestOptions);
        //    console.log(props);
        creativeTable
            .json()
            .then((creatives) => {
                setCreativeData(creatives);

            })
            .catch((err) => {
                console.log(err);
            });




    }

    useEffect(() => {
        // if(!data){
        fetchReportData();
        //  console.log(data);
        //  }
    }, [props, typeSelect]);





    return (
        <div >
            <ThemeProvider theme={theme}>



                {(data ?
                    <div>
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="fullWidth" scrollButtons="auto" style={{ marginBottom: theme.spacing(2) }}>
                            <Tab label="Native ADS" {...a11yProps(0)} />
                            <Tab label="Display ADS" {...a11yProps(1)} />
                            <Tab label="Notes" {...a11yProps(2)} />
                        </Tabs>
                        <TabPanel value={value} index={0}>

                            <Grid container spacing={2} alignContent="space-between">
                                <Grid style={{ display: 'none' }} item sm={6} xs={12}>
                                    <Card>
                                        <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor.primary, 0) }} title="Platform Expense"></CardHeader>
                                        <CardContent>
                                            <Typography align='center' variant="h4"><NumericFormat value={data.native.cost.count.toFixed(2)} thousandSeparator="," displayType="text" prefix="$" /></Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid style={{ display: 'none' }} item sm={6} xs={12}>
                                    <Card style={{ marginTop: theme.spacing(2) }}>
                                        <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor.primary, 0) }} title="Platform ROI"></CardHeader>
                                        <CardContent >
                                            <Typography align='center' variant="h4"><NumericFormat value={((data.native.revenue.count / data.native.cost.count) * 100).toFixed(2)} displayType="text" suffix="%" /></Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Impressions"
                                        data={data.native.impressions}
                                        client={props.client}
                                        color={props.themeColor}
                                    />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Clicks"
                                        data={data.native.clicks}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="CTR"
                                        data={data.native.ctr}
                                        client={props.client}
                                        color={props.themeColor}
                                    />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="CPC"
                                        data={data.native.cpc}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                            </Grid>
                            <Grid container spacing={2} alignContent="space-between">

                                <Grid item xl={3} sm={3} xs={12} >
                                    <ChartCard
                                        title="Conversions"
                                        data={data.native.conversions}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Revenue"
                                        data={data.native.revenue}
                                        client={props.client}
                                        color={props.themeColor}
                                    />


                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Conversion Rate"
                                        data={data.native.convrate}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Avg Gift"
                                        data={data.native.avgGift}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                            </Grid>



                            <Grid container spacing={2} alignContent="space-between">

                                <Grid item sm={12} xs={12} >
                                    {(campaignData ?
                                        <TableCard
                                            options={{
                                                rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                cellStyle: {
                                                    border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                    padding: theme.spacing(1)
                                                },
                                                headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 20), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                paging: (campaignData.native.length > 10 ? true : false),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            title="Campaigns"
                                            data={campaignData.native}
                                            count={(campaignData.native.length > 10 ? 10 : campaignData.native.length)}
                                            client={props.client}
                                            color={props.themeColor}
                                            columns={[
                                                { title: "Campaign Name", field: "CAMPAIGN", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CAMPAIGN}</Typography> },
                                                { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)

                                                    },
                                                    title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)
                                                    }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                },
                                                { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                { title: "Avg. Gift", field: "avgGift", type: 'currency' },
                                                { title: "CPM", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / (rowdata.IMPRESSIONS / 1000))).toFixed(2)} displayType="text" prefix="$" /> }
                                            ]}
                                        />
                                        : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                                </Grid>



                                <Grid item sm={12} xs={12} >
                                    {(creativeData ?
                                        <TableCard
                                            options={{
                                                rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                cellStyle: {
                                                    border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                    padding: theme.spacing(1)
                                                },
                                                headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 20), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                paging: (creativeData.native.length > 10 ? true : false),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            title="Creatives"
                                            data={creativeData.native}
                                            count={(creativeData.native.length > 10 ? 10 : creativeData.native.length)}
                                            client={props.client}
                                            color={props.themeColor}
                                            columns={[
                                                { title: "Creative", field: "CREATIVE", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CREATIVE}</Typography> },
                                                { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)

                                                    },
                                                    title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)
                                                    }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                },
                                                { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                { title: "Avg. Gift", field: "avgGift", type: 'currency' },
                                                { title: "CPM", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / (rowdata.IMPRESSIONS / 1000))).toFixed(2)} displayType="text" prefix="$" /> }
                                            ]}
                                        />
                                        : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                                </Grid>


                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1}>

                              <Grid container spacing={2} alignContent="space-between">
                                <Grid style={{ display: 'none' }} item sm={6} xs={12}>
                                    <Card>
                                        <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor.primary, 0) }} title="Platform Expense"></CardHeader>
                                        <CardContent>
                                            <Typography align='center' variant="h4"><NumericFormat value={data.display.cost.count.toFixed(2)} thousandSeparator="," displayType="text" prefix="$" /></Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid style={{ display: 'none' }} item sm={6} xs={12}>
                                    <Card style={{ marginTop: theme.spacing(2) }}>
                                        <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor.primary, 0) }} title="Platform ROI"></CardHeader>
                                        <CardContent >
                                            <Typography align='center' variant="h4"><NumericFormat value={((data.display.revenue.count / data.display.cost.count) * 100).toFixed(2)} displayType="text" suffix="%" /></Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Impressions"
                                        data={data.display.impressions}
                                        client={props.client}
                                        color={props.themeColor}
                                    />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Clicks"
                                        data={data.display.clicks}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="CTR"
                                        data={data.display.ctr}
                                        client={props.client}
                                        color={props.themeColor}
                                    />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="CPC"
                                        data={data.display.cpc}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                            </Grid>
                            <Grid container spacing={2} alignContent="space-between">

                                <Grid item xl={3} sm={3} xs={12} >
                                    <ChartCard
                                        title="Conversions"
                                        data={data.display.conversions}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Revenue"
                                        data={data.display.revenue}
                                        client={props.client}
                                        color={props.themeColor}
                                    />


                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Conversion Rate"
                                        data={data.display.convrate}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Avg Gift"
                                        data={data.display.avgGift}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                            </Grid>



                            <Grid container spacing={2} alignContent="space-between">

                                <Grid item sm={12} xs={12} >
                                    {(campaignData ?
                                        <TableCard
                                            options={{
                                                rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                cellStyle: {
                                                    border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                    padding: theme.spacing(1)
                                                },
                                                headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 20), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                paging: (campaignData.display.length > 10 ? true : false),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            title="Campaigns"
                                            data={campaignData.display}
                                            count={(campaignData.display.length > 10 ? 10 : campaignData.display.length)}
                                            client={props.client}
                                            color={props.themeColor}
                                            columns={[
                                                { title: "Campaign Name", field: "CAMPAIGN", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CAMPAIGN}</Typography> },
                                                { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)

                                                    },
                                                    title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)
                                                    }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                },
                                                { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                { title: "Avg. Gift", field: "avgGift", type: 'currency' },
                                                { title: "CPM", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / (rowdata.IMPRESSIONS / 1000))).toFixed(2)} displayType="text" prefix="$" /> }
                                            ]}
                                        />
                                        : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                                </Grid>



                                <Grid item sm={12} xs={12} >
                                    {(creativeData ?
                                        <TableCard
                                            options={{
                                                rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                cellStyle: {
                                                    border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                    padding: theme.spacing(1)
                                                },
                                                headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 20), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                paging: (campaignData.display.length > 10 ? true : false),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            title="Creatives"
                                            data={creativeData.display}
                                            count={(creativeData.display.length > 10 ? 10 : creativeData.display.length)}
                                            client={props.client}
                                            color={props.themeColor}
                                            columns={[
                                                { title: "Creative", field: "CREATIVE", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CREATIVE}</Typography> },
                                                { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)

                                                    },
                                                    title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)
                                                    }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                },
                                                { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                { title: "Avg. Gift", field: "avgGift", type: 'currency' },
                                                { title: "CPM", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / (rowdata.IMPRESSIONS / 1000))).toFixed(2)} displayType="text" prefix="$" /> },
                                                { title: "Cost", field: "COST", type: 'currency', hidden: (admintoken >= 10 ? false : true) }
                                            ]}
                                        />
                                        : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                                </Grid>


                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Comments eDate={props.eDate} sDate={props.sDate} color={props.color} view={(admintoken>=8 ? "5af0a295-8b57-4ccb-8cc8-d54e5bb278e6" : "a7edd5c0-7a77-49ed-86ad-6fe3066a0f59")}type="stackadapt"></Comments>
                        </TabPanel>

                    </div>
                    : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading {props.title} Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(8) }}></LinearProgress></div>)}


            </ThemeProvider>




        </div>
    );
};

StackAdapt.propTypes = {

};

export default StackAdapt;
