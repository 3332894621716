import { Card, CardContent, CardHeader, Chip, Grid, Typography, LinearProgress, Toolbar, Tabs, Tab, Box } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import themeFile from '../../theme.json';
import ChartCard from '../../../chart-card';
import { NumericFormat } from 'react-number-format';
import TableCard from '../../../table-card';
import { lightenHexColor, getCookie, darkenHexColor } from '../../../../dwi-functions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import appVars from '../../../../appVars.json';
import moment from 'moment';
import _ from 'lodash';
import Comments from '../../../../system/control/comments';

const fontSize = 'initial';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const Google = (props) => {
    const [value, setValue] = useState(0);
    const [creativeData, setCreativeData] = useState();
    const [topConversionData, setTopConversionData] = useState([]);
    const [adNetworkData, setAdNetworkData] = useState([]);
    const [searchData, setsearchData] = useState();
    const [keywordData, setkeywordData] = useState();
    const [campaignData, setCampaignData] = useState();
    const [data, setData] = useState();
    const [dataType, setDataType] = useState([{ CAMPAIGN_TYPE: "SEARCH" }]);
    const [loading, setloading] = useState(true);
    const [admintoken, setAdmin] = useState(props.admintoken);

    
    //console.log(props.admintoken);
    const theme = createTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchReportData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "aid": props.advertiser_id,
            "sdate": props.sDate,
            "edate": props.eDate,
            "account": props.which,
            "type": dataType
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };


        setAdNetworkData([{ CAMPAIGN_TYPE: "SEARCH" }, { CAMPAIGN_TYPE: "YOUTUBE" }]);


        const res4 = await fetch(appVars.api_url + "/google/paid/data", requestOptions);
        //    console.log(props);
        res4
            .json()
            .then((res4) => {
                setData(res4);

            })
            .catch((err) => {
                console.log(err);
            });

        const campTable = await fetch(appVars.api_url + "/google/paid/campaigns", requestOptions);
        //    console.log(props);
        campTable
            .json()
            .then((campaigns) => {
                setCampaignData(campaigns);

            })
            .catch((err) => {
                console.log(err);
            });


        const keywordTable = await fetch(appVars.api_url + "/google/paid/keywords", requestOptions);
        //  console.log(props);
        keywordTable
            .json()
            .then((keywords) => {
                setkeywordData(keywords);
                // console.log(keywords);
            })
            .catch((err) => {
                console.log(err);
            });



        const searchTable = await fetch(appVars.api_url + "/google/paid/search", requestOptions);
        //    console.log(props);
        searchTable
            .json()
            .then((search) => {


                var sd = _(search) // Filter out items where CONVERSIONS is 0
                    .groupBy('SEARCH')
                    .map((groupedData, key) => ({ SEARCH: key, CONVERSIONS: _.sumBy(groupedData, 'CONVERSIONS'), IMPRESSIONS: _.sumBy(groupedData, 'IMPRESSIONS'), CLICKS: _.sumBy(groupedData, 'CLICKS'), REVENUE: _.sumBy(groupedData, 'REVENUE'), COST: _.sumBy(groupedData, 'COST') }))
                    .value();
                setsearchData(sd);

                var cv = _(search).filter(item => item.CONVERSIONS !== 0) // Filter out items where CONVERSIONS is 0
                    .groupBy('SEARCH')
                    .map((groupedData, key) => ({ SEARCH: key, CONVERSIONS: _.sumBy(groupedData, 'CONVERSIONS'), CLICKS: _.sumBy(groupedData, 'CLICKS') }))
                    .value();
                setTopConversionData(cv);
            })
            .catch((err) => {
                console.log(err);
            });




    }

    useEffect(() => {
        // if(!data){
        fetchReportData();
        //console.log(data);
        //  }
    }, [props, dataType]);




    return (
        <div >
   


                {(data ?
                   <div style={{ paddingTop: theme.spacing(1) }} >
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="scrollable" scrollButtons="auto" style={{ marginBottom: theme.spacing(2) }}>
                            <Tab label={props.title} {...a11yProps(0)} />
                            <Tab label="Notes" {...a11yProps(1)} />
                        </Tabs>


                        <TabPanel value={value} index={0}>
                            <Grid container spacing={2} alignContent="space-between">
                                <Grid item xl={3} sm={3} xs={12}>
                                    <Toolbar style={{ padding: 0 }}>
                                        <Autocomplete
                                            options={adNetworkData}
                                            value={dataType}
                                            multiple={true}
                                            color="secondary"
                                            fullWidth
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        label={option.CAMPAIGN_TYPE}
                                                        {...getTagProps({ index })}
                                                        // Apply custom styles here
                                                        style={{ backgroundColor: props.themeColor.secondary, color: props.themeColor.primary }} // Example: blue background with white text
                                                    />
                                                ))
                                            }
                                            getOptionLabel={(option) => option.CAMPAIGN_TYPE} // Defines how options are rendered in the menu and what values are searched.

                                            renderInput={(params) => <TextField {...params} label="Choose an Ad Network" variant="outlined" />}
                                            // style={{ width: '100%' }} // Style the Autocomplete component.
                                            onChange={(e, newValue) => {
                                                e.stopPropagation();
                                                console.log(newValue);
                                                setDataType((newValue.length > 0 ? newValue : [{ CAMPAIGN_TYPE: "SEARCH" }]));
                                                //fetchReportData(newValue);
                                            }}
                                        />
                                    </Toolbar>
                                </Grid>

                                <Grid item xl={3} sm={3} xs={12}>
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}></Grid>
                                <Grid item xl={3} sm={3} xs={12}></Grid>

                                <Grid style={{ display: 'none' }} item sm={6} xs={12}>
                                    <Card>
                                        <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor.primary, 0) }} title="Platform Expense"></CardHeader>
                                        <CardContent>
                                            <Typography align='center' variant="h4"><NumericFormat value={data.cost.count.toFixed(2)} thousandSeparator="," displayType="text" prefix="$" /></Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid style={{ display: 'none' }} item sm={6} xs={12}>
                                    <Card style={{ marginTop: theme.spacing(0) }}>
                                        <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor.primary, 0) }} title="Platform ROI"></CardHeader>
                                        <CardContent >
                                            <Typography align='center' variant="h4"><NumericFormat value={((data.revenue.count / data.cost.count) * 100).toFixed(2)} displayType="text" suffix="%" /></Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Impressions"
                                        data={data.impressions}
                                        client={props.client}
                                        color={props.themeColor}
                                    />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Clicks"
                                        data={data.clicks}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="CTR"
                                        data={data.ctr}
                                        client={props.client}
                                        color={props.themeColor}
                                    />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="CPC"
                                        data={data.cpc}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                            </Grid>








                            <Grid container spacing={2} alignContent="space-between">

                                <Grid item xl={3} sm={3} xs={12} >
                                    <ChartCard
                                        title="Conversions"
                                        data={data.conversions}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Revenue"
                                        data={data.revenue}
                                        client={props.client}
                                        color={props.themeColor}
                                    />


                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Conversion Rate"
                                        data={data.convrate}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Avg Gift"
                                        data={data.avgGift}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                            </Grid>




                            <Grid container spacing={2} alignContent="space-between">
                                <Grid item sm={12} xs={12} >
                                    {(campaignData ?
                                        <TableCard
                                            options={{
                                                rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                cellStyle: {
                                                    border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                    padding: theme.spacing(1)
                                                },
                                                headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 0), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                paging: (campaignData.length > 10 ? true : false),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            title="Campaigns"
                                            data={campaignData}
                                            count={(campaignData.length > 10 ? 10 : campaignData.length)}
                                            client={props.client}
                                            color={props.themeColor}
                                            columns={[
                                                { title: "Campaign Name", field: "CAMPAIGN", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CAMPAIGN}</Typography> },
                                                { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)

                                                    },
                                                    title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)
                                                    }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                },
                                                { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                { title: "Avg. Gift", field: "avgGift", type: 'currency' },
                                                { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS)).toFixed(2)} displayType="text" prefix="$" /> }
                                            ]}
                                        />
                                        : <div ><Typography variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}

                                </Grid>
                                <Grid item sm={12} xs={12} >
                                    {(searchData ?
                                        <TableCard
                                            title="Search"
                                            data={searchData}
                                            count={(searchData.length > 10 ? 10 : searchData.length)}
                                            client={props.client}
                                            color={props.themeColor}
                                            options={{
                                                rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                cellStyle: { border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center', padding: theme.spacing(1), margin: 0 },
                                                headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 0), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                grouping: false,
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                searchFieldVariant: "outlined",
                                                paging: (searchData.length > 10 ? true : false),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                // pageSize: ,
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            columns={[
                                                { title: "Search Term", field: "SEARCH", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.SEARCH}</Typography> },
                                                { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)
                                                    }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)

                                                    },
                                                    title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)
                                                    }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                },
                                                { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                { title: "Avg. Gift", field: "avgGift", type: 'currency' },
                                                { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS)).toFixed(2)} displayType="text" prefix="$" /> }
                                            ]}
                                        />
                                        : <div ><Typography variant='h6' align='center'>Loading Search Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}

                                </Grid>
                                <Grid item sm={12} xs={12} >
                                    {(keywordData ?
                                        <TableCard
                                            title="Keyword"
                                            data={keywordData}
                                            count={(keywordData.length > 5 ? 5 : keywordData.length)}
                                            client={props.client}
                                            color={props.themeColor}
                                            options={{
                                                rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                cellStyle: { border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center', padding: theme.spacing(1), margin: 0 },
                                                headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 0), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                grouping: false,
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                searchFieldVariant: "outlined",
                                                paging: (keywordData.length > 5 ? true : false),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                // pageSize: ,
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            columns={[
                                                { title: "Keyword", field: "KEYWORD", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.KEYWORD}</Typography> },
                                                { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)
                                                    }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)

                                                    },
                                                    title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)
                                                    }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                },
                                                { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                { title: "Avg. Gift", field: "avgGift", type: 'currency' },
                                                { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS)).toFixed(2)} displayType="text" prefix="$" /> }
                                            ]}
                                        />
                                        : <div ><Typography variant='h6' align='center'>Loading Keyword Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}

                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <Comments eDate={props.eDate} sDate={props.sDate} color={props.themeColor.primary} admintoken={admintoken} view={(admintoken >= 6 ? "5af0a295-8b57-4ccb-8cc8-d54e5bb278e6" : "a7edd5c0-7a77-49ed-86ad-6fe3066a0f59")} type="google_paid"></Comments>
                        </TabPanel>

                    </div>
                    : <div style={{ paddingTop: theme.spacing(5) }} ><Typography style={{ color: props.themeColor.primary }} variant='h6' align='center'>Loading {props.title} Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


      




        </div >
    );
};

Google.propTypes = {

};

export default Google;
