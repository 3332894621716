import React, { useState } from 'react';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from './Navbar'
import 'typeface-roboto';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import themeFile from './theme.json';
import { getCookie } from './dwi-functions';
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom';
import DashBoard from './DashBoard';
import Login from './Login';
import Logout from './logout';
import SignUp from './SignUp';
import Clients from './clients';
import Reports from './reports';
import PasswordReset from './SignInSide/reset-password';
import PasswordResetForm from './SignInSide/password-reset';
import ClientView from './clients/single_client';
import SubclientViewer from './clients/subclientviewer/';
import NewClient from './clients/newclient';
import NewUser from './Users/Account/new-user';
import appVars from './appVars.json';
import ControlPanel from './settings/ControlPanel';
import Account from './Users/Account';
import ReactGA from "react-ga4";
import Users from './UserTable';
import DDMonthlyReport from './reports/donor-detectives/dd-monthly-report';
import GlobalReport from './reports/donor-detectives/global-report';
import { TokenProvider } from './Login/TokenProvider';
import ClientGridView from './clients/client-grid-view';
import Virtuous from './virtuous/upload';
import MonthlyReport from './reports/donor-detectives/monthly-report';
import SitesTypes from './settings/SiteTypes';
import ecommerce from './settings/SiteTypes/ecommerce';

const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1);
var id = getLastItem(window.location.href);

const theme = createTheme(themeFile);
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0, 0),
  },
}));

const site = {
  title: appVars.application
};
document.title = site.title;

function App() {
  const classes = useStyles();
  const [auth, setAuth] = useState(getCookie('_bu'));
  const [admintoken, setAdmin] = useState(getCookie('_buta'));
  ReactGA.initialize(appVars.ga4);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div>
          <TokenProvider>
            <Switch>
              <Route path="/mr" component={MonthlyReport} />
              <Route path="/monthly-report" component={DDMonthlyReport} />
              <Route path="/cl" render={(props) => <ClientGridView {...props} status={true} header={true} />} />
              <Route path="/register" component={SignUp} exact />
              <Route path="/login" component={Login} exact />
              <Route path="/password-reset" component={PasswordReset} exact />
              <Route path="/reset-password" component={PasswordResetForm} />


              {auth ? (
                <div className={classes.root}>
                  <Switch>
                    <Route path="/subclientviewer" component={SubclientViewer} />
                    <Route path="/global-report" >
                      <GlobalReport admintoken={admintoken} />
                    </Route>
                    <Route>
                      <Navbar clientid={id} siteTitle={site.title} />
                      <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <Route path="/" component={DashBoard} exact />
                        <Route path="/client" component={Clients} exact />
                        <Route path="/control" exact>
                          <ControlPanel admintoken={admintoken} />
                        </Route>
                        <Route path="/control/sites"  >
                          <SitesTypes admintoken={admintoken} />
                        </Route>


                        <Route path="/control/sites/ecommerce" component={ecommerce} />
                        <Route path="/client/new" component={NewClient} exact />
                        <Route path="/control/users/" component={Users} exact />
                        <Route path="/account" component={Account} />
                        <Route path="/control/users/new/" component={NewUser} exact />
                        <Route path="/reports" exact component={Reports} />
                        <Route path="/reports/virtuous/" exact component={Virtuous} />
                        <Route path="/logout" component={Logout} />
                        <Route path="/clientview" component={ClientView} />
                        <Route path="/user/new" component={NewUser} exact />
                      </main>
                    </Route>
                  </Switch>
                </div>
              ) : (
                <Redirect to="/login" />
              )}
            </Switch>
          </TokenProvider>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
