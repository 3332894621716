import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { lightenHexColor, darkenHexColor } from '../../dwi-functions';
import Chart from 'react-apexcharts';
import { Card, CardContent, CardHeader } from '@material-ui/core';

const StackedBarChartAgeComponent = (props) => {

    const labels = props.data.map(item => item.ageRange);
    const series = [{
        name: 'female',
        data: props.data.map(item => item.female)
    },
    {
        name: 'male',
        data: props.data.map(item => item.male)
    },
    {
        name: 'unknown',
        data: props.data.map(item => item.unknown)
    }];




    var options = {
        series: series,
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: false,
        },
        colors: ["#54DEFD", "#D999B9", "#c4c4c4"],
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
                colors: ['#fff']
            },
            formatter: function (val, opt) {
                return val
            },
            offsetX: 0,
            dropShadow: {
                enabled: true
            }
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: true,
            offsetY: -25,
            formatter: function (seriesName, opts) {
                return seriesName + ": " + opts.w.globals.series[opts.seriesIndex][opts.seriesIndex]
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                borderRadius: 10,
                borderRadiusApplication: 'around', // 'around', 'end'
                borderRadiusWhenStacked: 'last', // 'all', 'last'
                dataLabels: {
                    offsetX: 0,
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '10px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        xaxis: {
            categories: labels,
            stroke: {
                width: 5,
            },
            title: {
                text: 'Link Clicks'
            },
        },
        yaxis: {
            title: {
                text: 'Age Range'
            },
        },
        tooltip: {
            theme: 'dark',
            y: {
                formatter: function (val) {
                    return val + " Clicks"
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        }
    };



    return (
        <div >

            <Card>
                <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor.secondary, 0), padding: 8 }} title="LINK CLICKS BY DEMOGRAPHICS (AGE/GENDER)"></CardHeader>
                <CardContent >
                    <Chart options={options} labels={labels} series={options.series} width={'100%'} type='bar' height={500} />
                </CardContent>
            </Card>
        </div>
    );
};

StackedBarChartAgeComponent.propTypes = {

};

export default StackedBarChartAgeComponent;
