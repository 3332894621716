import React, { useState, useEffect } from "react";
import {
    IconButton, Grid, Card, CardHeader, CardMedia, CardContent, Button, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Typography, CardActionArea, CardActions, Divider, MenuItem
} from '@material-ui/core';
import appVars from '../../appVars.json';
import Prism from 'prismjs';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Add, Edit } from "@material-ui/icons";
import { useToken } from "../../Login/TokenProvider";


const useStyles = makeStyles((theme) => ({
    root: { padding: theme.spacing(1) },
    media: {
        height: 50,
        margin: theme.spacing(1),
        backgroundSize: 'contain',
        backgroundPosition: 'top center'
    }
}));

const SiteTypes = (props) => {


    const classes = useStyles();
    const [ecom, seteCom] = useState([]);
    const [isloading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const token = useToken();
    const admintoken = token.token.token;


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = (e) => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "site": document.getElementById('site').value,
            "trackable": document.getElementById('trackable').value,
            "link": document.getElementById('link').value,
            "payload": {
                "ct": "",
                "em": "",
                "fn": "",
                "ln": "",
                "ph": "",
                "st": "",
                "zp": "",
                "revenueLive": ""
            },
            "type": (props.type === 'ecommerce' ? "donation" : appVars.lightwerks + "website")
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        var url = (props.type === 'ecommerce' ? appVars.lightwerks + "/sites/donation/add" : appVars.lightwerks + "/sites/website/add");
        fetch(url, requestOptions)
            .then(response => response.text())
            .then(result => fetchData())
            .catch(error => console.log('error', error));



        setOpen(false);
    };






    async function fetchData() {
        var url = (props.type === 'ecommerce' ? appVars.lightwerks + "/sites/donation" : appVars.lightwerks + "/sites/website");



        const res = await fetch(url);
        res
            .json()
            .then((res) => {
                seteCom(res);
                setLoading(false);
                //console.log(res);
            });




    }

    useEffect(() => {
        fetchData();
        Prism.highlightAll();
    });


    return (
        <div className={classes.root} >

            {(admintoken >= 10 ? <Grid container spacing={2} xl={12} >
                <Grid item xs={12}>
                    <Button onClick={handleClickOpen} color="primary" startIcon={<Add></Add>}>Add New</Button>
                    <Divider style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}></Divider>
                </Grid>
            </Grid> : <Skeleton variant="rect" style={{ padding: theme.spacing(1),margin:theme.spacing(1)}} >Admin Access Needed</Skeleton>)}

            <Grid container spacing={0} xl={12}>

                {isloading ? (



                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((card, index) => (
                        <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={index}>
                            <Card>
                                <CardHeader
                                    title={<Skeleton animation="wave" width="40%" component="h5" style={{ marginBottom: 6 }} />}
                                >

                                </CardHeader>
                                <CardMedia>
                                    <Skeleton animation="wave" variant="rect" />
                                </CardMedia>
                                <CardContent>
                                    <Skeleton animation="wave" component="h1" width="65%" style={{ marginBottom: 6 }} />
                                    <Skeleton animation="wave" style={{ marginBottom: 6 }} />
                                    <Skeleton animation="wave" style={{ marginBottom: 6 }} />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))

                ) : (

                    <Grid container spacing={2} xl={12}>
                        {ecom.map((card, index) => (
                            <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={index}>
                                <Card>
                                    <CardHeader
                                        title={<Typography variant="body2" color="textPrimary" component="p">{card.site}</Typography>} />
                                    <CardActionArea>
                                        <CardMedia
                                            className={classes.media}
                                            image={appVars.lightwerks + '/imageFiles/' + card.pkRecID + '.png'}
                                            title={card.site}
                                        />
                                    </CardActionArea>
                                    <CardContent>

                                        <Typography variant="body2" color="primary" component="p">{new Date(card.lasttrack).toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        })}</Typography>
                                        <Typography variant="body2" color="primary" component="p">{card.trackable}</Typography>



                                    </CardContent>
                                    {(admintoken.token >= 10 ?
                                        <CardActions>
                                            <IconButton href={"/control/sites/ecommerce/" + card.pkRecID} aria-label="Edit">
                                                <Edit color="primary" />
                                            </IconButton>

                                        </CardActions>
                                        : '')}

                                </Card>
                            </Grid>
                        ))}
                    </Grid>


                )}
            </Grid>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add new {(props.type === 'ecommerce' ? "Ecommerce" : "Website")} Platform</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To add a new platform enter required information below.
                    </DialogContentText>
                    <TextField
                        required={true}
                        autoFocus
                        margin="dense"
                        id="site"
                        label="Platform Name"
                        type="text"
                        InputLabelProps={{
                            shrink: true
                        }}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="link"
                        label="Documentation Address"
                        type="text"
                        InputLabelProps={{
                            shrink: true
                        }}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        select
                        autoFocus
                        margin="dense"
                        id="platform"
                        label="Platform Type"
                        type="text"
                        InputLabelProps={{
                            shrink: true
                        }}
                        fullWidth
                        value={(props.type === 'ecommerce' ? "donation" : appVars.lightwerks + "website")}
                        variant="outlined"
                    >

                        <MenuItem value="website">Website Platform</MenuItem>
                        <MenuItem value="donation">Ecommerce Platform</MenuItem>

                    </TextField>
                    <TextField
                        select
                        autoFocus
                        margin="dense"
                        id="trackable"
                        label="Trackable?"
                        type="text"
                        InputLabelProps={{
                            shrink: true
                        }}
                        fullWidth
                        variant="outlined"
                    >

                        <MenuItem value="1">Yes</MenuItem>
                        <MenuItem value="0">No</MenuItem>

                    </TextField>
                    <TextField
                        defaultValue={JSON.stringify({
                            "ct": "",
                            "em": "",
                            "fn": "",
                            "ln": "",
                            "ph": "",
                            "st": "",
                            "zp": "",
                            "revenueLive": ""
                        })}
                        autoFocus
                        disabled={true}
                        margin="dense"
                        id="payload"
                        label="Default Payload"
                        type="text"
                        fullWidth
                        variant="outlined"
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default" variant="contained">Cancel</Button>
                    <Button onClick={handleSave} variant="contained" color="secondary" startIcon={<Add></Add>}>Add New</Button>
                </DialogActions>
            </Dialog>




        </div>
    );
};

SiteTypes.propTypes = {

};

export default SiteTypes;
