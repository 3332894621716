import React, { useState, useEffect } from 'react';
import appVars from '../../../appVars.json';
import TextField from '@material-ui/core/TextField';
import {Avatar, Box, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';



const UserSearch = (props) => {
    const [accountTeams, setaccountTeams] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);


    async function fetchData() {
        const atres = await fetch(appVars.api_url + "/users/");
        atres
            .json()
            .then((resss) => {
                setaccountTeams(resss);
            });

    }


    return (
        <Box className="hidden-xs-down" >
            <TextField variant='outlined' shrink select {...props} {...props.field}>
                {accountTeams.map((entry) => (
                    <MenuItem key={entry.userid} value={entry.userid}>

                        <Box display="flex" alignItems="center">
                            <ListItemIcon>
                                <Avatar variant="circular" alt={entry.name} src={appVars.api_url + '/imageFiles/' + entry.userid + '.png'} />
                            </ListItemIcon>
                            <ListItemText primary={entry.name} />
                        </Box>
                    </MenuItem>

                ))}
            </TextField>
        </Box>
    )
};

UserSearch.propTypes = {

};

export default UserSearch;
