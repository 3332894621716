import React, { useState } from 'react';
import { Button, Divider, Grid, Typography, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import appVars from '../../appVars.json';
import ClientListing from '../../clients/controls/client-search';
import { useTheme } from '@material-ui/core/styles';
import { lightenHexColor } from '../../dwi-functions'

const Upload = (prop) => {
    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [client, setclient] = useState();
    const theme = useTheme();

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileUpload = async () => {
        if (!file) {
            setUploadStatus('Please select a file to upload.');
            return;
        }
        setLoading(true);
        const formData = new FormData();
        formData.append('filesToUpload', file);
        formData.append('clientid', client);
        formData.append('cdate', new Date())

        try {
            await axios.post(appVars.api_url + '/upload/virtuous', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setUploadStatus('File uploaded successfully.');
            setLoading(false);
        } catch (error) {
            setUploadStatus(`Upload failed: ${error.response?.data?.message || error.message}`);
            setLoading(false);
        }
    };
    return (
        <div style={{ padding: theme.spacing(2), background: lightenHexColor(theme.palette.primary.main, 30), borderRadius: theme.spacing(1) }}>

            <Grid container spacing={4}  >
                <Grid item sm={12} >
                    <Typography variant="h5" color='primary' gutterBottom>Virtuous Upload</Typography>
                    <Divider></Divider>
                    {(loading ? <LinearProgress /> : uploadStatus && <div>{uploadStatus}</div>)}
                </Grid>

                <Grid item xs={12}>
                    <ClientListing updateClient={(e) => {
                        setclient(e.id);
                    }
                    } />
                </Grid>
                <Grid item xs={12} >

                    <input id="filesToUpload" type="file" onChange={handleFileChange} />
                    <input id="clientid" type="hidden" value={client} />


                    <Button variant="contained" disabled={loading} color="primary" onClick={handleFileUpload}>
                        Upload File
                    </Button>



                </Grid>
            </Grid>



        </div>
    );
};

Upload.propTypes = {

};

export default Upload;
