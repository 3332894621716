// Consolidating imports from '@material-ui/core'
import {
    AppBar,
    Button,
    Grid,
    TextField,
    Paper,
    Typography,
    LinearProgress,
    Tab,
    Tabs,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useTheme,
    makeStyles
} from '@material-ui/core';

// Importing React and hooks
import React, { useEffect, useState } from 'react';

// Importing custom utilities and components
import appVars from '../appVars.json';
import FileUploads from '../settings/file-uploads';
import {  lightenHexColor, darkenHexColor } from '../dwi-functions';
import Virtuous from '../virtuous/upload/';
import { useToken } from '../Login/TokenProvider';
import { useLocation } from 'react-router-dom';


var id = "f9de637d-6cb1-11eb-a1e0-0ab31cf82f57";



const reports = [
    {
        "name": "Aggregate Report",
        "url": "/global-report/" + id
    }
];

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}





const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

        maxWidth: 1600,
        margin: 'auto'
    },
    data: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    content: {
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
    }
}));




const Reports = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [tabs, setTabs] = useState({});
    const [hasError, setErrors] = useState(false);
    const [apiStats, setApiStats] = useState({
        "client_id": "",
        "total_records": 0,
        "name_percentage": 0,
        "city_percentage": 0,
        "state_percentage": 0,
        "zip_percentage": 0,
        "phone_percentage": 0,
        "email_percentage": 0,
        "fb_percentage": 0,
        "fbc_percentage": 0,
        "ga_percentage": 0,
        "sa_percentage": 0,
        "adwords_percentage": 0,
        "bing_percentage": 0
    });
    const [settings, setSettings] = useState({});
    const [profile, setProfile] = useState([]);
    const [adsets, setAdsets] = useState({});
    const [saveMsg, setSaveMsg] = useState("");
    const [saveSeverity, setSeverity] = useState("success");
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [tabsettingsOpen, setTabSettingsOpen] = useState(false);
    const token = useToken();
    const admintoken = token.token;
    const location = useLocation();
    var id = location.pathname.split("/")[2];

    const searchParams = new URLSearchParams(location.search);

    const MyInput = ({ field, form, ...props }) => {
        return <TextField shrink {...field} {...props} />;
    };



    const handleSettingsOpen = () => {
        setSettingsOpen(true);
    };

    const handleTabSettingsOpen = () => {
        setTabSettingsOpen(true);
    };

    const handleClose = () => {
        setSettingsOpen(false);
        setTabSettingsOpen(false);
    };

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {

        console.log(profile);

        settings.ad_sets[event.currentTarget.id].id = event.currentTarget.value;
        profile.settings = settings;
        setProfile(profile);
        setSettings(settings);

    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleColorChange = (event, newValue) => {
        profile.color = event.currentTarget.value;
        profile.settings = settings;
        setProfile(profile);
        // console.log(profile);
    };


    const childToParent = (childdata) => {
        var f = appVars.api_url + '/imageFiles/' + id + '.png';
        const img = document.getElementById('avatar');
        img.src = f; //console.log(f);
    };


    async function fetchData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cid": props.client_id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };




        const res2 = await fetch(appVars.api_url + "/apistats", requestOptions)
        res2
            .json()
            .then((res2) => {
                (res2 === null ? setApiStats({}) : setApiStats(res2))

            })
            .catch((err) => setErrors(true));

        profile.color = appVars.brandColor;
        setProfile(profile);

    }


    useEffect(() => {


        if (loading) {
            fetchData();
        }



    });
    return (
        <div style={{ margin: theme.spacing(2) }}>


            {(profile ?
                <Grid container spacing={2}>
                    <Grid item sm={12} >
                        <Grid container spacing={4}>
                            <Grid item sm={12} >
                                <Typography variant="h5" gutterBottom style={{ color: profile.color, borderBottom: "1px solid #000" }}>Reports</Typography>
                                <Grid container spacing={3}>
                                    {reports?.map(option => {
                                        return (
                                            <Grid item xl={2} sm={4}><Button style={{ color: "#fff", background: profile.color }} variant="contained" target='_BLANK' fullWidth href={option.url}>{option.name}</Button></Grid>
                                        );
                                    })}
                                </Grid>

                            </Grid>


                            <Grid item sm={12} >
                               
                                <Grid container spacing={3}>
                                    <Grid item xl={4} sm={4}>
                                    <Virtuous></Virtuous>
                                    </Grid>
                                </Grid>

                            </Grid>

                            {(admintoken >= 10 ?
                                <Grid item xs={12}>
                                    <Typography variant="h5" gutterBottom style={{ color: profile.color, borderBottom: "1px solid #000" }}>API Stats</Typography>
                                    <Grid container spacing={3}>


                                        <Grid item xl={2} sm={3}>
                                            <Paper style={{ color: "#fff", backgroundColor: lightenHexColor(profile.color, 30) }} className={classes.paper}>
                                                <Typography align="center" gutterBottom variant="body1">Meta Users:</Typography>
                                                <Typography align="center" gutterBottom variant="body2">{parseFloat(apiStats.fb_percentage).toFixed(2)}%</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xl={2} sm={3}>
                                            <Paper style={{ color: "#fff", backgroundColor: lightenHexColor(profile.color, 30) }} className={classes.paper}>
                                                <Typography align="center" gutterBottom variant="body1">Meta Clickers:</Typography>
                                                <Typography align="center" gutterBottom variant="body2">{parseFloat(apiStats.fbc_percentage).toFixed(2)}%</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xl={2} sm={3}>
                                            <Paper style={{ color: "#fff", backgroundColor: lightenHexColor(profile.color, 30) }} className={classes.paper}>
                                                <Typography align="center" gutterBottom variant="body1">Google Users:</Typography>
                                                <Typography align="center" gutterBottom variant="body2">{parseFloat(apiStats.ga_percentage).toFixed(2)}%</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xl={2} sm={3}>
                                            <Paper style={{ color: "#fff", backgroundColor: lightenHexColor(profile.color, 30) }} className={classes.paper}>
                                                <Typography align="center" gutterBottom variant="body1">Google Clickers:</Typography>
                                                <Typography align="center" gutterBottom variant="body2">{parseFloat(apiStats.adwords_percentage).toFixed(2)}%</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xl={2} sm={3}>
                                            <Paper style={{ color: "#fff", backgroundColor: lightenHexColor(profile.color, 30) }} className={classes.paper}>
                                                <Typography align="center" gutterBottom variant="body1">Stackadapt Users:</Typography>
                                                <Typography align="center" gutterBottom variant="body2">{parseFloat(apiStats.sa_percentage).toFixed(2)}%</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xl={2} sm={3}>
                                            <Paper style={{ color: "#fff", backgroundColor: lightenHexColor(profile.color, 30) }} className={classes.paper}>
                                                <Typography align="center" gutterBottom variant="body1">Bing Users:</Typography>
                                                <Typography align="center" gutterBottom variant="body2">{parseFloat(apiStats.bing_percentage).toFixed(2)}%</Typography>
                                            </Paper>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                : '')}



                        </Grid>

                    </Grid>

                </Grid>
                : <LinearProgress style={{ margin: theme.spacing(8) }}></LinearProgress>)}


            <Dialog
                open={settingsOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle style={{ background: lightenHexColor(profile.color, 10) }} id="alert-dialog-title">{<Typography variant="h5" gutterBottom>Ad Settings</Typography>}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <img id="avatar" src={appVars.api_url + '/imageFiles/' + id + '.png'} alt='' />
                        <FileUploads newid={id} childToParent={childToParent} ></FileUploads>
                        <MyInput size="small" onChange={handleColorChange} style={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(3) }} id="color" name="color" defaultValue={profile.color} placeholder="Brand Color" label="Brand Color" fullWidth />
                        <MyInput onChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} id="meta" name="meta" placeholder="" label="META Id" defaultValue={(settings.ad_sets ? settings.ad_sets.meta.id : '')} fullWidth />
                        <MyInput onChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} id="stackadapt" name="stackadapt" placeholder="" label="StackAdapt Id" defaultValue={(settings.ad_sets ? settings.ad_sets.stackadapt.id : '')} fullWidth />
                        <MyInput onChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} id="bing" name="bing" placeholder="" label="Bing Id" defaultValue={(settings.ad_sets ? settings.ad_sets.bing.id : '')} fullWidth />
                        <MyInput onChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} id="google_paid" name="google_paid" placeholder="" label="Google Paid Id" defaultValue={(settings.ad_sets ? settings.ad_sets.google_paid.id : '')} fullWidth />
                        <MyInput onChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} id="google_grant" name="google_grant" placeholder="" label="Google Grant Id" defaultValue={(settings.ad_sets ? settings.ad_sets.google_grant.id : '')} fullWidth />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        style={{ color: theme.palette.primary.contrastText, backgroundColor: darkenHexColor(profile.color, 20) }}
                        onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        style={{ color: theme.palette.primary.contrastText, backgroundColor: profile.color }}
                        onClick={(e) => {

                            // console.log('raw');
                            var sett = (typeof profile.settings === 'object' && profile.settings !== null ? profile.settings : JSON.parse(profile.settings));
                            var ads = (typeof profile.tabs === 'object' && profile.tabs !== null ? profile.tabs : JSON.parse(profile.tabs));

                            var data = profile;

                            delete data.updatedAt;
                            delete data.createdAt;
                            data.settings = JSON.stringify(sett);
                            data.tabs = JSON.stringify(ads);

                            //setProfile(profile);
                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/json");
                            var raw = JSON.stringify(data);
                            //console.log(raw);
                            //  return false;

                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: raw,
                                redirect: 'follow'
                            };

                            fetch(appVars.api_url + "/reportprofile/", requestOptions)
                                .then(response => response.text())
                                .then(result => setSettingsOpen(false))
                                // .then(result =>window.location.reload()) 
                                .catch(error => console.log('error', error));







                        }}

                        variant="outlined">Save</Button>
                </DialogActions>
            </Dialog>




            <Dialog
                open={tabsettingsOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{ background: lightenHexColor(profile.color, 10) }} id="alert-dialog-title">{<Typography variant="h5" gutterBottom>Tab Settings</Typography>}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <AppBar position="relative" variant="outlined" >
                            {(tabs.tabs ?
                                <Tabs orientation="horizontal" variant="fullWidth" value={value} onChange={handleTabChange} aria-label="simple tabs example">
                                    {Object.entries(tabs.tabs).map((tab, index) => {
                                        // console.log(tab);
                                        return (
                                            <Tab label={tab[1]['label']} {...a11yProps(index)} />
                                        )
                                    })}


                                </Tabs>
                                : <LinearProgress></LinearProgress>
                            )}



                        </AppBar>


                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        style={{ color: theme.palette.primary.contrastText, backgroundColor: darkenHexColor(profile.color, 20) }}
                        onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        style={{ backgroundColor: profile.color }}
                        onClick={(e) => {
                            console.log(profile);
                        }}

                        variant="outlined">Save</Button>
                </DialogActions>
            </Dialog>








        </div>
    );
};

Reports.propTypes = {

};

export default Reports;