import { Box, Grid, useMediaQuery, Snackbar } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import appVars from '../../../appVars.json';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Google from './google';
import StackAdapt from './stackadapt';
import Meta from './meta';
import Bing from './bing';
import Virtuous from '../../../virtuous/report';
import Registrations from '../../../clients/registrations';
import { lightenHexColor, darkenHexColor } from '../../../dwi-functions';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import ReportSettings from '../../../settings/report-settings';
import Overview from '../monthly-report/overview';
import Navbar from '../global-report/GlobalReportNavbar';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

// Utility function to get the first day of the previous month
function getFirstDayOfPreviousMonth() {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    return firstDay.toISOString().split('T')[0];
}

// Utility function to get the last day of the previous month
function getLastDayOfPreviousMonth() {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    return lastDay.toISOString().split('T')[0];
}





const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex'
    },
    data: {
        // paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1)
    },
    cal: {
        position: 'absolute'
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '.75rem'
        }
    },
    logos: {
        width: '100%',
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        boxShadow: '0px 4px 8px rgba(93, 93, 93, .25)',
        [theme.breakpoints.up('xs')]: {
            maxWidth: 150
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    content: {
        flexGrow: 1,
        top: 100,
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
    },
    sidebar: {
        [theme.breakpoints.up('sm')]: {
            minHeight: 'calc(100vh - 90px)'
        },
    },
    tabs: {
        "& .MuiTab-wrapper": {
            flexDirection: "row",
            justifyContent: "flex-start!important"
        }
    },
    dataMargin: {
        paddingLeft: 1,
        paddingRight: 1,
        [theme.breakpoints.up('xs')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0)
        },
    },
    selected: {
        // Define your active tab color here
        background: "linear-gradient(90deg,rgba(255,255,255,.5) 30%, #fff 100%)",
        color: '#000',
        fontSize: '101%'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar

        minHeight: 100
    }
}));



const GlobalReport = (props) => {

    // eslint-disable-next-line
    const [order, setorder] = useState([
        { overview: 'Overview' },
        { google_paid: 'Google Paid' },
        { google_grant: 'Google Grant' },
        { bing: 'Bing' },
        { meta: 'Meta' },
        { stackadapt: 'Display' },
        { virtuous: 'Virtuous' }
    ]);

    const location = useLocation();
    var id = location.pathname.split("/")[2];

    const searchParams = new URLSearchParams(location.search);
    const sdate = searchParams.get('sdate');
    const edate = searchParams.get('edate');
    const tab = (searchParams.get('tab') ? Number(searchParams.get('tab')) : 0)
    var _d = sdate ? new Date(sdate).toISOString().split('T')[0] : getFirstDayOfPreviousMonth;
    var _e = edate ? new Date(edate).toISOString().split('T')[0] : getLastDayOfPreviousMonth;

    const [selectedDateS, setSelectedDateS] = useState(_d);
    const [selectedDateE, setSelectedDateE] = useState(_e);

    const [brandColor, setBrandColor] = useState(JSON.stringify({"primary":"#fff","secondary":"#5791a0"}));
    const theme = createTheme({
        palette: {
            primary: {
                main: darkenHexColor(brandColor.primary, 0), // Assuming darkenHexColor returns a valid color
            },
            secondary: {
                main: darkenHexColor(brandColor.secondary, 0), // Assuming the same
            },
        },
        overrides: { // Use 'overrides' instead of 'components' for MUI v4
            MuiTab: {
                root: { // Target the root style
                    '&$selected': { // Use '&$selected' to increase specificity for selected tabs
                        backgroundColor: "#f7f7f7",
                        color: lightenHexColor(brandColor.secondary, 0),
                    },
                },
            },
            MuiOutlinedInput: {
                input: {
                    padding: '2px 14px',
                },
            },
        },
        mixins: {
            toolbar: {
                minHeight: 100,  // Align the mixin minHeight with the Toolbar custom height
                '@media (min-width:0px) and (orientation: landscape)': {
                    minHeight: '70px'  // Custom height for landscape orientation
                },
                '@media (min-width:600px)': {
                    minHeight: '100px'  // Custom height for wider screens
                }
            }
        }
    });
    const classes = useStyles();
    const [value, setValue] = useState(tab);
    const [client, setClient] = useState({});

    const [reportProfile, setReportProfile] = useState();
    const [adsettings, setAdSettings] = useState();
    // eslint-disable-next-line
    const [copyUrl, setcopyUrl] = useState(window.location.href + '?sdate=' + selectedDateS + '&edate=' + selectedDateE + '&tab=' + value);
    const [tabs, setTabs] = useState({});
    const [open, setOpen] = useState(false);
    const [snack, setSnack] = useState({ msg: "", open: false });
    const admintoken = props.admintoken;
      // eslint-disable-next-line
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));



    const handleRangeSelect = (rangeKey) => {
        setSelectedDateS(rangeKey.startDate.toISOString().split('T')[0]);
        setSelectedDateE(rangeKey.endDate.toISOString().split('T')[0]);
        // console.log(window.location.href + '/?sdate=' + selectedDateS + '&edate=' + selectedDateE + '&tab=' + props.value)
        setcopyUrl(window.location.href + '/?sdate=' + selectedDateS + '&edate=' + selectedDateE + '&tab=' + value)
        setOpen(!open);
    };

    const handlesnackClose = (e) => {
        setSnack({ msg: "", open: false });
    }
    const MyTabCreator = ({ field, form, ...props }) => {
        var l = JSON.parse(reportProfile.settings);
        var e = "";
        //  console.log('Start:',selectedDateS);
        //  console.log('End:',selectedDateE);
        switch (props.tab) {
            case "meta":
                e = <TabPanel value={value} index={props.posi}>
                    {(l.ad_sets[props.tab].id !== 0 ?
                        <Meta
                            title='Meta formerly Facebook'
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.meta.id}
                            delta="15"
                            admintoken={admintoken}
                            idi={id}
                        />
                        : ''
                    )}
                </TabPanel>
                break;

            case "google_paid":

                e = <TabPanel value={value} index={props.posi}>
                    {(l.ad_sets['google_paid'].id !== 0 ?

                        <Google
                            onParentStateChange={handleDateChangeS}
                            title='Google Paid'
                            which="Paid"
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.google_paid.id}
                            delta="15"
                            type="SEARCH"
                            admintoken={admintoken}
                            idi={id}
                        />

                        : ''
                    )}
                </TabPanel>

                break;
            case "google_grant":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <Google
                            onParentStateChange={handleDateChangeS}
                            title='Google Grant'
                            which="Grant"
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.google_grant.id}
                            delta="15"
                            type="SEARCH"
                            admintoken={admintoken}
                            idi={id}
                        />
                        : ''
                    )}
                </TabPanel>
                break;

            case "bing":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <Bing
                            title='Bing'
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.bing.id}
                            delta="15"
                            admintoken={admintoken}
                            idi={id}
                        />
                        : ''
                    )}
                </TabPanel>
                break;
            case "stackadapt":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <StackAdapt
                            title='Display'
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.stackadapt.id}
                            delta="15"
                            admintoken={admintoken}
                            idi={id}
                        />
                        : ''
                    )}
                </TabPanel>
                break;

            case "virtuous":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <Virtuous
                            title='Virtuous'
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.stackadapt.id}
                            delta="15"
                            admintoken={admintoken}
                            idi={id}
                        />
                        : ''
                    )}
                </TabPanel>
                break;
            case "overview":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?

                        <Overview sdate={selectedDateS} admintoken={admintoken} clientid={id} edate={selectedDateE} themeColor={brandColor} ></Overview>
                        : ''
                    )}
                </TabPanel>
                break;
            case "donations":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <Registrations themeColor={brandColor} admintoken={admintoken} pageSize={1000} edate={selectedDateE} sdate={selectedDateS} ></Registrations>
                        : ''
                    )}
                </TabPanel>
                break;




            default:
                break;
        }


        return (
            <div>{e}</div>
        );
    };


    const handleDateChangeS = (date) => {
        var _d = date.toISOString().split('T')[0];
        //console.log(_d);
        setSelectedDateS(_d);
        fetchData();
    };




    async function fetchData() {

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };



        const res2 = await fetch(appVars.api_url + "/tagmanager/" + id, requestOptions);
        res2
            .json()
            .then((res2) => {
                setClient(res2);
            })
            .catch((err) => {
                console.log(err);
            });






        const res3 = await fetch(appVars.api_url + "/reportprofile/" + id, requestOptions);
        res3
            .json()
            .then((res3) => {
                setReportProfile(res3);
                setBrandColor(JSON.parse(res3.color));
                setTabs(JSON.parse(res3.tabs));
                setAdSettings(JSON.parse(res3.settings).ad_sets);
                console.log(res3);

            })
            .catch((err) => {
                console.log(err);
            });
    }


    useEffect(() => {
        fetchData();
          // eslint-disable-next-line
    }, [props]);


    return (
        <div className={classes.root}>
            {(reportProfile ?
                <ThemeProvider theme={theme}>

                    <Navbar copyUrl={copyUrl} sDate={selectedDateS} eDate={selectedDateE} value={value} handleTabChange={setValue} childToParent={handleRangeSelect} setValue={setValue} client={client} admintoken={admintoken} tabs={tabs} adsettings={adsettings} profile={reportProfile} brandColor={brandColor} ></Navbar>
                    <main className={classes.content}>
                        <div className={classes.toolbar} />

                        <Grid container spacing={0} className={classes.content}>
                            <Grid item xs={12} className={classes.data} >

                                {(tabs.tabs && adsettings ?
                                    <div className={classes.dataMargin}>
                                        {Object.entries(_.pickBy(adsettings, (value, key) => { return value.id !== ""; })).map((tab, index) => {

                                            //  console.log(g)
                                            var k = <MyTabCreator posi={index} tab={tab[0]}></MyTabCreator>
                                            return (
                                                k
                                            )
                                        })}
                                    </div>
                                    : ''
                                )}
                            </Grid>
                        </Grid>
                        {(admintoken.token >= 8 ?
                            <div style={{ padding: theme.spacing(1) }}>
                                <ReportSettings icon={true} themeColor={brandColor} title="Settings" client_id={id} />
                            </div>
                            : null)}
                    </main>

                    <div>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={handlesnackClose}
                            open={snack.open}
                            color="success"
                            autoHideDuration={3000}
                            message={snack.msg}
                        />
                    </div>
                </ThemeProvider>
                : '')}
        </div >
    );
};

GlobalReport.propTypes = {

};

export default GlobalReport;
