import React, { useEffect, useState } from 'react';
import { style } from './index.scss';
import appVars from '../../../appVars.json';
import { Button, CardActions, CardHeader, IconButton, ButtonGroup, Link } from '@material-ui/core';
import { makeStyles, createTheme,useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Loading from '../../../no-screenshot.png';
import { Assessment, Edit, ReportOutlined, VerifiedUser } from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';
import { lightenHexColor } from "../../../dwi-functions";
import { FaUsers } from "react-icons/fa6";
import themeFile from '../../../theme.json';


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 500,

    },
    media: {
        width: '100%'
    },
    pad: {
        padding: '0 1em'
    },
    avatar: {
        background: theme.palette.primary.dark
    }
}));

const Screenshot = (props) => {
    const classes = useStyles();
    const [imageName, setimageName] = useState(appVars.api_url + '/imageFiles/' + props.item.id + '.jpeg');
    const handleImageError = () => {
        // Image failed to load, call your function here
        setimageName(Loading);
    };
    const [hideHeader, setshowHeader] = useState(props.hideHeader);
    const [hideFooter, setshowFooter] = useState(props.hideFooter);
    if (props.themeColor) {


        var theme = createTheme({
            palette: {
                primary: {
                    main: props.themeColor.primary, // Default primary color
                },
                secondary: {
                    main: props.themeColor.secondary, // Default primary color
                },
            }
        });

    } else {
        var theme = createTheme(themeFile);
    }

    //console.log(props);
    function FetchData() {
        //  console.log(appVars.api_url + '/screenshot?d=' + props.item.siteUrl + '&id=' + props.item.id);
        fetch(appVars.api_url + '/screenshot?d=' + props.item.siteUrl + '&id=' + props.item.id)
            .then(response => response.json())
            .then((response) => {
                console.log(response.file);
                setimageName(response.file);

            }).catch((err) => {
                console.log('Error');
            });



    }
    useEffect(() => {

        setshowHeader(props.hideHeader);
        setshowFooter(props.hideFooter);


        return () => {
            //   console.log('Loaded')
        };
    }, [props.hideHeader, props.hideFooter]);



    return (

        <div className={style}>


            <Card variant="outlined" className={classes.root}>
                <CardHeader avatar={
                    <Avatar variant="circular" src={appVars.api_url + '/imageFiles/' + props.item.owner + '.png'} title={props.item.owner} ></Avatar>
                }
                    style={{ background: theme.palette.primary.main, display: (hideHeader === true ? 'none' : 'flex') }}
                    title={<Typography className={classes.pad} variant="body2" >{props.item.name}</Typography>}
                />
                <CardActionArea >
                    <CardMedia
                        title={props.clientname}
                        onClick={FetchData}
                        component="img"
                        alt={props.item.name}
                        height="200"
                        image={imageName}
                        onError={handleImageError}
                    >

                    </CardMedia>
                </CardActionArea>
                {(!hideFooter ?
                    <CardActions style={{ background: lightenHexColor(theme.palette.primary.main, 50) }}>
                        <ButtonGroup color="primary" variant="contained">
                            {(props.admintoken.token >= 8 ?
                                <Button target='_BLANK' style={{ background: theme.palette.primary.main, color: theme.palette.secondary.main }} href={'/subclientviewer/' + props.item.id}>
                                    <Edit style={{ color: theme.palette.secondary.main }}  ></Edit>
                                </Button>
                                : '')}
                            {(props.admintoken.token >= 8 ?
                                <Button target='_BLANK' style={{ background: theme.palette.primary.main, color: theme.palette.secondary.main }} href={'/cl/' + props.item.client}>
                                    <FaUsers style={{ color: theme.palette.secondary.main }} />
                                </Button>
                                : '')}

                            <Button style={{ background: theme.palette.primary.main, color: theme.palette.secondary.main }} target='_blank' href={'/mr/' + props.item.id}>
                                <Assessment style={{ color: theme.palette.secondary.main }} />
                            </Button>
                        </ButtonGroup>

                    </CardActions>
                    : '')}

            </Card>
        </div>
    );
};

Screenshot.propTypes = {
    data: PropTypes.array
};

export default Screenshot;
