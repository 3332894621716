import React, { useState, useEffect } from "react";
import {  Button, Grid, LinearProgress, Typography, Snackbar, CssBaseline,  AppBar, Toolbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {  createTheme } from "@material-ui/core/styles";
import Screenshot from '../controls/screenshot';
import appVars from '../../appVars.json';
import { useLocation } from 'react-router-dom';
import { useToken } from '../../Login/TokenProvider';
import logo from '../../Digital-Dashboard-Logo-FINAL.png';
import _ from "lodash";
import moment from 'moment';

const ClientGridView = (props) => {
    const [clientUsers, setClientUsers] = useState(false);
    // console.log(props);



    if (props.themeColor) {


        var theme = createTheme({
            palette: {
                primary: {
                    main: props.themeColor.primary, // Default primary color
                },
                secondary: {
                    main: props.themeColor.secondary, // Default primary color
                },
            }
        });

    } else {
        var theme = createTheme();
    }




    const [hasError, setErrors] = useState(false);
      // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    var id = location.pathname.split("/")[2];
    const token = useToken();
    const admintoken = token.token;
      // eslint-disable-next-line
    const searchParams = new URLSearchParams(location.search);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrors({ show: false, "msg": '' });
    };



    const getData = async () => {
        const res = await fetch(appVars.api_url + "/tagmanager_detail/" + id);
        res
            .json()
            .then((res) => {
                var a = _.sortBy(res, ['name']);

                if (props.status) {
                    a = _.filter(a, function (o) { return o.status; });
                } else {
                    a = _.filter(a, function (o) { return !o.status; });
                }
                console.log(res);
                setClientUsers(a);
                setLoading(false);
                // console.log(clientUsers);
            }).catch((err) => {

                setErrors({ show: true, "msg": err.msg })
            });



    }




    useEffect(() => {
        getData();
        return () => {

        };
    }, []);


    return (
        <div style={{ height: '100vh' }}>
            <CssBaseline />
            <Grid container spacing={0}>
                {/* Navbar */}
                {(props.header === true ?
                    <Grid item xs={12}>

                        <AppBar color="primary" position="sticky" >

                            <Toolbar>
                                <div style={{ padding: 10, flexGrow: 1 }}>
                                    <img style={{ maxWidth: 150, marginRight: 'auto', width: '100%', paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) }} src={appVars.api_url + '/imageFiles/' + id + '.png'} alt="" />
                                </div>
                                <div style={{ padding: 10, flexGrow: 0 }}>
                                    <img style={{ maxWidth: 200, marginRight: 'auto', width: '100%', paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) }} src={logo} alt="" />
                                </div>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                    : '')}
                {/* Main content area with sidebar and main content */}
                <Grid item container spacing={1} alignItems="flex-start" xs style={{ flexGrow: 1 }}>
                    <Grid item xs={12} style={{ padding: theme.spacing(2) }}>
                        {(clientUsers !== false ?
                            <div>

                                <div>
                                    <Grid container spacing={2}>
                                        {clientUsers.map((item, index) => (
                                            <Grid item sm={3} xs={12}>
                                                <Screenshot themeColor={props.themeColor} admintoken={admintoken} item={item} url={item.siteUrl} clientname={item.name} hideFooter={false} ></Screenshot>
                                            </Grid>
                                        ))}
                                    </Grid>

                                </div>

                            </div>
                            : <div>
                                <Typography></Typography>
                                <LinearProgress style={{ margin: (theme.spacing(4), theme.spacing(4)) }} color="secondary"></LinearProgress></div>
                        )}
                    </Grid>
                </Grid>

                {/* Footer */}
                <Grid item xs={12}>
                    <AppBar style={{background:theme.palette.primary.main}} position="relative">
                        <Toolbar >

                        </Toolbar>
                    </AppBar>
                </Grid>
            </Grid>







            <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    The data can not be pulled at this time.
                    <Button color="primary" onClick={window.location.reload}>
                        Refresh
                    </Button>
                </Alert>
            </Snackbar>
        </div>
    );
};

ClientGridView.propTypes = {

};

export default ClientGridView;
