import { AppBar, Grid, Typography, LinearProgress, Tabs, Tab, Box } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import TableCard from '../../reports/table-card';
import { lightenHexColor, darkenHexColor } from '../../dwi-functions';
import appVars from '../../appVars.json';
import moment from 'moment';
import Comments from '../../system/control/comments';
import MaterialTable from 'material-table';





const fontSize = 'initial';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}






const Virtuous = (props) => {
    const [value, setValue] = useState(0);
    const [data, setData] = useState();
    const [admintoken, setAdmin] = useState(props.admintoken);
    const location = useLocation();
    var id = location.pathname.split("/")[2];

    const searchParams = new URLSearchParams(location.search);

    const theme = createTheme({
        palette: {
            primary: {
                main: props.themeColor.primary, // Default primary color
            },
            secondary: {
                main: props.themeColor.secondary, // Default primary color
            },
        },
        overrides: {
            // Customizing MuiOutlinedInput globally
            MuiInputAdornment: {
                root: {
                    color: props.themeColor.primary
                }
            },
            MuiIcon: {
                root: {
                    color: props.themeColor.secondary
                }
            },
            MuiOutlinedInput: {
                root: {
                    padding: 0,
                    background: lightenHexColor(props.themeColor.primary, 75)
                },
                input: {
                    padding: 7
                }
            },
            MuiPaper: {
                // Apply styles for elevation = 0 by default
                elevation2: {
                    boxShadow: 'none'  // Removes default elevation shadow
                },
                root: {
                    border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 25),  // Add an outline
                }
            }
        },
    });


    const headerStyleObj = {
        textAlign: 'center',
        backgroundColor: lightenHexColor(props.themeColor.secondary, 0),
        color: lightenHexColor(props.themeColor.primary, 0),
        fontSize: fontSize,
        border: '1px solid ' + props.themeColor.secondary,
        paddingTop: 5,
        paddingBottom: 5,
        maxWidth: 75,
    };





    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchReportData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cid": id,
            "sdate": props.sDate,
            "edate": props.eDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res4 = await fetch(appVars.api_url + "/virtuous/stats", requestOptions);
        //    console.log(props);
        res4
            .json()
            .then((res4) => {
                setData(res4);

            })
            .catch((err) => {
                console.log(err);
            });





    }

    useEffect(() => {
        // if(!data){
        fetchReportData();
        // console.log(data);
        //  }
    }, [props]);




    return (
        <div >



            {(data ?
                <div>
                    <AppBar variant="outlined" color='secondary' position="relative" style={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(0) }} >
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="standard" scrollButtons="auto" indicatorColor='secondary' textColor="secondary">
                            <Tab label="Campaign" {...a11yProps(0)} />
                            <Tab label="Notes" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <Grid container spacing={2} alignContent="space-between">
                            <Grid item sm={12} xs={12} >

                                {(data ?

                                    <MaterialTable
                                        options={{
                                            rowStyle: (rowData, index) => ({
                                                textAlign: 'center',
                                                fontSize: fontSize,
                                                padding: 0,
                                                margin: 0,
                                                backgroundColor: index % 2 === 0 ? '#FFF' : lightenHexColor(props.themeColor.secondary, 50),
                                            }),
                                            cellStyle: { border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0), textAlign: 'center', padding: theme.spacing(1) },
                                            headerStyle: headerStyleObj,
                                            grouping: false,
                                            searchFieldVariant: 'outlined',
                                            exportButton: true,
                                            exportAllData: true,
                                            search: true,
                                            exportFileName: moment(new Date()).format('YYYYMMDD.hhmmss'),
                                            pageSize: data.length,
                                            pageSizeOptions: [10, 25, 50, 100],
                                            showTitle: false,
                                            toolbar: true,
                                            sort: true,
                                           
                                        }}
                                        title="Campaigns"
                                        data={data}
                                        count={(data.length > 5 ? 5 : data.length)}
                                        client={props.client}
                                        themeColor={props.themeColor}
                                        columns={[
                                            { title: "Email Name", field: "email_name", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 100, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.email_name}</Typography> },
                                            { title: "Delivered", field: "delivered", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.delivered} displayType="text" thousandSeparator="," /> },
                                            {
                                                title: "Opened", field: "CTR", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.opened} displayType="text" thousandSeparator="," />
                                            },
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor.secondary, 0),
                                                    textAlign: 'center',
                                                    padding: theme.spacing(1)

                                                }, title: "Clicked", field: "clicked", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.clicked} displayType="text" thousandSeparator="," />
                                            },
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                    textAlign: "center",
                                                    padding: theme.spacing(1)

                                                },
                                                title: "Bounces", field: "bounced", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.bounced} displayType="text" thousandSeparator="," />
                                            },
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                    textAlign: "center",
                                                    padding: theme.spacing(1)

                                                },
                                                title: "Unsubscribe", field: "unsubcribe", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.unsubscribe} displayType="text" thousandSeparator="," />
                                            },
                                            { title: "Revenue", field: "REVENUE", type: 'currency' },
                                            { title: "Avg. Gift", field: "avgGift", type: 'currency' }
                                        ]}
                                    />
                                    : <div ><Typography variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}

                            </Grid>
                        </Grid>
                    </TabPanel>


                    <TabPanel value={value} index={1}>
                        <Grid item sm={12} xs={12} >
                            <Comments eDate={props.eDate} sDate={props.sDate} themeColor={props.themeColor} admintoken={admintoken} view={(admintoken.token >= 6 ? "5af0a295-8b57-4ccb-8cc8-d54e5bb278e6" : "a7edd5c0-7a77-49ed-86ad-6fe3066a0f59")} type="virtuous"></Comments>
                        </Grid>
                    </TabPanel>



                </div>
                : <div style={{ paddingTop: theme.spacing(5) }} ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading {props.title} Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(8) }}></LinearProgress></div>)}




        </div >
    );
};

Virtuous.propTypes = {

};

export default Virtuous;
