import React, { useState, useEffect, useRef } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import ColorPicker from "material-ui-color-picker";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Switch, Typography, Grid, Badge, LinearProgress, TextField, Card, CardContent, CardHeader, CardActionArea, CardActions, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { DateTimePicker } from "@material-ui/pickers";
import appVars from "../../appVars.json";
import MatomoSites from '../../clients/controls/matomo-sites';
import { Delete, Image, Save } from '@material-ui/icons';
import _ from 'lodash';
import TinyEditor from '../../settings/editor';
import ClipboardJS from "clipboard";
import { MdCopyAll } from 'react-icons/md';
import { useTheme } from "@material-ui/core/styles";


// eslint-disable-next-line
const MyInput = ({ field, form, ...props }) => {
  return <TextField size='small' {...field} {...props} />;
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: 0,
      padding: 0
    },
  },
  text: {
    width: "98%",
    marginBottom: theme.spacing(2)
  },
  textDate: {
    width: "48%",
    marginRight: '2%',
    marginBottom: theme.spacing(2)
  },
  dense: {
    float: "right",
  },
  text30: {
    width: "48%",
  },
  margin: {
    margin: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(0, 3),
  },
  hidden: {
    display: 'none'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "7px",
    fontSize: '.75em'
  },
  buttons: {

    flexGrow: "1"
  }
}));

const LightBoxSettings = (props) => {

  const [open, setOpen] = React.useState(false);
  const [openM, setOpenM] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setOpenM(false);
    setIsFilePicked(false);
    setSelectedFile("")
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenM = () => {
    setOpenM(true);
  };

  const editorRef = useRef(null);
  const log = (e) => {
    lightbox.embed.template.text = e.currentTarget.value;
    setLightbox(lightbox);
    //console.log(e.currentTarget.value);
  };


  const classes = useStyles();
  const theme = useTheme();
  const embed =
    '<script class="dwi-components"  id="' +
    props.lightboxId +
    "|" +
    props.beaconId +
    '" src="' +
    appVars.api_url +
    '/scripts/a.js"></script>';

  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const changeFileHandler = (event) => {

    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);

  };





  var st = new Date(props.startTime);
  //st.setMinutes(st.getMinutes() - st.getTimezoneOffset());
  var et = new Date(props.endTime);
  //et.setMinutes(et.getMinutes() - et.getTimezoneOffset());
  //console.log(st);

  const [state, setState] = React.useState({
    status: false,
    color: props.color,
  });


  const standAlone = {
    "description": props.description,
    "startTime": st,
    "endTime": et,
    "url": props.url,
    "cookieExpire": props.cookieExpire,
    "image": props.image,
    "themeColor": props.themeColor,
    "delay": props.delay,
    "lightboxId": props.lightboxId,
    "maxWidth": props.maxWidth,
    "beaconId": props.beaconId
  };




  const [stats, setStats] = React.useState({
    'label': 'tst',
    "impressions": 0
  });
  const [loading, setLoading] = useState([true]);
  // eslint-disable-next-line
  const [hasError, setErrors] = useState(false);


  const [effectiveStartDate, setEffectiveStartDate] = React.useState(
    st
  );
  const [effectiveEndDate, setEffectiveEndDate] = React.useState(
    et
  );
  // eslint-disable-next-line

  const cDefault = {
    "close": {
      "mobile": {
        "top": "5px",
        "right": "10px",
        "max-width": "10px"
      },
      "desktop": {
        "top": "15px",
        "right": "15px",
        "max-width": "10px"
      }
    },
    "splash": {
      "mobile": {
        "max-width": "90vw"
      },
      "desktop": {
        "max-width": "900px"
      }
    },
    "template": {
      "text": "<div id=\"dwiSplash\" class=\"dwiSplash\"><div id=\"splash\" class=\"splash\"><div class=close><a id=\"close\"><img id=\"close-image\" /></a></div><div class=donate-now><a id=\"donatelink\"><img id=\"light-image\" /></a></div></div></div></div>"
    }
  };

  //console.log(props.embed.close);



  const [lightbox, setLightbox] = useState(props);
  const [embedSettings, setEmbed] = useState((typeof data === 'string' ? JSON.parse(props.embed) : props.embed));
  const [cdesktop, setCDesktop] = useState((embedSettings !== undefined ? embedSettings.close.desktop : cDefault.close.desktop));
  const [cmobile, setCMobile] = useState((embedSettings !== undefined ? embedSettings.close.mobile : cDefault.close.mobile));
  const [status, setstatus] = useState(props.status);
  ///console.log(props);

  const handleEffectiveDateChange = (date) => {
    var momentDate = date;
    if (date) {
      setEffectiveStartDate(momentDate);

    } else {
      setEffectiveStartDate(null);
    }
    console.log(date);
  };

  const handleEffectiveEndDateChange = (date) => {


    var momentDate = date;


    if (date) {
      setEffectiveEndDate(momentDate);
    } else {
      setEffectiveEndDate(null);
    }
    console.log(date);
  };

  const handleImages = (e) => {
    var a = {
      "imageSrcMobile": document.getElementById('imgurlm').value,
      "imageSrc": document.getElementById('imgurl').value,
      "imageAlt": document.getElementById('imgalt').value,
      "close": document.getElementById('close').value
    };

    setImages(a);
    var i = document.getElementById("imgSource");
    i.setAttribute("src", images.imageSrc);
    document.getElementById("image").value = JSON.stringify(images);
  }
  //console.log(k);

  const [images, setImages] = useState("");

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });

    //console.log(event);
  };

  const handleStatusChange = (event) => {
    //lightbox.status=event.target.checked;
    setState({ ...state, [event.target.name]: event.target.checked });
    setstatus(event.target.checked);
    console.log(status);
  };

  //console.log(images);


  async function fetchStats(id) {
    var a = new Date(props.startTime);
    var b = new Date(props.endTime);

    var data = {
      module: 'API',
      format: 'JSON',
      idSite: props.beaconId,
      period: 'range',
      date: a.getFullYear() + '-' + (a.getMonth() + 1) + '-' + a.getDate() + ',' + b.getFullYear() + '-' + (b.getMonth() + 1) + '-' + b.getDate(),
      method: 'Events.getName',
      expanded: 1,
      pivotBy: 'Events.EventAction',
      pivotByColumnLimit: 20,
      pivotByColumn: 'nb_events',
      token_auth: appVars.matomo_token,
      filter_limit: 50
    };

    const res = await fetch(appVars.matomo_url + '/index.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(data).toString()
    });

    res.json()
      .then((res) => {
        const q = res.map(item => item);
        var k = _.find(q, function (o) { return o.label === props.lightboxId; });
        var st = {
          label: res[0].label,
          impressions: Object.values(k)[1],
          closed: Object.values(k)[2],
          clicked: Object.values(k)[3]
        };

        console.log(k);
        setStats(st);
        setLoading(true);
      })
      .catch((err) => {
        setErrors(true);
        console.log(err);
      });
  }





  var clipboard = new ClipboardJS("#copy-script");

  useEffect(() => {

    if (props.id !== "NEW") {
      fetchStats();
    }


    //setEffectiveStartDate(Date.now);
    //setEffectiveEndDate(Date.now);
    setImages(props.image);
    document.getElementById("cdtop").value = cdesktop['top']
    document.getElementById("cdright").value = cdesktop['right']
    document.getElementById("cdmaxWidth").value = cdesktop['max-width']

    document.getElementById("cmtop").value = cmobile['top']
    document.getElementById("cmright").value = cmobile['right']
    document.getElementById("cmmaxWidth").value = cmobile['max-width']
    document.getElementById("embedhtml").value = (embedSettings !== undefined ? embedSettings.template.text : cDefault.template.text);
    document.getElementById("cssFile").value = (embedSettings.cssFile !== undefined ? embedSettings.cssFile : 'https://api.digitalwerks.com:7325/styles/a.css');
    // console.log(lightbox);


  }, []);


  return (
    <div >



      <Formik
        enableReinitialize
        initialValues={lightbox}
        onSubmit={(values) => {
          var k = JSON.stringify(values);
          k = JSON.parse(k);
          const apiUrl =
            (k.lightboxId === "NEW"
              ? "https://api.digitalwerks.com:7325/lightbox/add"
              : "https://api.digitalwerks.com:7325/lightbox/update/" +
              k.lightboxId);

          k.embed = "NEW" === k.lightboxId ? cDefault : k.embed;
          k.embed.close.mobile = cmobile;
          k.embed.close.desktop = cdesktop;
          k.embed.cssFile = k.cssFile;


          k.startTime = effectiveStartDate;
          k.endTime = effectiveEndDate;
          k.image = JSON.stringify(images);
          k.impressions = stats.impressions;
          k.clicked = stats.clicked;
          k.closed = stats.clicked;
          k.status = status;
          k.embed = JSON.stringify(k.embed);
          
          /*
          console.log(k);
          return;
          */
          
          // eslint-disable-next-line
          const res = fetch(apiUrl, {
            method: (k.lightboxId === "NEW" ? "POST" : "PUT"),
            body: JSON.stringify(k),
            headers: {
              "Content-Type": "application/json",
            },
          }).then(res => {
            console.log(res);
            window.location.reload();
            // window.location.href = '/';
          }).catch(err => setErrors(true));
          //console.log(res);

          //  window.location.reload()
        }}
      >

        <Form className={classes.root}>



          <Grid container justifyContent="flex-end" spacing={1} style={{ background: theme.palette.primary.light, padding: 0, margin: "0 -8px" }}>


            <Grid item md={1} sm={12}>
              <Switch
                checked={status}
                onChange={handleStatusChange}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              ></Switch>
            </Grid>
            <Grid item xl={1} md={2} sm={12}>

              <Button fullWidth startIcon={<Save></Save>} className={classes.buttons} color="primary" variant="contained" type="submit">
                Save
              </Button>

            </Grid>
            <Grid item md={1} sm={12}>
              <Button
                color="secondary"
                variant="contained"
                href={'/lightbox/preview/' + btoa(props.lightboxId + '|' + props.clientId)}
                target="_BLANK"
                type="button"
                fullWidth
              >
                Preview
              </Button>

            </Grid>
            <Grid item md={1} sm={12}>
              <Button
                startIcon={<Delete></Delete>}
                color="default"
                style={{ borderColor: "red", color: "red" }}
                variant="outlined"
                onClick={(e) => {
                  var requestOptions = {
                    method: 'GET',
                    redirect: 'follow'
                  };
                  fetch(appVars.api_url + "/lightbox/delete/" + props.lightboxId, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                      console.log(result)
                      window.location.reload();
                    })
                    .catch(error => console.log('error', error));
                }}
                target="_BLANK"
                type="button"
                fullWidth
              >
                Delete
              </Button>

            </Grid>
          </Grid>


          <Grid container xs={12} spacing={2}>
            <Grid item xl={3} sm={6}>

              <Card>

                <CardHeader title="Global Settings" className={classes.cardHeader} />


                <CardContent>
                  <Field
                    type="text"
                    size="small"
                    label="Beacon ID"
                    variant="outlined"
                    name="beaconId"
                    id="beaconId"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    client={{ client: { name: '' } }}
                    className={classes.text}
                    component={MatomoSites}
                  />

                  <Field
                    type="text"
                    className={classes.hidden}
                    component={MyInput}
                    variant="outlined"
                    label="ClientID"
                    disabled

                    name="clientId"
                    id="clientId"
                  />
                  <ErrorMessage name="password" component="div" />
                  <Field
                    type="text"
                    className={classes.hidden}
                    component={MyInput}
                    variant="outlined"
                    label="LightboxID"
                    disabled
                    name="lightboxId"
                    id="lightboxId"
                  />

                  <ErrorMessage name="lightboxId" component="div" />
                  <Field
                    type="text"
                    className={classes.text}
                    component={MyInput}
                    variant="outlined"
                    label="Name of Lightbox"
                    name="description"
                    id="description"
                  />
                  <ErrorMessage name="description" component="div" />
                  <ColorPicker
                    name="themeColor"
                    variant="outlined"
                    label="Theme Color"
                    className={classes.hidden}
                    defaultValue={state.color}
                    value={state.color}
                    onChange={(color) => {
                      state.color = color;
                      console.log(state.color);
                    }}
                  />
                  <Field
                    className={classes.text}
                    type="text"
                    label="Destination URL"
                    variant="outlined"
                    component={MyInput}
                    name="url"
                    id="url"
                  />
                  <ErrorMessage name="url" component="div" />
                  <Field

                    className={classes.textDate}
                    label="Start Date"
                    size="small"
                    onChange={handleEffectiveDateChange}
                    inputVariant="outlined"
                    component={DateTimePicker}
                    value={effectiveStartDate}
                    name="startTime"
                    id="startTime"
                    InputLabelProps={{
                      shrink: true,
                    }}

                  />
                  <ErrorMessage name="Start Time" component="div" />
                  <Field

                    className={classes.textDate}
                    label="End Date"
                    size="small"
                    onChange={handleEffectiveEndDateChange}
                    inputVariant="outlined"
                    component={DateTimePicker}
                    value={effectiveEndDate}
                    name="endTime"
                    id="endTime"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorMessage name="End Time" component="div" />
                  <Field
                    className={classes.text}
                    type="number"
                    label="# of days to show"
                    variant="outlined"
                    component={MyInput}
                    name="cookieExpire"
                    id="cookieExpire"
                  />
                  <ErrorMessage name="cookieExpire" component="div" />
                  <Field
                    className={classes.text}
                    type="text"
                    label="Show delay (ms)"
                    variant="outlined"
                    component={MyInput}
                    name="delay"
                    id="delay"
                  />
                  <ErrorMessage name="delay" component="div" />
                  <Field
                    className={classes.text}
                    type="text"
                    label="Maximum Width"
                    variant="outlined"
                    component={MyInput}
                    name="maxWidth"
                    id="maxWidth"
                  />
                  <ErrorMessage name="delay" text="Err" component="div" />
                  <Field
                    className={classes.text}
                    type="text"
                    label="Enter Custom CSS file"
                    component={MyInput}
                    variant="outlined"
                    name="cssFile"
                    id="cssFile"
                  />
                  <Grid item xl={12} sm={12}>
                    {props.id === "NEW" ? (
                      ""
                    ) : (
                      <Field
                        className={classes.text}
                        id="embedscript"
                        type="text"
                        label="Embed Script"
                        disabled

                        InputProps={{
                          startAdornment: (
                            <IconButton size='small' color="Primary" id="copy-script" data-clipboard-target="#embedscript" title={'Copy to Clipbaord'} ><MdCopyAll />
                            </IconButton>

                          )
                        }}
                        variant="outlined"
                        component={MyInput}
                        value={embed}
                        fullWidth
                        size="small"
                      />

                    )}
                    {props.id === "NEW" ? (
                      ""
                    ) : (
                      <Field
                        className={classes.text}
                        type="text"
                        label="Settings for Standalone Script"
                        disabled
                        variant="outlined"
                        component={MyInput}
                        value={JSON.stringify(standAlone)}
                        fullwidth
                        size="small"
                      />

                    )}

                  </Grid>

                </CardContent>
              </Card>
              <Card style={{ marginTop: theme.spacing(2) }}>
                <CardHeader title="Statistics" className={classes.cardHeader}>

                </CardHeader>
                <CardContent>
                  <LinearProgress hidden={loading} color="primary" />
                  <Grid container>
                    <Grid item xl={4}>
                      <Badge color="primary" badgeContent={stats.impressions} className={classes.margin} max={9999}>
                        <Typography className={classes.padding}>Impressions</Typography>
                      </Badge>

                    </Grid>
                    <Grid item xl={4}>
                      <Badge color="error" badgeContent={stats.impressions - stats.clicked} className={classes.margin} max={9999}>
                        <Typography className={classes.padding}>Closed {((stats.impressions - stats.clicked) / stats.impressions).toFixed(2)}%</Typography>
                      </Badge>
                    </Grid>
                    <Grid item xl={4}>
                      <Badge color="secondary" badgeContent={stats.clicked} className={classes.margin} max={9999}>
                        <Typography className={classes.padding}>Clicked {(stats.clicked / stats.impressions).toFixed(2)}%</Typography>
                      </Badge>
                    </Grid>

                  </Grid>
                  <CardActionArea>
                    <CardActions>

                      <ErrorMessage name="delay" text="Err" component="div" /></CardActions>
                  </CardActionArea>
                </CardContent>
              </Card>
            </Grid>


            <Grid item xl={9} sm={6}>


              <Card >
                <CardHeader className={classes.cardHeader} title="Display Image" color="primary" />

                <CardContent>
                  <Grid container xl={12} spacing={2}>
                    <Grid item xl={12} md={12}>
                      <Field
                        className={classes.text}
                        type="text"
                        label="Main Image ALT Text"
                        variant="outlined"
                        component={MyInput}
                        name="imgalt"
                        id="imgalt"
                        InputProps={{
                          onChange: handleImages,
                          shrink: true
                        }}
                        value={images.imageAlt}
                      />
                    </Grid>
                    <Grid item xl={6}>
                      <Grid xl={12} spacing={1} container>
                        <Grid item xl={9} style={{ display: 'none' }}>
                          <Field
                            className={classes.text}
                            type="text"
                            label="Main Image URL"
                            variant="outlined"

                            component={MyInput}
                            name="imgurl"
                            id="imgurl"
                            InputProps={{
                              onChange: handleImages,
                              shrink: true
                            }}
                            value={images.imageSrc}
                          />
                        </Grid>
                        <Grid item xl={12} md={12}>
                          <Button className={classes.text} startIcon={<Image></Image>} size="small" variant="contained" component="label" color="primary" fullWidth onClick={handleClickOpen}>Add Image</Button>
                        </Grid>

                      </Grid>


                      <div style={{ position: 'relative', maxWidth: props.maxWidth, marginBottom: '3em' }}>
                        <img id="imgClose" alt='Close Button' src={images.close} style={{ position: 'absolute', right: cdesktop["right"], top: cdesktop["top"] }} />
                        <img id="imgSource" width={650} className={classes.image} alt={images.imageAlt} src={images.imageSrc} style={{ boxShadow: '0px 0px 10px 1px gray' }} />
                      </div>

                    </Grid>
                    <Grid item xl={6}>

                      <Grid xl={12} spacing={1} container>
                        <Grid item xl={7} style={{ display: 'none' }}>
                          <Field
                            className={classes.text}
                            type="text"
                            label="Mobile Image URL"
                            variant="outlined"
                            component={MyInput}
                            name="imgurlm"
                            id="imgurlm"
                            InputProps={{
                              onChange: handleImages,
                              shrink: true
                            }}
                            value={images.imageSrcMobile}
                          />
                        </Grid>
                        <Grid item xl={12}>
                          <Button startIcon={<Image></Image>} className={classes.text} size="small" variant="contained" component="label" color="primary" fullWidth onClick={handleClickOpenM}>Add Mobile Image</Button>
                        </Grid>
                      </Grid>

                      <div style={{ position: 'relative', maxWidth: props.maxWidth, marginBottom: '3em' }}>
                        <img id="imgClose" alt='Close Button' src={images.close} style={{ position: 'absolute', right: cmobile["right"], top: cmobile["top"] }} />
                        <img id="imgSource" width={"100%"} className={classes.image} alt={images.imageAlt} src={images.imageSrcMobile} style={{ boxShadow: '0px 0px 10px 1px gray' }} />
                      </div>

                    </Grid>


                    <Grid item xl={12}>




                      <Field
                        className={classes.text}
                        type="hidden"
                        label="Main Image URL"
                        variant="outlined"

                        name="image"
                        id="image"
                        InputProps={{
                          onBlur: handleImages
                        }}
                      />






                      <Field
                        className={classes.text}
                        type="text"
                        label="EmbedHTML"
                        variant="outlined"
                        component={MyInput}
                        inputProps={{ "shrink": true }}
                        name="embedhtml"
                        id="embedhtml"
                        InputProps={{
                          onChange: log,
                          shrink: true
                        }}
                      //value={lightbox.embed.template.text}
                      />


                    </Grid>
                    <Grid xl={12} item>
                      <Field
                        className={classes.text}
                        type="text"
                        label="Close Button"
                        variant="outlined"
                        component={MyInput}
                        inputProps={{ "shrink": true }}
                        name="close"
                        id="close"
                        InputProps={{
                          onChange: handleImages,
                          shrink: true
                        }}
                        value={images.close}
                      />

                    </Grid>

                    <Grid container xl={6} spacing={2}>
                      <Grid xl={12} item>
                        <Typography gutterBottom variant='h6'>Close Button Desktop</Typography>
                      </Grid>
                      <Grid xl={4} item>
                        <Field
                          className={classes.text}

                          type="text"
                          label="top"
                          variant="outlined"
                          component={MyInput}
                          inputProps={{ "shrink": true }}
                          name="cdtop"
                          id="cdtop"
                          onChange={(e) => {
                            cdesktop['top'] = e.currentTarget.value;
                            setCDesktop(cdesktop);
                          }}

                        />
                      </Grid>
                      <Grid xl={4} item>
                        <Field
                          className={classes.text}
                          type="text"
                          label="right"
                          variant="outlined"
                          component={MyInput}
                          inputProps={{ "shrink": true }}
                          name="cdright"
                          id="cdright"
                          onChange={(e) => {
                            cdesktop['right'] = e.currentTarget.value;
                            setCDesktop(cdesktop);
                          }}

                        />
                      </Grid>
                      <Grid xl={4} item>
                        <Field
                          className={classes.text}
                          type="text"
                          label="Max Width"
                          variant="outlined"
                          component={MyInput}
                          inputProps={{ "shrink": true }}
                          name="cdmaxWidth"
                          id="cdmaxWidth"
                          onChange={(e) => {
                            cdesktop['max-width'] = e.currentTarget.value;
                            setCDesktop(cdesktop);
                          }}

                        />
                      </Grid>

                    </Grid>

                    <Grid container xl={6} spacing={2}>
                      <Grid xl={12} item>
                        <Typography gutterBottom variant='h6'>Close Button Mobile</Typography>
                      </Grid>
                      <Grid xl={4} item>
                        <Field
                          className={classes.text}
                          type="text"
                          label="top"
                          variant="outlined"
                          component={MyInput}
                          inputProps={{ "shrink": true }}
                          name="cmtop"
                          id="cmtop"
                          onChange={(e) => {
                            cmobile['top'] = e.currentTarget.value;
                            setCMobile(cmobile);
                          }}

                        />
                      </Grid>
                      <Grid xl={4} item>
                        <Field
                          className={classes.text}
                          type="text"
                          label="right"
                          variant="outlined"
                          component={MyInput}
                          inputProps={{ "shrink": true }}
                          name="cmright"
                          id="cmright"
                          onChange={(e) => {
                            cmobile['right'] = e.currentTarget.value;
                            setCMobile(cmobile);
                          }}

                        />
                      </Grid>
                      <Grid xl={4} item>
                        <Field
                          className={classes.text}
                          type="text"
                          label="Max Width"
                          variant="outlined"
                          component={MyInput}
                          inputProps={{ "shrink": true }}
                          name="cmmaxWidth"
                          id="cmmaxWidth"
                          onChange={(e) => {
                            cmobile['max-width'] = e.currentTarget.value;
                            setCMobile(cmobile);
                          }}

                        />
                      </Grid>

                    </Grid>


                  </Grid>

                </CardContent>
              </Card>
            </Grid>






            <Grid item xl={3} className={classes.hidden}>
              <Card>
                <CardHeader title=" Embed Settings" className={classes.cardHeader} />
                <CardContent>

                  {props.id === "NEW" ? (
                    ""
                  ) : (
                    <Field
                      className={classes.text}
                      type="text"
                      label="Embed Settings"
                      disabled
                      variant="outlined"
                      component={MyInput}
                      name="settings"
                      id="settings"
                    />
                  )}

                  {props.id === "NEW" ? (
                    ""
                  ) : (
                    <Field
                      className={classes.text}
                      type="text"
                      label="Embed Script"
                      disabled
                      variant="outlined"
                      component={MyInput}
                      value={embed}
                    />
                  )}

                  <Field
                    className={classes.checkbox}
                    type="checkbox"
                    label="Status"
                    checked={state.status}
                    onChange={handleChange}
                    variant="outlined"
                    component={Switch}
                    name="status"
                    id="status"
                  />
                </CardContent>
              </Card>
            </Grid>


          </Grid>
        </Form>

      </Formik>


      <form className={classes.root} noValidate autoComplete="off" action='http://localhost:7325/upload' method="POST">
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Image Uploader</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <input component={MyInput} id="fileToUpload" type="file" onChange={changeFileHandler} color="secondary" />
              {isFilePicked ? (
                <div>
                  <p>Filename: {selectedFile.name}</p>
                  <p>Filetype: {selectedFile.type}</p>
                  <p>Size in bytes: {selectedFile.size}</p>
                  <p>
                    lastModifiedDate:{' '}
                    {selectedFile.lastModifiedDate.toLocaleDateString()}
                  </p>
                </div>
              ) : (
                <p>Select a file to show details</p>
              )}
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary"
              onClick={async (e) => {
                const formData = new FormData();

                formData.append('filesToUpload', selectedFile);
                formData.append('pkRecID', props.lightboxId);

                const res = await fetch(appVars.api_url + "/upload/lightbox", {
                  method: "POST",
                  mode: "no-cors",
                  body: formData,
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });

                images.imageSrc = appVars.api_url + "/imageFiles/" + props.lightboxId + '.' + selectedFile.name.split('.')[1];
                handleClose();
              }}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </form>


      <form className={classes.root} noValidate autoComplete="off" action='http://localhost:7325/upload' method="POST">
        <Dialog open={openM} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Mobile Image Uploader</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <input component={MyInput} id="fileToUpload" type="file" onChange={changeFileHandler} color="secondary" />
              {isFilePicked ? (
                <div>
                  <p>Filename: {selectedFile.name}</p>
                  <p>Filetype: {selectedFile.type}</p>
                  <p>Size in bytes: {selectedFile.size}</p>
                  <p>
                    lastModifiedDate:{' '}
                    {selectedFile.lastModifiedDate.toLocaleDateString()}
                  </p>
                </div>
              ) : (
                <p>Select a file to show details</p>
              )}
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary"
              onClick={async (e) => {
                const formData = new FormData();

                formData.append('filesToUpload', selectedFile);
                formData.append('pkRecID', props.lightboxId + "-m");

                const res = await fetch(appVars.api_url + "/upload/lightbox", {
                  method: "POST",
                  mode: "no-cors",
                  body: formData,
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });

                images.imageSrcMobile = appVars.api_url + "/imageFiles/" + props.lightboxId + '-m.' + selectedFile.name.split('.')[1];
                handleClose();
              }}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </form>







    </div >
  );
};

LightBoxSettings.propTypes = {};

export default LightBoxSettings;
