import React, { useState } from 'react';
import { Container, Grid, TextField, Button, Paper } from '@material-ui/core';
import appVars from '../../appVars.json';
import Logo from '../../Digital-Dashboard-Logo-FINAL.png';
import { makeStyles } from '@material-ui/core';


const PasswordReset = (props) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    /**
     *Gets the ID needed for the look up, its the last section of the URL
     *
     * @param {*} thePath
     */
    const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
    var id = getLastItem(window.location.href);

    const classes = makeStyles((theme) => ({
        root: {
            display: 'content!important',
            background: '#e01a1a'
        }
    }))



    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate password and confirmation
        if (password !== confirmPassword) {
            alert("Passwords don't match");
            return;
        }

        try {
            const response = await fetch(appVars.api_url + '/resetpassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "userid": id, "password": password }),
            });
          //  console.log(response)
            if (response.ok) {
                document.getElementById('form').innerHTML = "<h3>Password has been Reset</h3>";
                setTimeout(() => {
                    window.location.href= "/login";
                }, 5000);

            //    alert('Password reset successful!');
                // Reset form fields
                setPassword('');
                setConfirmPassword('');
            } else {
                alert('Password reset failed!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while resetting the password');
        }
    };
    return (
        <div className={classes.root}>
            <Container>
                <Grid container justifyContent="center" alignItems="center" style={{ height: '70vh' }}>
                    <Grid item xs={12} sm={6} md={4}>
                        <img src={Logo} style={{ width: '100%' }} alt="DigitalWerks" />
                        <Paper elevation={3} style={{ padding: 16 }} id='form'>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    type="password"
                                    label="New Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    type="password"
                                    label="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <Button type="submit" variant="contained" color="primary" fullWidth>
                                    Reset Password
                                </Button>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

PasswordReset.propTypes = {

};

export default PasswordReset;
