import React, { useState, useEffect } from 'react';

import { Button, Divider, Grid, MenuItem, Typography, Card, CardContent, CardMedia, CardActions, CardActionArea, Link, Snackbar, CircularProgress, CardHeader, Avatar, useTheme } from '@material-ui/core';
import { Add, Delete, CloudDownload, Edit, OpenInNew } from '@material-ui/icons';
import ClipboardJS from "clipboard";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import appVars from '../../appVars.json';
import { Alert } from '@material-ui/lab';
import _ from "lodash";

const Tags = (props) => {
    const [tag, setTag] = useState({
        "pkRecID": "1",
        "clientid": props.clientID,
        "type": "",
        "settings": '{}',
        "tagProfile": ""
    });
    const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
    const id = getLastItem(window.location.href);
    

    const [tags, setTags] = useState([]);
    const [tagProfiles, setTagProfiles] = useState(false);
    const [open, setopen] = useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [selectedTAG, setSelectedTAG] = React.useState({});
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [copySuccess, setCopySuccess] = useState("");
    const theme = useTheme();

    const handleClose = () => {
        setopen(false);
        setCopySuccessShow(false);
    }

    const openNew = () => {
        setopen(true);
    }

    async function fetchData() {

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const res = await fetch(appVars.api_url + "/tag_profiles", requestOptions);
        res.json()
            .then((response) => {
                var k = _.sortBy(response, 'name');
                setTagProfiles(k);
            })
            .catch(error => console.log('error', error));

        const res2 = await fetch(appVars.api_url + "/tags/" + props.clientID, requestOptions);
        res2.json()
            .then((response) => {
                var k = _.sortBy(response, 'type');
                setTags(k);
                //console.log(JSON.parse(k[0].settings));
            })
            .catch(error => console.log('error', error));
    }


    useEffect(() => {

        if (tagProfiles === false) {
            fetchData();
        }
        var clipboard = new ClipboardJS(".copy");
        clipboard.on("success", function (e) {
            setCopySuccess(e.text);
            setCopySuccessShow(true);
            e.clearSelection();
        });
    });

    const TagTypes = ({ field, form, ...props }) => {

        //console.log(k);
        return <TextField select  {...field} {...props}
            onChange={(e) => {
                tag.tagProfile = e.target.value;
                tag.type = e.currentTarget.dataset.form;
                tag.settings = JSON.parse(e.currentTarget.dataset.settings);
                setTag(tag);
                //console.log(e.currentTarget.dataset.settings);
                //document.getElementById('name').value = e.currentTarget.dataset.form;
                //document.getElementById('logoHolder').src = appVars.api_url + '/imageFiles/' + e.target.value + '.png';
                openNew();
            }
            }
        >
            {tagProfiles.map((entry) => (
                <MenuItem key={entry.pkRecID} data-settings={entry.params} data-form={entry.name} value={entry.pkRecID}>{entry.name}</MenuItem>
            ))}
        </TextField>;
    };


    return (
        <>
            <Grid container spacing={1}  >

                <Grid item xl={2} md={12} xs={12}>
                    {(tagProfiles !== false ? <TagTypes InputLabelProps={{ shrink: true }} label="Add New Tag" variant="outlined" color="primary" margin="none" size="small" fullWidth /> : <CircularProgress></CircularProgress>)}
                </Grid>

                <Grid item xl={2} md={3} xs={12} >
                    <Button color="primary" fullWidth variant="contained" size="small" id="gtmcopy" title={'Download Google Tag Manager Install Instructions for ' + props.name} data-clipboard-text={appVars.lightwerksUrl + 'gtm-install/' + props.pkRecId}>Tag Manager Instuctions</Button>
                </Grid>

                <Grid item xl={2} md={3} xs={12}>
                    <Button fullWidth variant="contained" size="small" color="primary" href={appVars.api_url + '/generate-gtm-container/default/' + props.pkRecId}>Tag Manager Container</Button>
                </Grid>

                <Grid item xl={2} md={3} xs={12}>
                    <Button fullWidth variant="contained" size="small" color="primary" href={appVars.api_url + '/generate-gtm-container/' + props.donationtype + '/' + props.pkRecId}>Platform Settings</Button>

                </Grid>

                <Grid item xl={2} md={3} xs={12}>
                    <Button size="small" color="primary" fullWidth variant="contained" href={appVars.api_url + '/generate-gtm-container/fbapi/' + props.pkRecId} title={'Download Tags for ' + props.name} target="_BLANK" >Meta Convesion API</Button>
                </Grid>

            </Grid>
            <Divider style={{ margin: "15px auto" }}></Divider>


            {(tags ?
                <Grid container xl={12} spacing={1}>
                    {tags.map((entry) => (
                        <Grid item xl={3} md={3} sm={6} xs={12}>
                            <Card variant="outlined" >
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="recipe" variant="circular">
                                            <img style={{ height: 15 }} src={appVars.api_url + '/imageFiles/' + entry.tagProfile + '-icon.png'} alt="" />
                                        </Avatar>
                                    }
                                    title={entry.type}
                                //  subheader="September 14, 2016"
                                />
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <CardContent style={{ minHeight: 80, maxHeight: 100 }}>
                                        {Object.entries(JSON.parse(entry.settings)).map((entry2) => (
                                            <Typography variant="body2" color="textSecondary" component="p">{entry2[0]}: <Link className='copy' data-clipboard-text={entry.type + ' ' + entry2[0] + ' ' + entry2[1] + ' Copied!'}  >{entry2[1].length > 25 ? entry2[1].substring(0, 25) + '...' : entry2[1]}</Link></Typography>
                                        ))}
                                    </CardContent>
                                </div>
                                <CardActions>

                                    {(entry.tagProfile === "35d4eef1-71d1-11ee-bfcd-16725369f13b" ? <Button size="small" variant="contained" color="primary" href={appVars.api_url + '/generate-gtm-container/g4update/' + entry.pkRecId}><CloudDownload title="Download GA4 Update"></CloudDownload></Button> : '')}
                                    {(entry.tagProfile !== "6d20c78b-71cf-11ee-bfcd-16725369f13b1" ? <Button size="small" color="primary" href={appVars.api_url + '/generate-gtm-tag/' + entry.tagProfile + '/' + entry.clientid} target='_BLANK' variant="outlined" ><CloudDownload></CloudDownload></Button> : '')}
                                    {(entry.tagProfile === "6d20c78b-71cf-11ee-bfcd-16725369f13b" ? <Button size="small" id="fbcopy" color="primary" href={'https://business.facebook.com/events_manager2/list/pixel/' + entry.settings.id + '/overview'} title={'Open Pixel Overview for ' + entry.settings.id} target="_BLANK" ><OpenInNew></OpenInNew></Button> : '')}
                                    <Button size="small" color="primary" href={appVars.api_url + '/generate-gtm-tag/' + entry.tagProfile + '/' + entry.clientid} target='_BLANK' variant="outlined" ><Edit></Edit></Button>
                                    <Button size="small" color="primary" target='_BLANK' variant="outlined"
                                        onClick={(e) => {
                                            setSelectedTAG(entry);
                                            setOpenDelete(true);
                                        }}
                                    ><Delete color="primary"></Delete></Button>

                                </CardActions>

                            </Card>
                        </Grid>
                    ))}

                </Grid>

                : <CircularProgress></CircularProgress>)
            }

            <Snackbar
                open={copySuccessShow}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>


            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add New Tag
                    <img src={appVars.api_url + '/imageFiles/' + tag.tagProfile + '.png'} style={{ maxWidth: 100, float: 'right' }} id="logoHolder" alt='' />
                </DialogTitle>
                <DialogContent style={{ padding: "30px", borderBottom: "1px solid #cfcfcf" }}>
                    <DialogContentText>
                        Select Tag Type below to set up a new tag for TAG manager
                    </DialogContentText>

                    <Grid container spacing={2}>

                        <Grid item xl={12} md={12}>
                            <TextField
                                id="tagProfile"
                                type="hidden"
                            />
                            <TextField

                                margin="dense"
                                id="name"
                                label="Name Tag"
                                type="text"
                                disabled={true}
                                value={tag.type}
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    tag.type = e.target.value;
                                    setTag(tag);
                                    console.log(tag);
                                }}
                            />

                        </Grid>



                        {Object.entries(tag.settings).map((entry2) => (

                            <Grid item xl={6} md={6}>

                                <TextField

                                    margin="dense"
                                    id={entry2[0]}
                                    label={entry2[0].toUpperCase()}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        tag.settings[entry2[0]] = e.target.value;
                                        setTag(tag);
                                        console.log(tag);
                                    }}
                                />

                            </Grid>

                        ))}


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained" startIcon={<Add></Add>} onClick={async (e) => {

                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");

                        var requestOptions = {
                            method: 'POST',
                            redirect: 'follow',
                            headers: myHeaders,
                            body: JSON.stringify(tag)
                        };

                        const res = await fetch(appVars.api_url + "/tags/a", requestOptions);
                        res.json()
                            .then((response) => {
                                fetchData();
                                handleClose();
                            })
                            .then(result => console.log(result))
                            .catch(error => console.log('error', error));



                    }}>
                        Add New
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={openDelete}

            >
                <DialogTitle id="confirmation-dialog-title">Do you want to delete tag?</DialogTitle>
                <DialogContent dividers>
                    <img style={{ maxWidth: 100 }} src={appVars.api_url + '/imageFiles/' + selectedTAG.tagProfile + '.png'} alt='' /><br></br>

                    <Typography variant='body'>{selectedTAG.type}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        setOpenDelete(false);
                    }} autoFocus color="primary" variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={(e) => {
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");

                        var raw = JSON.stringify({
                            "pkRecID": selectedTAG.pkRecID
                        });

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        };
                        fetch(appVars.api_url + "/tags/d", requestOptions)
                            .then(response => response.text())
                            .then(result => setOpenDelete(false))
                            .then(result2 => fetchData())
                            .catch(error => console.log('error', error));
                    }} variant="contained" color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>



        </>
    );
};

Tags.propTypes = {

};

export default Tags;
