import React, { useState, useEffect } from 'react';
import { style } from './index.scss';
import { Link, Typography, Snackbar, CircularProgress, Button } from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import MaterialTable from 'material-table';
import appVars from '../appVars.json';
import _ from 'lodash';

const ErrorView = (props) => {
    const [loading, setLoading] = useState(true);
    const [errorlog, setErrorLog] = useState([]);
    const [error, setError] = useState({ show: false, message: 'Test', severity: 'error' });
    const [progress, setProgress] = useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError({ show: false, message: '', severity: 'success' });
    };

    async function fetchData() {
        const res5 = await fetch(appVars.lightwerks + "/clients/errors");
        res5
            .json()
            .then((res5) => {
                var a = res5;

                console.log(a.length);
                if (props.clientid !== undefined) {
                    a = _.filter(res5, ["clientid", props.clientid]);
                }

                setErrorLog(a);
                setProgress(true);
                setLoading(false);
            })
            .catch((err) => setError({ show: true, message: err.message, severity: 'error' }));

    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={style}>




            {(errorlog ?
                <MaterialTable
                    title={(props.clientid ? <Button color="secondary" variant='outlined' onClick={(e) => {

                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");

                        var raw = JSON.stringify({
                            "clientid": props.clientid
                        });

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        };

                        fetch(appVars.lightwerks + "/errors", requestOptions)
                            .then(response => fetchData())
                            .then(result => setError({ show: true, message: 'Errors Cleared!', severity: 'success' }))
                            .catch(error => setError({ show: true, message: error, severity: 'error' }))

                    }}>Clear Errors</Button> : '')}
                    
                    isLoading={loading}
                    columns={[
                        { title: "", type: "datetime", field: "createdAt", defaultSort: 'desc', cellStyle: { padding: "3px 10px" }, hidden: true },
                        {
                            title: "Name", field: "name", defaultSort: 'asc', cellStyle: { padding: "3px 10px" }
                            , render: rowData =>
                                <Typography style={{ fontSize: '13px' }}><Link href={'/subclientviewer/' + rowData.clientid}>{(rowData.name.length > 50 ? rowData.name.substring(0, 50) : rowData.name)} ...</Link></Typography>
                        },
                        {
                            title: "Error", field: "error_message", defaultSort: 'desc', cellStyle: { padding: "3px 10px" }, render: rowData =>
                                <Typography style={{ fontSize: '13px' }}>{(rowData.error_message.length > 75 ? rowData.error_message.substring(0, 75) : rowData.error_message)} ...</Typography>
                        }
                    ]}
                    data={errorlog}
                    options={{
                        rowStyle: { fontSize: '13px', padding: 0, margin: 0 },
                        headerStyle: { fontSize: '13px' },
                        search: true,
                        grouping: true,
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 20, 25, 40, 50, 100],
                        showTitle: true,
                        paging: true,
                        header: true

                    }}
                />
                : <CircularProgress hidden={progress} ></CircularProgress>
            )}








            <Snackbar open={error.status} autoHideDuration={3500} onClose={handleClose}>
                <Alert severity={error.severity} onClose={handleClose}>
                    {error.msg}
                </Alert>
            </Snackbar>

        </div>
    );
};

ErrorView.propTypes = {

};

export default ErrorView;
