import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import logo from '../Digital-Dashboard-Logo-FINAL.png';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import { setCookie, getCookie } from '../dwi-functions';
import Avatar from '@material-ui/core/Avatar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import appVars from '../appVars.json';
import { googleLogout } from '@react-oauth/google';
import { RiPictureInPictureExitLine, RiSettings5Line, RiDashboardFill, RiMailCheckLine, RiUser2Fill, RiLogoutBoxFill } from "react-icons/ri";
import PlatformSearch from "../clients/controls/platform-search";
import { ArrowBack, Search } from "@material-ui/icons";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import ClientSearch from "../clients/controls/client-search";

const drawerWidth = 240;




var ui = window.location.pathname.split("/");
var id = (ui[1] === '' ? 'main' : ui[1]);



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  avatar: { float: 'right' },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.79),
    },
    marginRight: theme.spacing(1),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  siteTitle: {
    width: "100%"
  },
  logoContain: {
    //maxWidth: "150px",
    flexGrow: 1
  },
  logo: {
    maxWidth: "100%"
  }
}));


const menuIcons = {
  chevron: <ChevronRightIcon />,
  dashboard: <RiDashboardFill />,
  report: <AssessmentIcon />,
  settings: <RiSettings5Line />,
  user: <RiUser2Fill />,
  back: <ArrowBack />,
  RiPictureInPictureExitLine: <RiPictureInPictureExitLine />,
  logout: <RiLogoutBoxFill />
  ,
  link: <RiMailCheckLine />
};


export default function MiniDrawer(props) {

  const location = useLocation();
  var menuid = location.pathname.split("/")[1];
  var id = location.pathname.split("/")[2];

  const searchParams = new URLSearchParams(location.search);


  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [admintoken, setAdmin] = useState(sessionStorage.getItem('_buta'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [hasError, setErrors] = useState(false);
  
  const [domains, setDomains] = React.useState([
    { siteUrl: "Search" }
  ]);

  const [menu, setMenu] = useState([]);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const logOut = () => {
    setCookie('_bu', '', -1);
    setCookie('_buta', '', -1);
    setCookie('_buta_s', '', -1);
    sessionStorage.removeItem("_buta_s");
    sessionStorage.removeItem("_bu");
    sessionStorage.removeItem("_buta");
    googleLogout();
    window.location.href = "/";


  }

  //console.log(location);
  setCookie('_au', 0, 7);


  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => { window.location = "/account/" + getCookie('_bu'); }}>My account</MenuItem>
      {(admintoken >= 10 ? <MenuItem onClick={() => { window.location = "/control"; }}>Settings</MenuItem> : '')}
      <MenuItem onClick={logOut}>Logout</MenuItem>


    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => { window.location = "/account/" + getCookie('_bu'); }}>My account</MenuItem>
      {(admintoken >= 10 ? <MenuItem onClick={() => { window.location = "/control"; }}>Settings</MenuItem> : '')}
      <MenuItem onClick={logOut}>Logout</MenuItem>
    </Menu>
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };



  async function fetchData() {

    const res = await fetch(appVars.api_url + "/customers");
    res
      .json()
      .then((res) => {
        setDomains(res);
        //console.log(appVars.api_url + "/tagmanagers");
        //  setLoading(false);
      })
      .catch((err) => setErrors(true));

    const res2 = await fetch(appVars.api_url + "/menus/" + (menuid ? menuid : "main"));
    res2
      .json()
      .then((res2) => {
        setMenu(JSON.parse(res2[0]['menu']));
        //console.log(res2[0]['menu']);
        //  setLoading(false);
      })
      .catch((err) => setErrors(true));




  }


  useEffect(() => {
    fetchData();
  }, []);




  return (
    <div className={classes.root}>

      <CssBaseline />

      <AppBar
        position="fixed"
        color="primary"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.logoContain}>

          </div>
          {(admintoken >= 10 ?
            <div className={classes.search}>
              <PlatformSearch title="Platforms Search...." ></PlatformSearch>
            </div>
            : '')}


          <div className={classes.search}>
            <ClientSearch
              onChange={(e, ne) => {
                window.location = '/subclientviewer/' + ne.id;
                //console.log(ne);
              }}

            ></ClientSearch>
          </div>



          <div>
            <div className={classes.grow} />

            <div className={classes.sectionDesktop}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar variant="circular"  src={appVars.api_url + '/imageFiles/' + getCookie("_bu") + '.png'} />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </div>
        </Toolbar>

      </AppBar>
      {renderMobileMenu}
      {renderMenu}


      <Drawer
        style={{ background: theme.palette.secondary.light }}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <div className={classes.logoContain}>
            <Link href="/"> <img src={logo} className={classes.logo} alt={props.siteTitle}></img></Link>
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {menu.map((text, index) => (
            <Link href={text['path']} key={index}>
              <ListItem button key={index}>
                <ListItemIcon style={{ fontSize: "24px" }}>{menuIcons[text["icon"]]}</ListItemIcon>
                <ListItemText primary={text['name']} />
              </ListItem>
            </Link>
          ))}

        </List>

      </Drawer>


    </div>
  );
}
