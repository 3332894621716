import { AppBar, Card, CardContent, CardHeader, Grid, Typography, LinearProgress, Tabs, Tab, Box } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ChartCard from '../../../chart-card';
import { NumericFormat } from 'react-number-format';
import TableCard from '../../../table-card';
import { lightenHexColor, darkenHexColor } from '../../../../dwi-functions';
import moment from 'moment';
import Comments from '../../../../system/control/comments';
import appVars from '../../../../appVars.json';


const fontSize = 'initial';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const StackAdapt = (props) => {
    const [creativeData, setCreativeData] = useState();
    const [campaignData, setCampaignData] = useState();

    const [data, setStackAdaptData] = useState();
    const [typeSelect, setTypeSelect] = useState([{ CAMPAIGN_TYPE: 'native' }]);
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(0);
    const [admintoken, setAdmin] = useState(props.admintoken);
    const theme = createTheme({
        palette: {
            primary: {
                main: props.themeColor.primary, // Default primary color
            },
            secondary: {
                main: props.themeColor.secondary, // Default primary color
            },
        },
        overrides: {
            // Style overrides for MuiTab
            MuiTab: {
                // Target the text color of the selected tab
                textColorPrimary: {
                    '&$selected': {
                        color: props.themeColor.secondary // Example: Deep orange color for the selected tab
                    }
                }
            }
        }

    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    async function fetchReportData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "aid": props.advertiser_id,
            "sdate": props.sDate,
            "edate": props.eDate,
            "type": typeSelect
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res4 = await fetch(appVars.api_url + "/stackadapt/data", requestOptions);
        //  console.log(typeSelect);
        res4
            .json()
            .then((res4) => {
                console.log(res4[0]);
                setStackAdaptData(res4[0]['data']);
                // setAccountData(res4[0]['accounts']);
                console.log(res4[0]);
                setTypes(res4[0]['types']);
                // setCustomers(res4[0]['accountnames']);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });

        const campTable = await fetch(appVars.api_url + "/stackadapt/campaigns", requestOptions);
        //    console.log(props);
        campTable
            .json()
            .then((campaigns) => {
                setCampaignData(campaigns);

            })
            .catch((err) => {
                console.log(err);
            });

        const creativeTable = await fetch(appVars.api_url + "/stackadapt/creatives", requestOptions);
        //    console.log(props);
        creativeTable
            .json()
            .then((creatives) => {
                setCreativeData(creatives);

            })
            .catch((err) => {
                console.log(err);
            });




    }

    useEffect(() => {
        // if(!data){
        fetchReportData();
        //  console.log(data);
        //  }
    }, [props, typeSelect]);





    return (
        <div >



            {(data ?
                <div>
                    <AppBar variant="outlined" color='secondary' position="relative" style={{ margin: theme.spacing(0) }} >

                    </AppBar>

                    <AppBar variant="outlined" color='secondary' position="relative" style={{ margin: theme.spacing(0) }} >
                        <Grid container alignItems='center' >
                            <Grid item sm={10}>
                                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="standard" scrollButtons="auto">
                                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="standard" scrollButtons="auto" >
                                        <Tab label="Native ADS" {...a11yProps(0)} />
                                        <Tab label="Display ADS" {...a11yProps(1)} />
                                        <Tab label="Video ADS" {...a11yProps(2)} />
                                        <Tab label="Audio ADS" {...a11yProps(3)} />
                                        <Tab label="Notes" {...a11yProps(4)} />
                                    </Tabs>
                                </Tabs>
                            </Grid>
                        </Grid>
                    </AppBar>




                    <TabPanel value={value} index={0} id="native">

                        <Grid container spacing={2} alignContent="space-between">


                            {admintoken.token >= 8 && (
                                <Grid container justifyContent='flex-end'>
                                    <Grid item sm={1}>
                                        <Typography>Cost: <NumericFormat decimalScale={2} value={data.native[0].cost.count} thousandSeparator="," displayType="text" prefix="$" /></Typography>
                                    </Grid>
                                    <Grid item sm={1} style={{ display: 'initial' }}>
                                        <Typography>ROAS: <NumericFormat decimalScale={2} value={(data.native[0].revenue.count / data.native[0].cost.count)} displayType="text" /></Typography>
                                    </Grid>
                                </Grid>
                            )}



                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Impressions"
                                    data={data.native[0].impressions}
                                    client={props.client}
                                    color={props.themeColor}
                                    view="stackadapt"
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Clicks"
                                    data={data.native[0].clicks}
                                    client={props.client}
                                    color={props.themeColor}
                                    view="stackadapt"
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CTR"
                                    data={data.native[0].ctr}
                                    client={props.client}
                                    color={props.themeColor}
                                    view="stackadapt"
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CPM"
                                    data={data.native[0].cpm}
                                    client={props.client}
                                    color={props.themeColor}
                                    view="stackadapt"
                                />

                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item xl={3} sm={3} xs={12} >
                                <ChartCard
                                    title="Conversions"
                                    data={data.native[0].conversions}
                                    client={props.client}
                                    color={props.themeColor}
                                    view="stackadapt"
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Revenue"
                                    data={data.native[0].revenue}
                                    client={props.client}
                                    color={props.themeColor}
                                    view="stackadapt"
                                />


                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Conversion Rate"
                                    data={data.native[0].convrate}
                                    client={props.client}
                                    color={props.themeColor}
                                    view="stackadapt"
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Avg Gift"
                                    data={data.native[0].avgGift}
                                    client={props.client}
                                    color={props.themeColor}
                                    view="stackadapt"
                                />

                            </Grid>
                        </Grid>



                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item sm={12} xs={12} >
                                {(campaignData ?
                                    <TableCard
                                        options={{

                                            cellStyle: {
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                padding: theme.spacing(1)
                                            },
                                            grouping: false,
                                            searchFieldVariant: "outlined",
                                            exportButton: true,
                                            exportAllData: true,
                                            search: true,
                                            paging: (campaignData.native.length > 10 ? true : false),
                                            exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                            pageSizeOptions: [10, 25, 50, 100],
                                            showTitle: false,
                                            toolbar: true,
                                            sort: true
                                        }}
                                        title="Campaigns"
                                        data={campaignData.native}
                                        count={(campaignData.native.length > 10 ? 10 : campaignData.native.length)}
                                        client={props.client}
                                        themeColor={props.themeColor}
                                        columns={[
                                            { title: "Campaign",
                                            cellStyle: {
                                                backgroundColor: lightenHexColor("#cecece", 0),
                                                textAlign: 'center',
                                                width: 300,
                                                fontSize: fontSize,
                                                padding: theme.spacing(1),
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0),
                                            },
                                             field: "CAMPAIGN", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CAMPAIGN}</Typography> },
                                        ]}
                                    />
                                    : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                            </Grid>



                            <Grid item sm={12} xs={12} >
                                {(creativeData ?
                                    <TableCard
                                        options={{

                                            cellStyle: {
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                padding: theme.spacing(1)
                                            },
                                            grouping: false,
                                            searchFieldVariant: "outlined",
                                            exportButton: true,
                                            exportAllData: true,
                                            search: true,
                                            paging: (creativeData.native.length > 10 ? true : false),
                                            exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                            pageSizeOptions: [10, 25, 50, 100],
                                            showTitle: false,
                                            toolbar: true,
                                            sort: true
                                        }}
                                        title="Creatives"
                                        data={creativeData.native}
                                        count={(creativeData.native.length > 10 ? 10 : creativeData.native.length)}
                                        client={props.client}
                                        themeColor={props.themeColor}
                                        columns={[
                                            { title: "Creative",
                                            cellStyle: {
                                                backgroundColor: lightenHexColor("#cecece", 0),
                                                textAlign: 'center',
                                                width: 300,
                                                fontSize: fontSize,
                                                padding: theme.spacing(1),
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0),
                                            }, field: "CREATIVE", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CREATIVE}</Typography> },
                                        ]}
                                    />
                                    : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                            </Grid>


                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1} id="display">

                        <Grid container spacing={2} alignContent="space-between">
                            {admintoken.token >= 8 && (
                                <Grid container justifyContent='flex-end'>
                                    <Grid item sm={1}>
                                        <Typography>Cost: <NumericFormat decimalScale={2} value={data.display[0].cost.count} thousandSeparator="," displayType="text" prefix="$" /></Typography>
                                    </Grid>
                                    <Grid item sm={1} style={{ display: 'initial' }}>
                                        <Typography>ROAS: <NumericFormat decimalScale={2} value={(data.display[0].revenue.count / data.display[0].cost.count)} displayType="text" /></Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Impressions"
                                    data={data.display[0].impressions}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Clicks"
                                    data={data.display[0].clicks}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CTR"
                                    data={data.display[0].ctr}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CPM"
                                    data={data.display[0].cpm}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item xl={3} sm={3} xs={12} >
                                <ChartCard
                                    title="Conversions"
                                    data={data.display[0].conversions}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Revenue"
                                    data={data.display[0].revenue}
                                    client={props.client}
                                    color={props.themeColor}
                                />


                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Conversion Rate"
                                    data={data.display[0].convrate}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Avg Gift"
                                    data={data.display[0].avgGift}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                        </Grid>



                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item sm={12} xs={12} >
                                {(campaignData ?
                                    <TableCard
                                        options={{

                                            cellStyle: {
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                padding: theme.spacing(1)
                                            },
                                            grouping: false,
                                            searchFieldVariant: "outlined",
                                            exportButton: true,
                                            exportAllData: true,
                                            search: true,
                                            paging: (campaignData.display.length > 10 ? true : false),
                                            exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                            pageSizeOptions: [10, 25, 50, 100],
                                            showTitle: false,
                                            toolbar: true,
                                            sort: true
                                        }}
                                        title="Campaigns"
                                        data={campaignData.display}
                                        count={(campaignData.display.length > 10 ? 10 : campaignData.display.length)}
                                        client={props.client}
                                        themeColor={props.themeColor}
                                        columns={[
                                            { title: "Campaign Name",
                                            cellStyle: {
                                                backgroundColor: lightenHexColor("#cecece", 0),
                                                textAlign: 'center',
                                                width: 300,
                                                fontSize: fontSize,
                                                padding: theme.spacing(1),
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0),
                                            },
                                             field: "CAMPAIGN", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CAMPAIGN}</Typography> },
                                        ]}
                                    />
                                    : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                            </Grid>



                            <Grid item sm={12} xs={12} >
                                {(creativeData ?
                                    <TableCard
                                        options={{

                                            cellStyle: {
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                padding: theme.spacing(1)
                                            },
                                            grouping: false,
                                            searchFieldVariant: "outlined",
                                            exportButton: true,
                                            exportAllData: true,
                                            search: true,
                                            paging: (campaignData.display.length > 10 ? true : false),
                                            exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                            pageSizeOptions: [10, 25, 50, 100],
                                            showTitle: false,
                                            toolbar: true,
                                            sort: true
                                        }}
                                        title="Creatives"
                                        data={creativeData.display}
                                        count={(campaignData.display.length > 10 ? 10 : campaignData.display.length)}
                                        client={props.client}
                                        themeColor={props.themeColor}
                                        columns={[
                                            { title: "Creative",
                                            cellStyle: {
                                                backgroundColor: lightenHexColor("#cecece", 0),
                                                textAlign: 'center',
                                                width: 300,
                                                fontSize: fontSize,
                                                padding: theme.spacing(1),
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0),
                                            },
                                             field: "CREATIVE", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CREATIVE}</Typography> },
                                        ]}
                                    />
                                    : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                            </Grid>


                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2} id="video">
                        <Grid container spacing={2} alignContent="space-between">
                            {admintoken.token >= 8 && (
                                <Grid container justifyContent='flex-end'>
                                    <Grid item sm={1}>
                                        <Typography>Cost: <NumericFormat decimalScale={2} value={data.video[0].cost.count} thousandSeparator="," displayType="text" prefix="$" /></Typography>
                                    </Grid>
                                    <Grid item sm={1} style={{ display: 'initial' }}>
                                        <Typography>ROAS: <NumericFormat decimalScale={2} value={(data.video[0].revenue.count / data.video[0].cost.count)} displayType="text" /></Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Impressions"
                                    data={data.video[0].impressions}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Clicks"
                                    data={data.video[0].clicks}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CTR"
                                    data={data.video[0].ctr}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CPM"
                                    data={data.video[0].cpm}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item xl={3} sm={3} xs={12} >
                                <ChartCard
                                    title="Conversions"
                                    data={data.video[0].conversions}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Revenue"
                                    data={data.video[0].revenue}
                                    client={props.client}
                                    color={props.themeColor}
                                />


                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Conversion Rate"
                                    data={data.video[0].convrate}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Avg Gift"
                                    data={data.video[0].avgGift}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                        </Grid>



                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item sm={12} xs={12} >
                                {(campaignData ?
                                    <TableCard
                                        options={{

                                            cellStyle: {
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                padding: theme.spacing(1)
                                            },
                                            grouping: false,
                                            searchFieldVariant: "outlined",
                                            exportButton: true,
                                            exportAllData: true,
                                            search: true,
                                            paging: (campaignData.video.length > 10 ? true : false),
                                            exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                            pageSizeOptions: [10, 25, 50, 100],
                                            showTitle: false,
                                            toolbar: true,
                                            sort: true
                                        }}
                                        title="Campaigns"
                                        data={campaignData.video}
                                        count={(campaignData.video.length > 10 ? 10 : campaignData.video.length)}
                                        client={props.client}
                                        themeColor={props.themeColor}
                                        columns={[
                                            { title: "Campaign Name",
                                            cellStyle: {
                                                backgroundColor: lightenHexColor("#cecece", 0),
                                                textAlign: 'center',
                                                width: 300,
                                                fontSize: fontSize,
                                                padding: theme.spacing(1),
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0),
                                            }, field: "CAMPAIGN", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CAMPAIGN}</Typography> },
                                        ]}
                                    />
                                    : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                            </Grid>



                            <Grid item sm={12} xs={12} >
                                {(creativeData ?
                                    <TableCard
                                        options={{

                                            cellStyle: {
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                padding: theme.spacing(1)
                                            },
                                            grouping: false,
                                            searchFieldVariant: "outlined",
                                            exportButton: true,
                                            exportAllData: true,
                                            search: true,
                                            paging: (creativeData.video.length > 10 ? true : false),
                                            exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                            pageSizeOptions: [10, 25, 50, 100],
                                            showTitle: false,
                                            toolbar: true,
                                            sort: true
                                        }}
                                        title="Creatives"
                                        data={creativeData.video}
                                        count={(creativeData.video.length > 10 ? 10 : creativeData.video.length)}
                                        client={props.client}
                                        themeColor={props.themeColor}
                                        columns={[
                                            { title: "Creative",
                                            cellStyle: {
                                                backgroundColor: lightenHexColor("#cecece", 0),
                                                textAlign: 'center',
                                                width: 300,
                                                fontSize: fontSize,
                                                padding: theme.spacing(1),
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0),
                                            },
                                             field: "CREATIVE", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CREATIVE}</Typography> },
                                        ]}
                                    />
                                    : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                            </Grid>


                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3} id="audio">


                        <Grid container spacing={2} alignContent="space-between">
                            {admintoken.token >= 8 && (
                                <Grid container justifyContent='flex-end'>
                                    <Grid item sm={1}>
                                        <Typography>Cost: <NumericFormat decimalScale={2} value={data.audio[0].cost.count} thousandSeparator="," displayType="text" prefix="$" /></Typography>
                                    </Grid>
                                    <Grid item sm={1} style={{ display: 'initial' }}>
                                        <Typography>ROAS: <NumericFormat decimalScale={2} value={(data.audio[0].revenue.count / data.audio[0].cost.count)} displayType="text" /></Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Impressions"
                                    data={data.audio[0].impressions}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Clicks"
                                    data={data.audio[0].clicks}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CTR"
                                    data={data.audio[0].ctr}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CPM"
                                    data={data.audio[0].cpm}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item xl={3} sm={3} xs={12} >
                                <ChartCard
                                    title="Conversions"
                                    data={data.audio[0].conversions}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Revenue"
                                    data={data.audio[0].revenue}
                                    client={props.client}
                                    color={props.themeColor}
                                />


                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Conversion Rate"
                                    data={data.audio[0].convrate}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Avg Gift"
                                    data={data.audio[0].avgGift}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                        </Grid>



                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item sm={12} xs={12} >
                                {(campaignData ?
                                    <TableCard
                                        options={{

                                            cellStyle: {
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                padding: theme.spacing(1)
                                            },
                                            grouping: false,
                                            searchFieldVariant: "outlined",
                                            exportButton: true,
                                            exportAllData: true,
                                            search: true,
                                            paging: (campaignData.native.length > 10 ? true : false),
                                            exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                            pageSizeOptions: [10, 25, 50, 100],
                                            showTitle: false,
                                            toolbar: true,
                                            sort: true
                                        }}
                                        title="Campaigns"
                                        data={campaignData.audio}
                                        count={(campaignData.audio.length > 10 ? 10 : campaignData.audio.length)}
                                        client={props.client}
                                        themeColor={props.themeColor}
                                        columns={[
                                            { title: "Campaign Name",
                                            cellStyle: {
                                                backgroundColor: lightenHexColor("#cecece", 0),
                                                textAlign: 'center',
                                                width: 300,
                                                fontSize: fontSize,
                                                padding: theme.spacing(1),
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0),
                                            },
                                             field: "CAMPAIGN", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CAMPAIGN}</Typography> },
                                        ]}
                                    />
                                    : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                            </Grid>



                            <Grid item sm={12} xs={12} >
                                {(creativeData ?
                                    <TableCard
                                        options={{

                                            cellStyle: {
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                padding: theme.spacing(1)
                                            },
                                            grouping: false,
                                            searchFieldVariant: "outlined",
                                            exportButton: true,
                                            exportAllData: true,
                                            search: true,
                                            paging: (creativeData.audio.length > 10 ? true : false),
                                            exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                            pageSizeOptions: [10, 25, 50, 100],
                                            showTitle: false,
                                            toolbar: true,
                                            sort: true
                                        }}
                                        title="Creatives"
                                        data={creativeData.audio}
                                        count={(creativeData.audio.length > 10 ? 10 : creativeData.audio.length)}
                                        client={props.client}
                                        themeColor={props.themeColor}
                                        columns={[
                                            { title: "Creative",
                                            cellStyle: {
                                                backgroundColor: lightenHexColor("#cecece", 0),
                                                textAlign: 'center',
                                                width: 300,
                                                fontSize: fontSize,
                                                padding: theme.spacing(1),
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0),
                                            },
                                             field: "CREATIVE", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CREATIVE}</Typography> },
                                        ]}
                                    />
                                    : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                            </Grid>


                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={4} id="notes">
                        <Comments eDate={props.eDate} sDate={props.sDate} themeColor={props.themeColor} admintoken={admintoken} view={(admintoken.token >= 6 ? "5af0a295-8b57-4ccb-8cc8-d54e5bb278e6" : "a7edd5c0-7a77-49ed-86ad-6fe3066a0f59")} type="stackadapt"></Comments>

                    </TabPanel>

                </div>
                : <div style={{ padding: theme.spacing(8)}} ><Typography color='secondary' variant='h6' align='center'>Loading {props.title} Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(8) }}></LinearProgress></div>)}







        </div>
    );
};

StackAdapt.propTypes = {

};

export default StackAdapt;
