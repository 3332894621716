import React, { useState, useEffect } from 'react';
import { style } from './index.scss';
import LightCard from '../LightCard';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Card, CardHeader, CardMedia, CardContent, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import appVars from '../appVars.json';



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    media: {
        height: 145,
        backgroundPosition: 'top center'
    },
    appBar: {

        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));


const LightGrid = (props) => {

    const [hasError, setErrors] = useState(false);
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState([true]);


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrors(false);
    };


    async function fetchData(id) {

        console.log('ID:' + id);
        var url = appVars.lightwerks + "/lightboxes/" + id;
        if (id === '') {
            url = appVars.lightwerks + "/lightboxes/all";

        }

        const res = await fetch(url);
        // console.log(res);
        res
            .json()
            .then(res => setCards(res))
            .then(res => setLoading(false))
            .catch(err => setErrors(true));


    }


    useEffect(() => {
        const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1);
        const id = getLastItem(window.location.href);
        //  console.log(props.cards);
        if (props.cards === undefined) {
            fetchData(id);
        } else {
            setCards(props.cards);
            setLoading(false);
        }



        console.log(cards);
    }, [props]);


    const classes = useStyles();
    // eslint-disable-next-line
    const [spacing, setSpacing] = React.useState(1);

    var arr = [];
    var d = [];
    Object.keys(cards).forEach((key, index) =>
        d[key] = cards[key],
        arr.push(d)
    );
    //console.log(arr);
    return (
        <div className={style}>


            {loading ? (


                <Grid container spacing={spacing}>

                    {[1, 2, 3, 4].map((card, index) => (
                        <Grid item xl={3} lg={3} md={4} sm={6} xs={12} key={index}>
                            <Card>
                                <CardHeader
                                    title={<Skeleton animation="wave" width="40%" component="h2" style={{ marginBottom: 6 }} />}
                                >

                                </CardHeader>
                                <CardMedia>
                                    <Skeleton animation="wave" variant="rect" className={classes.media} />
                                </CardMedia>
                                <CardContent>
                                    <Skeleton animation="wave" component="h1" width="65%" style={{ marginBottom: 6 }} />
                                    <Skeleton animation="wave" style={{ marginBottom: 6 }} />
                                    <Skeleton animation="wave" style={{ marginBottom: 6 }} />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>


            ) : (


                <Grid container spacing={2} alignItems="stretch" >
                    {Array.isArray(arr[0]) && arr[0].map((card, i) => {
                        try {
                            // Parse JSON fields safely
                            const embed = JSON.parse(card.embed);
                            const image = JSON.parse(card.image);

                            // Create a new object with parsed data
                            const set = {
                                ...card,
                                embed,
                                image
                            };
//console.log(set)
                            // Return the LightCard component with parsed props
                            return (
                                <LightCard key={i} {...set} siteID={props.beaconId} />
                            );
                        } catch (error) {
                            console.error("Error parsing JSON:", error);
                            return null; // Return null if there's an error
                        }
                    })}


                </Grid>



            )}
            <Snackbar open={hasError} autoHideDuration={6000} onClose={handleClose}  >
                <Alert onClose={handleClose} severity="error">
                    The data can not be pulled at this time.
                    <Button color="primary" onClick={window.location.reload}>Refresh</Button>
                </Alert>
            </Snackbar>

        </div>
    );
};

LightGrid.propTypes = {

};

export default LightGrid;
