import React, { useState, useEffect } from 'react';
import {
    AppBar, Chip, Grid, Typography,
    LinearProgress, Toolbar, Tabs, Tab, Box, TextField
} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import ChartCard from '../../../chart-card';
import { NumericFormat } from 'react-number-format';
import TableCard from '../../../table-card';
import Autocomplete from '@material-ui/lab/Autocomplete';
import appVars from '../../../../appVars.json';
import moment from 'moment';
import Comments from '../../../../system/control/comments';

const fontSize = 'initial';



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Google = (props) => {
    const [value, setValue] = useState(0);

    const [adNetworkData, setAdNetworkData] = useState([{ CAMPAIGN_TYPE: "SEARCH" }, { CAMPAIGN_TYPE: "YOUTUBE" }]);
    const [searchData, setSearchData] = useState();
    const [data, setData] = useState();
    const [dataType, setDataType] = useState([{ CAMPAIGN_TYPE: "SEARCH" }]);
    const [loading, setLoading] = useState(true);
    const admintoken = props.admintoken;
    const [customers, setCustomers] = useState();
    const [accounts, setAccounts] = useState();
    const [exclude, setexclude] = useState([]);
    const [customerSelect, setCustomerSelect] = useState([]);

    const theme = createTheme();
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box p={0} paddingLeft={"8px"} paddingRight={"8px"} >{children}</Box>}
            </div>
        );
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 1:
                setDataType([{ CAMPAIGN_TYPE: "PERFORMANCE_MAX" }]);
                fetchReportData([{ CAMPAIGN_TYPE: "PERFORMANCE_MAX" }]);
                break;
            case 2:
                fetchSearchData();
                break;
            default:
                fetchReportData(dataType);
                break;
        }
    };

    const fetchReportData = async (type) => {
        setLoading(true);
        const requestOptions = getRequestOptions(type);

        try {
            const [accounts, campTable, customerTable] = await Promise.all([
                fetch(appVars.api_url + "/google/accountids/get", requestOptions),
                fetch(appVars.api_url + "/google/paid/account", requestOptions),
                fetch(appVars.api_url + "/google/paid/accounts", requestOptions),
            ]);

            const [accountsJson, campTableJson, customerJson] = await Promise.all([
                accounts.json(), campTable.json(), customerTable.json()
            ]);

            setData(campTableJson);
            setCustomers(customerJson[0]);
            setAdNetworkData(customerJson[1])
            setAccounts(accountsJson);

            console.log(customerJson)
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchSearchData = async () => {
        setLoading(true);
        const requestOptions = getRequestOptions(dataType);

        try {
            const searchTable = await fetch(appVars.api_url + "/google/paid/search", requestOptions);
            const searchTableJson = await searchTable.json();
            setSearchData(searchTableJson);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getRequestOptions = (type) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "aid": props.advertiser_id,
            "sdate": props.sDate,
            "edate": props.eDate,
            "account": props.which,
            "type": type
        });

        return {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
    };

    useEffect(() => {

        fetchReportData(dataType);

        // eslint-disable-next-line
    }, [props, dataType]);

    return (
        <div>
            {data ? (
                <div>
                    <AppBar variant="outlined" color='secondary' position="relative" style={{ margin: theme.spacing(0) }}>
                        <Grid container alignItems='center'>
                            <Grid item sm={10}>
                                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="standard" scrollButtons="auto">
                                    <Tab label={props.title} {...a11yProps(0)} />
                                    <Tab label="Search" {...a11yProps(1)} />

                                    <Tab label="Notes" {...a11yProps(3)} />
                                </Tabs>
                            </Grid>
                            {admintoken.token >= 8 && (
                                <>
                                    <Grid item sm={1}>
                                        <Typography>Cost: <NumericFormat decimalScale={2} value={data.cost.count} thousandSeparator="," displayType="text" prefix="$" /></Typography>
                                    </Grid>
                                    <Grid item sm={1}>
                                        <Typography>ROAS: <NumericFormat decimalScale={2} value={(data.revenue.count / data.cost.count)} displayType="text" /></Typography>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </AppBar>

                    <TabPanel value={value} index={0}>
                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item xl={3} sm={6} xs={12}>
                                <Toolbar style={{ padding: 0 }}>
                                    {(accounts ? <Autocomplete
                                        value={customerSelect}
                                        fullWidth
                                        multiple={true}
                                        options={accounts}
                                        getOptionLabel={(option) => option.ACCOUNT_NAME || 'Unknown Customer'}
                                        renderInput={(params) => <TextField {...params} label="Choose a Customer to EXCLUDE:" variant='outlined' />}
                                        onChange={(e, newValue) => {
                                            const reportReturn = Object.keys(newValue).map((key, index) => (
                                                newValue[index].ACCOUNT_ID
                                            ));
                                            e.stopPropagation();
                                            setexclude(reportReturn);
                                            setCustomerSelect(newValue);
                                            //   console.log(reportReturn);
                                        }}
                                    /> : '')}
                                </Toolbar>
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                {props.which !== 'Grant' && (
                                    <Toolbar style={{ padding: 0 }}>
                                        <Autocomplete
                                            options={adNetworkData}
                                            value={dataType}
                                            multiple
                                            color="secondary"
                                            fullWidth
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        label={option.CAMPAIGN_TYPE}
                                                        {...getTagProps({ index })}
                                                        style={{ backgroundColor: props.themeColor.secondary, color: props.themeColor.primary }}
                                                    />
                                                ))
                                            }
                                            getOptionLabel={(option) => option.CAMPAIGN_TYPE}
                                            renderInput={(params) => <TextField {...params} label="Choose an Ad Network" variant="outlined" />}
                                            onChange={(e, newValue) => {
                                                e.stopPropagation();
                                                setDataType(newValue.length > 0 ? newValue : [{ CAMPAIGN_TYPE: "SEARCH" }]);
                                            }}
                                        />
                                    </Toolbar>
                                )}
                            </Grid>
                            <Grid item xl={6} sm={6} xs={12}></Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard title="Impressions" data={data.impressions} client={props.client} color={props.themeColor} />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard title="Clicks" data={data.clicks} client={props.client} color={props.themeColor} />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard title="CTR" data={data.ctr} client={props.client} color={props.themeColor} />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard title="CPC" data={data.cpc} client={props.client} color={props.themeColor} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignContent="space-between">
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard title="Conversions" data={data.conversions} client={props.client} color={props.themeColor} />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard title="Revenue" data={data.revenue} client={props.client} color={props.themeColor} />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard title="Conversion Rate" data={data.convrate} client={props.client} color={props.themeColor} />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard title="Avg Gift" data={data.avgGift} client={props.client} color={props.themeColor} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignContent="space-between">
                            <Grid item sm={12} xs={12}>
                                {customers ? (
                                    <TableCard
                                        admintoken={admintoken}
                                        options={{
                                            grouping: false,
                                            searchFieldVariant: "outlined",
                                            exportButton: true,
                                            exportAllData: true,
                                            search: true,
                                            paging: customers.length > 10,
                                            exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                            pageSizeOptions: [10, 25, 50, 100],
                                            showTitle: false,
                                            toolbar: true,
                                            sort: true
                                        }}
                                        title="Clients"
                                        data={customers}
                                        count={customers.length}
                                        client={props.client}
                                        themeColor={props.themeColor}
                                        columns={[
                                            { title: "Client", field: "ACCOUNT_NAME", render: rowdata => <Typography align='left' style={{ fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.ACCOUNT_NAME}</Typography> }
                                        ]}
                                    />
                                ) : (
                                    <div>
                                        <Typography color="secondary" variant='h6' align='center'>Loading Campaign Data</Typography>
                                        <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }} />
                                    </div>
                                )}
                            </Grid>




                        </Grid>
                    </TabPanel>

                    <TabPanel value={value} index={1} data-title="Search Data">
                        <Grid container spacing={2} alignContent="space-between">
                            <Grid item sm={12} xs={12}>
                                {searchData ? (
                                    <TableCard
                                        title="Search"
                                        data={searchData}
                                        count={searchData.length > 10 ? 10 : searchData.length}
                                        client={props.client}
                                        themeColor={props.themeColor}
                                        options={{
                                            grouping: false,
                                            exportButton: true,
                                            exportAllData: true,
                                            search: true,
                                            searchFieldVariant: "outlined",
                                            paging: searchData.length > 10,
                                            exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                            pageSizeOptions: [10, 25, 50, 100],
                                            showTitle: false,
                                            toolbar: true,
                                            sort: true
                                        }}
                                        columns={[
                                            { title: "Search Term", field: "SEARCH", render: rowdata => <Typography align='left' style={{ fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.SEARCH}</Typography> }
                                        ]}
                                    />
                                ) : (
                                    <div>
                                        <Typography color="secondary" variant='h6' align='center'>Loading Search Data</Typography>
                                        <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }} />
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </TabPanel>

                    <TabPanel value={value} index={2} data-title="Notes">
                        <Comments
                            eDate={props.eDate}
                            sDate={props.sDate}
                            themeColor={props.themeColor}
                            admintoken={admintoken}
                            view={admintoken >= 6 ? "5af0a295-8b57-4ccb-8cc8-d54e5bb278e6" : "a7edd5c0-7a77-49ed-86ad-6fe3066a0f59"}
                            type={'google_' + props.which.toLowerCase()} />
                    </TabPanel>
                </div>
            ) : (
                <div style={{ paddingTop: theme.spacing(8) }}>
                    <Typography color="secondary" variant='h6' align='center'>Loading {props.title} Data</Typography>
                    <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }} />
                </div>
            )}
        </div>
    );
};

export default Google;
