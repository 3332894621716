import React, { useState, useEffect } from 'react';
import { style } from './index.scss';
import appVars from '../../../appVars.json';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';



const PlatformSearch = (props) => {
    const [ecoms, setEcoms] = useState([
        { siteUrl: "Search" }
    ]);



    useEffect(() => {
        fetchData();
    }, []);


    async function fetchData() {
        const donationSites = await fetch(appVars.lightwerks + "/sites/donation/");
        donationSites
            .json()
            .then((sites) => {
                setEcoms(sites);
                //console.log(sites);
                //  setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });

    }


    return (
        <Box className="hidden-xs-down" >
            <Autocomplete
                id="platformSearch"
                groupBy={(option) => option.type}
           /*     renderOption={(option) => (
                    <React.Fragment>
                        <span><img alt='' onError={(e) => {
                            this.src = 'https://place-hold.it/20x20/cfcfcf/?text=Placeholder';
                        }} style={{ maxWidth: 20 }} src={appVars.lightwerks + "/imageFiles/" + option.pkRecID + ".png"} /> </span>
                        {option.site}
                    </React.Fragment>
                )}*/
                options={ecoms.sort((a, b) => -b.type.localeCompare(a.type))}
                getOptionLabel={(option) => option.site}
                onChange={(e, ne) => {
                    window.location = '/control/sites/ecommerce/' + ne.pkRecID;
                }}

                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} InputLabelProps={{style:{
                    color:'#000'
                }}} label={props.title} size="small" fullWidth={true} variant="outlined" />}
                {...props}
           />
        </Box>
    );
};

PlatformSearch.propTypes = {

};

export default PlatformSearch;
