import React, { useState, useEffect } from 'react';
import appVars from '../../../appVars.json';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, LinearProgress, Box, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core';
import { useTheme } from '@material-ui/core';

const ClientSearch = (props) => {
    const [domains, setDomains] = useState();
    const theme = useTheme();
    //

    async function fetchData() {
        const res = await fetch(appVars.api_url + "/customers");
        res
            .json()
            .then((res) => {
                setDomains(res);
                //console.log(res);
                //  setLoading(false);
            })
            .catch((err) => console.log(err.msg));

    }


    useEffect(() => {
        fetchData();
    }, []);





    return (
        <Box>
            <ThemeProvider theme={theme}>
                {(domains ?
                    <Autocomplete
                        id="combo-box-demo"
                        groupBy={(option) => option.clientname}
                        options={domains.sort((a, b) => -b.clientname.localeCompare(a.clientname))}
                        getOptionLabel={(option) => option.name}  // Simple string for display in the input field
                        style={{ minWidth: 550 }}
                        onChange={(event, newValue) => props.updateClient(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{ style: { color: '#000' } }}
                                label="Domain Search...."
                                size="small"
                                fullWidth
                                variant="outlined"
                            />
                        )}
                        renderOption={(option) => (
                            <Box display="flex" alignItems="center">
                                <ListItemIcon>
                                    <Avatar
                                        variant="circular"
                                        alt={option.name}
                                        src={`${appVars.api_url}/imageFiles/${option.id}.png`}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={option.name} />
                            </Box>
                        )}
                        {...props}
                    />
                    : <LinearProgress />)}
            </ThemeProvider>
        </Box>

    );
};

ClientSearch.propTypes = {

};

export default ClientSearch;
