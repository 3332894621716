import { Card, CardContent, CardHeader, Grid, Typography, LinearProgress, Tabs, Tab, Box, CircularProgress } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import ChartCard from '../../../chart-card';
import { NumericFormat } from 'react-number-format';
import TableCard from '../../../table-card';
import { lightenHexColor, getCookie, darkenHexColor } from '../../../../dwi-functions';
import moment from 'moment';
import appVars from '../../../../appVars.json';
import PieChartCard from '../../../pie-chart';
import _ from 'lodash';
import Comments from '../../../../system/control/comments';
import StackedBarChartAgeComponent from '../../../StackedBarChartAgeComponent';
import { FaRegImages } from "react-icons/fa";
import { MdOutlineShortText, MdOutlineTextSnippet, MdOutlineCallToAction } from "react-icons/md";

const fontSize = 'initial';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const fetchImageUrl = async (imageName) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ "image": imageName }),
        redirect: "follow"
    };
    try {
        const response = await fetch(`${appVars.api_url}/meta/creatives/image`, requestOptions);
        const data = await response.json();
        return data.IMAGE_ASSET_URL;
    } catch (error) {
        console.error(error);
        return 'https://api.digitalwerks.com:7325/imageFiles/no-image-icon.png'; // Default image on error
    }
};

const Meta = (props) => {
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(true);
    const [creativeData, setCreativeData] = useState();
    const [agePie, setAgePie] = useState();
    const [ageData, setAgeData] = useState();
    const [genderData, setGenderData] = useState();
    const [data, setMetaData] = useState();
    const [campaignData, setCampaignData] = useState();
    const [devicesData, setDevicesData] = useState();
    const [adsetData, setAdsetData] = useState();
    const [admintoken, setAdmin] = useState(props.admintoken);
    const [imageUrls, setImageUrls] = useState({});

    //console.log(props);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: props.themeColor.primary, // Default primary color
            },
            secondary: {
                main: props.themeColor.secondary, // Default primary color
            },
        },

    });

    const classes = makeStyles((theme) => ({
        selected: {
            // Define your active tab color here
            background: props.themeColor.secondary,
            color: '#000',
            fontSize: '101%',
        },
    }));


    async function fetchReportData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "aid": props.advertiser_id,
            "sdate": props.sDate,
            "edate": props.eDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res4 = await fetch(appVars.api_url + "/meta/data", requestOptions)
        res4
            .json()
            .then((res4) => {
                setMetaData(res4);
            })
            .catch((err) => {
                console.log(err);
            });

        const res5 = await fetch(appVars.api_url + "/meta/devices", requestOptions)
        res5
            .json()
            .then((res) => {
                setDevicesData(res);
                //   console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });

        const campTable = await fetch(appVars.api_url + "/meta/campaigns", requestOptions);
        //    console.log(props);
        campTable
            .json()
            .then((campaigns) => {
                setCampaignData(campaigns);

            })
            .catch((err) => {
                console.log(err);
            });


        const adsets = await fetch(appVars.api_url + "/meta/adsets", requestOptions);
        //    console.log(props);
        adsets
            .json()
            .then((adsets) => {
                setAdsetData(adsets);

            })
            .catch((err) => {
                console.log(err);
            });




        const creativeTable = await fetch(appVars.api_url + "/meta/creatives", requestOptions);
        //    console.log(props);
        creativeTable
            .json()
            .then((creatives) => {
                setCreativeData(creatives);

                creatives[1][0].forEach(async (item) => {
                    const imageUrl = await fetchImageUrl(item.NAME);
                    setImageUrls(prevUrls => ({ ...prevUrls, [item.NAME]: imageUrl }));
                });
                setLoading(false);


            })
            .catch((err) => {
                console.log(err);
            });

        const ageChart = await fetch(appVars.api_url + "/meta/data/age", requestOptions);
        //    console.log(props);
        ageChart
            .json()
            .then((age) => {

                const transformedData = _(age)
                    .groupBy('AGE')
                    .map((value, key) => ({
                        ageRange: key,
                        female: _.sumBy(value, (o) => (o.GENDER === 'female' ? parseInt(o.CLICKS, 10) : 0)),
                        male: _.sumBy(value, (o) => (o.GENDER === 'male' ? parseInt(o.CLICKS, 10) : 0)),
                        unknown: _.sumBy(value, (o) => (o.GENDER === 'unknown' ? parseInt(o.CLICKS, 10) : 0)),
                    }))
                    .value();


                var sd = _(age) // Filter out items where CONVERSIONS is 0
                    .groupBy('AGE')
                    .map((groupedData, key) => ({ name: key, count: _.sumBy(groupedData, 'CLICKS') }))
                    .value();


                var rd = _(age) // Filter out items where CONVERSIONS is 0
                    .groupBy('GENDER')
                    .map((groupedData, key) => ({ name: key, count: _.sumBy(groupedData, 'CLICKS') }))
                    .value();
                console.log(rd);
                setGenderData(rd);
                setAgeData(transformedData);
                setAgePie(sd);

                // setAgeData(_.map(age, ({ AGE, CLICKS }) => ({ name: AGE, count: CLICKS })));

            })
            .catch((err) => {
                console.log(err);
            });


    }

    const ShowImage = ({ thename, imageUrl }) => {
        return (
            <img
                style={{ textAlign: 'left', maxWidth: 100, fontSize: 'initial', padding: 0, margin: 0 }}
                alt={thename}
                src={imageUrl}
                onError={(event) => {
                    event.target.src = 'https://api.digitalwerks.com:7325/imageFiles/no-image-icon.png';
                }}
            />
        );
    };

    const ShowDetails = ({ field, form, ...props }) => {



        return (
            <TableCard
                data={props.data}
                style={{ marginLeft: theme.spacing(8) }}
                client={props.client}
                color={props.themeColor}
                options={{
                    rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                    cellStyle: {
                        border: '1px solid ' + lightenHexColor(props.color.primary, 0), textAlign: 'center',
                        padding: theme.spacing(1)
                    },
                    headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.color.secondary, 0), color: props.color.primary, fontSize: fontSize, border: '1px solid ' + props.color.primary, paddingTop: 5, paddingBottom: 5 },
                    grouping: false,
                    searchFieldVariant: "outlined",
                    exportButton: false,
                    exportAllData: false,
                    search: false,
                    paging: (creativeData.length > 10 ? true : false),
                    exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                    pageSizeOptions: [10, 25, 50, 100],
                    showTitle: false,
                    toolbar: false,
                    sort: true
                }}
                {...props}

            />

        );
    }








    useEffect(() => {
        // if(!data){
        fetchReportData();
        //console.log(data);
        //  }
    }, [props]);





    return (
        <div >



            {(data ? (
                <div>
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="standard" scrollButtons="auto" style={{ marginBottom: theme.spacing(2) }}>
                        <Tab label="Campaign" classes={{ selected: classes.selected }} {...a11yProps(0)} />
                        <Tab label="Audiences" classes={{ selected: classes.selected }} {...a11yProps(1)} />
                        <Tab label="Creatives" classes={{ selected: classes.selected }} style={{display: ( admintoken.token>=10 ? 'initial' :'none')}} {...a11yProps(2)} />
                        <Tab label="Demographics" classes={{ selected: classes.selected }} {...a11yProps(3)} />
                        <Tab label="Device Info" classes={{ selected: classes.selected }} {...a11yProps(4)} />
                        <Tab label="Notes" classes={{ selected: classes.selected }} {...a11yProps(5)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <div>
                            <Grid container spacing={2} >
                                <Grid style={{ display: 'none' }} item sm={6} xs={12}>
                                    <Card >
                                        <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor.primary, 0) }} title="Platform Expense"></CardHeader>
                                        <CardContent>
                                            <Typography align='center' variant="h4"><NumericFormat decimalScale={2} value={data.cost.count} thousandSeparator="," displayType="text" prefix="$" /></Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid style={{ display: 'none' }} item sm={6} xs={12}>
                                    <Card style={{ marginTop: theme.spacing(0) }}>
                                        <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor.primary, 0) }} title="Platform ROI"></CardHeader>
                                        <CardContent >
                                            <Typography align='center' variant="h4"><NumericFormat decimalScale={2} value={((data.revenue.count / data.cost.count) * 100)} displayType="text" suffix="%" /></Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Impressions"
                                        data={data.impressions}
                                        client={props.client}
                                        color={props.themeColor}
                                    />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Clicks"
                                        data={data.clicks}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="CTR"
                                        data={data.ctr}
                                        client={props.client}
                                        color={props.themeColor}
                                    />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="CPM"
                                        data={data.cpm}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                            </Grid>

                            <Grid container spacing={2} alignContent="space-between">

                                <Grid item xl={3} sm={3} xs={12} >
                                    <ChartCard
                                        title="Conversions"
                                        data={data.conversions}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Revenue"
                                        data={data.revenue}
                                        client={props.client}
                                        color={props.themeColor}
                                    />


                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Conversion Rate"
                                        data={data.convrate}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Avg Gift"
                                        data={data.avgGift}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                            </Grid>




                            <Grid container spacing={2} alignContent="space-between">

                                <Grid item sm={12} xs={12} >
                                    {(campaignData ?
                                        <TableCard
                                            options={{
                                                rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                cellStyle: {
                                                    border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                    padding: theme.spacing(1)
                                                },
                                                headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 20), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                paging: (campaignData.length > 10 ? true : false),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true,

                                            }}
                                            title=""
                                            data={campaignData}
                                            count={(campaignData.length > 10 ? 10 : campaignData.length)}
                                            client={props.client}
                                            color={props.themeColor}
                                            columns={[
                                                { title: "Campaign Name", field: "CAMPAIGN", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 150, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CAMPAIGN}</Typography> },
                                                { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0)
                                                    }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1), border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0)

                                                    }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1), border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0)

                                                    },
                                                    title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1), border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0)
                                                    }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                },
                                                { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                { title: "Avg. Gift", field: "avgGift", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CONVERSIONS === 0 ? 0 : (rowdata.REVENUE / rowdata.CONVERSIONS)).toFixed(2)} displayType="text" prefix="$" /> },
                                                { title: "CPM", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.IMPRESSIONS === 0 ? 0 : (rowdata.COST / rowdata.IMPRESSIONS) * 1000).toFixed(2)} displayType="text" prefix="$" /> },
                                                { title: "Cost", field: "COST", type: 'currency', hidden: (admintoken >= 8 ? false : true) },

                                            ]}





                                        />
                                        : <div ><Typography style={{ color: props.themeColor.primary }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                                </Grid>
                            </Grid>



                        </div>

                    </TabPanel>


                    <TabPanel value={value} index={1}>
                        <Grid container spacing={2} alignContent="space-between">
                            <Grid item sm={12} xs={12} >
                                {(creativeData ?
                                    <TableCard
                                        title=""
                                        data={creativeData[0]}
                                        count={(creativeData[0].length > 10 ? 10 : creativeData[0].length)}
                                        client={props.client}
                                        color={props.themeColor}
                                        options={{
                                            actionsCellStyle: { minWidth: 400 },
                                            rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                            cellStyle: {
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                padding: theme.spacing(1)
                                            },
                                            headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 20), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                            grouping: false,
                                            searchFieldVariant: "outlined",
                                            exportButton: true,
                                            exportAllData: true,
                                            search: true,
                                            paging: (creativeData.length > 10 ? true : false),
                                            exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                            pageSizeOptions: [10, 25, 50, 100],
                                            showTitle: false,
                                            toolbar: true,
                                            sort: true
                                        }}
                                        columns={[
                                            { title: "Audience", field: "CREATIVE", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 300, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CREATIVE}</Typography> },
                                            { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                    textAlign: 'center',
                                                    padding: theme.spacing(1)

                                                }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                            },
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                    textAlign: 'center',
                                                    padding: theme.spacing(1)

                                                }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                            },
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                    textAlign: "center",
                                                    padding: theme.spacing(1)

                                                },
                                                title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                            },
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                    textAlign: "center",
                                                    padding: theme.spacing(1)
                                                }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                            },
                                            { title: "Revenue", field: "REVENUE", type: 'currency' },
                                            { title: "Avg. Gift", field: "avgGift", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CONVERSIONS === 0 ? 0 : (rowdata.REVENUE / rowdata.CONVERSIONS)).toFixed(2)} displayType="text" prefix="$" /> },
                                            { title: "CPM", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.IMPRESSIONS === 0 ? 0 : (rowdata.COST / rowdata.IMPRESSIONS) * 1000).toFixed(2)} displayType="text" prefix="$" /> },
                                            { title: "Cost", field: "COST", type: 'currency', hidden: (admintoken >= 8 ? false : true) },

                                        ]}
                                        detailPanel={[
                                            {
                                                tooltip: 'Show Images',
                                                icon: () => <FaRegImages color={props.themeColor.secondary} size={20} />,
                                                openIcon: () => <FaRegImages color={props.themeColor.primary} size={25} />,
                                                render: rowData => {
                                                    return (
                                                        <div>
                                                            {(creativeData ?
                                                                <TableCard
                                                                    title="Images"
                                                                    data={query =>
                                                                        new Promise(async (resolve, reject) => {


                                                                            var myHeaders = new Headers();
                                                                            myHeaders.append("Content-Type", "application/json");

                                                                            var raw = JSON.stringify({
                                                                                "agroup": rowData.ADGROUP,
                                                                                "sdate": props.sDate,
                                                                                "edate": props.eDate
                                                                            });

                                                                            var requestOptions = {
                                                                                method: 'POST',
                                                                                headers: myHeaders,
                                                                                body: raw,
                                                                                redirect: 'follow'
                                                                            };

                                                                            const res = await fetch(appVars.api_url + "/meta/creatives/adgroup", requestOptions);
                                                                            //    console.log(props);
                                                                            res
                                                                                .json()
                                                                                .then((creatives) => {
                                                                                    console.log(creatives[0]);
                                                                                    resolve({
                                                                                        data: creatives[0], // your data array
                                                                                        page: 0, // current page number
                                                                                        totalCount: creatives[0].length// total row number
                                                                                    });



                                                                                    creatives[0].forEach(async (item) => {
                                                                                        const imageUrl = await fetchImageUrl(item.NAME);
                                                                                        setImageUrls(prevUrls => ({ ...prevUrls, [item.NAME]: imageUrl }));
                                                                                    });

                                                                                    setLoading(false);


                                                                                })
                                                                                .catch((err) => {
                                                                                    console.log(err);
                                                                                });






                                                                            // prepare your data and then call resolve like this:

                                                                        })
                                                                    }
                                                                    client={props.client}
                                                                    color={props.themeColor}
                                                                    options={{
                                                                        rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                                        cellStyle: {
                                                                            border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                                            padding: theme.spacing(1)
                                                                        },
                                                                        headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.secondary, 20), color: "#000", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                                        grouping: false,
                                                                        searchFieldVariant: "outlined",
                                                                        exportButton: true,
                                                                        exportAllData: true,
                                                                        search: false,
                                                                        paging: (creativeData.length > 10 ? true : false),
                                                                        exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                                        pageSizeOptions: [10, 25, 50, 100],
                                                                        showTitle: false,
                                                                        toolbar: false,
                                                                        sort: true
                                                                    }}
                                                                    columns={[
                                                                        {
                                                                            title: "", field: "NAME", render: rowdata => <div>
                                                                                {!loading ? (
                                                                                    <ShowImage thename={rowdata.NAME} imageUrl={imageUrls[rowdata.NAME]} />
                                                                                ) : (
                                                                                    <CircularProgress></CircularProgress> // Or a loading spinner
                                                                                )}
                                                                            </div>
                                                                        },
                                                                        { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(1)

                                                                            }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                                        },
                                                                        /*     {
                                                                                 cellStyle: {
                                                                                     backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                                                     textAlign: 'center',
                                                                                     padding: theme.spacing(1)
                             
                                                                                 }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                                             },
                                                                             */
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 60),
                                                                                textAlign: "center",
                                                                                padding: theme.spacing(1)

                                                                            },
                                                                            title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                                        },
                                                                        /*  {
                                                                              cellStyle: {
                                                                                  backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                                                  textAlign: "center",
                                                                                  padding: theme.spacing(1)
                                                                              }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                                          },
                                                                          */
                                                                        { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                                        /* { title: "Avg. Gift", field: "avgGift", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CONVERSIONS === 0 ? 0 : (rowdata.REVENUE / rowdata.CONVERSIONS)).toFixed(2)} displayType="text" prefix="$" /> },
                                                                         { title: "CPM", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.IMPRESSIONS === 0 ? 0 : (rowdata.COST / rowdata.IMPRESSIONS) * 1000).toFixed(2)} displayType="text" prefix="$" /> }
                                                                     */
                                                                    ]}
                                                                />

                                                                : <div ><Typography variant='h6' align='center'>Loading Image Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}

                                                        </div>
                                                    )
                                                },
                                            },
                                            {

                                                tooltip: 'Show Headlines',
                                                icon: () => <MdOutlineShortText color={props.themeColor.secondary} size={20} />,
                                                openIcon: () => <MdOutlineShortText color={props.themeColor.primary} size={25} />,
                                                render: rowData => {
                                                    return (
                                                        <div>
                                                            {(creativeData ?
                                                                <div>
                                                                    <TableCard
                                                                        title="Headlines"
                                                                        data={query =>
                                                                            new Promise(async (resolve, reject) => {
    
    
                                                                                var myHeaders = new Headers();
                                                                                myHeaders.append("Content-Type", "application/json");
    
                                                                                var raw = JSON.stringify({
                                                                                    "agroup": rowData.ADGROUP,
                                                                                    "sdate": props.sDate,
                                                                                    "edate": props.eDate
                                                                                });
    
                                                                                var requestOptions = {
                                                                                    method: 'POST',
                                                                                    headers: myHeaders,
                                                                                    body: raw,
                                                                                    redirect: 'follow'
                                                                                };
    
                                                                                const res = await fetch(appVars.api_url + "/meta/creatives/adgroup", requestOptions);
                                                                                //    console.log(props);
                                                                                res
                                                                                    .json()
                                                                                    .then((creatives) => {
                                                                                        console.log(creatives[1]);
                                                                                        resolve({
                                                                                            data: creatives[1], // your data array
                                                                                            page: 0, // current page number
                                                                                            totalCount: creatives[1].length// total row number
                                                                                        });
    
    
    
                                                                                        creatives[0].forEach(async (item) => {
                                                                                            const imageUrl = await fetchImageUrl(item.NAME);
                                                                                            setImageUrls(prevUrls => ({ ...prevUrls, [item.NAME]: imageUrl }));
                                                                                        });
    
                                                                                        setLoading(false);
    
    
                                                                                    })
                                                                                    .catch((err) => {
                                                                                        console.log(err);
                                                                                    });
    
    
    
    
    
    
                                                                                // prepare your data and then call resolve like this:
    
                                                                            })
                                                                        }
                                                                        client={props.client}
                                                                        color={props.themeColor}
                                                                        options={{
                                                                            rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                                            cellStyle: {
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                                                padding: theme.spacing(1)
                                                                            },
                                                                            headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 20), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                                            grouping: false,
                                                                            searchFieldVariant: "outlined",
                                                                            exportButton: true,
                                                                            exportAllData: true,
                                                                            search: false,
                                                                            paging: (creativeData.length > 10 ? true : false),
                                                                            exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                                            pageSizeOptions: [10, 25, 50, 100],
                                                                            showTitle: false,
                                                                            toolbar: true,
                                                                            sort: true
                                                                        }}
                                                                        columns={[
                                                                            {
                                                                                title: "Title", field: "TITLE", render: rowdata => <div>
                                                                                    <Typography style={{ textAlign: 'left', minWidth: 300, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.TITLE}</Typography>
                                                                                </div>
                                                                            },
                                                                            { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                                            {
                                                                                cellStyle: {
                                                                                    backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                                                    textAlign: 'center',
                                                                                    padding: theme.spacing(1)

                                                                                }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                                            },
                                                                            /*     {
                                                                                       cellStyle: {
                                                                                           backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                                                           textAlign: 'center',
                                                                                           padding: theme.spacing(1)
                                   
                                                                                       }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                                                   },
                                                                                   */
                                                                            {
                                                                                cellStyle: {
                                                                                    backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                                                    textAlign: "center",
                                                                                    padding: theme.spacing(1)

                                                                                },
                                                                                title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                                            },
                                                                            /*  {
                                                                                  cellStyle: {
                                                                                      backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                                                      textAlign: "center",
                                                                                      padding: theme.spacing(1)
                                                                                  }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                                              },
                                                                              */
                                                                            { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                                            /* { title: "Avg. Gift", field: "avgGift", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CONVERSIONS === 0 ? 0 : (rowdata.REVENUE / rowdata.CONVERSIONS)).toFixed(2)} displayType="text" prefix="$" /> },
                                                                             { title: "CPM", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.IMPRESSIONS === 0 ? 0 : (rowdata.COST / rowdata.IMPRESSIONS) * 1000).toFixed(2)} displayType="text" prefix="$" /> }
                                                                         */
                                                                        ]}
                                                                    />

                                                                </div>
                                                                : <div ><Typography variant='h6' align='center'>Loading Title / Headline Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}

                                                        </div>
                                                    )
                                                },
                                            },
                                            {
                                                icon: () => <MdOutlineTextSnippet color={props.themeColor.secondary} size={20} />,
                                                openIcon: () => <MdOutlineTextSnippet color={props.themeColor.primary} size={25} />,
                                                tooltip: 'Show Copy',
                                                render: rowData => {
                                                    return (
                                                        <div>
                                                            {(creativeData ?
                                                                <TableCard
                                                                    title="AD Copy"
                                                                    data={query =>
                                                                        new Promise(async (resolve, reject) => {


                                                                            var myHeaders = new Headers();
                                                                            myHeaders.append("Content-Type", "application/json");

                                                                            var raw = JSON.stringify({
                                                                                "agroup": rowData.ADGROUP,
                                                                                "sdate": props.sDate,
                                                                                "edate": props.eDate
                                                                            });

                                                                            var requestOptions = {
                                                                                method: 'POST',
                                                                                headers: myHeaders,
                                                                                body: raw,
                                                                                redirect: 'follow'
                                                                            };

                                                                            const res = await fetch(appVars.api_url + "/meta/creatives/adgroup", requestOptions);
                                                                            //    console.log(props);
                                                                            res
                                                                                .json()
                                                                                .then((creatives) => {
                                                                                    console.log(creatives[2]);
                                                                                    resolve({
                                                                                        data: creatives[2], // your data array
                                                                                        page: 0, // current page number
                                                                                        totalCount: creatives[2].length// total row number
                                                                                    });



                                                                                    creatives[0].forEach(async (item) => {
                                                                                        const imageUrl = await fetchImageUrl(item.NAME);
                                                                                        setImageUrls(prevUrls => ({ ...prevUrls, [item.NAME]: imageUrl }));
                                                                                    });

                                                                                    setLoading(false);


                                                                                })
                                                                                .catch((err) => {
                                                                                    console.log(err);
                                                                                });






                                                                            // prepare your data and then call resolve like this:

                                                                        })
                                                                    }
                                                                    client={props.client}
                                                                    color={props.themeColor}
                                                                    options={{
                                                                        rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                                        cellStyle: {
                                                                            border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                                            padding: theme.spacing(1)
                                                                        },
                                                                        headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 20), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                                        grouping: false,
                                                                        searchFieldVariant: "outlined",
                                                                        exportButton: true,
                                                                        exportAllData: true,
                                                                        search: false,
                                                                        paging: (creativeData.length > 10 ? true : false),
                                                                        exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                                        pageSizeOptions: [10, 25, 50, 100],
                                                                        showTitle: false,
                                                                        toolbar: true,
                                                                        sort: true
                                                                    }}
                                                                    columns={[
                                                                        {
                                                                            title: "Copy", field: "BODY_COPY", render: rowdata => <div>
                                                                                <Typography style={{ textAlign: 'left', minWidth: 300, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.BODY_COPY}</Typography>
                                                                            </div>
                                                                        },
                                                                        { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(1)

                                                                            }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                                        },
                                                                        /*     {
                                                                              cellStyle: {
                                                                                  backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                                                  textAlign: 'center',
                                                                                  padding: theme.spacing(1)
                             
                                                                              }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                                          },
                                                                          */
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                                                textAlign: "center",
                                                                                padding: theme.spacing(1)

                                                                            },
                                                                            title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                                        },
                                                                        /*  {
                                                                              cellStyle: {
                                                                                  backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                                                  textAlign: "center",
                                                                                  padding: theme.spacing(1)
                                                                              }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                                          },
                                                                          */
                                                                        { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                                        /* { title: "Avg. Gift", field: "avgGift", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CONVERSIONS === 0 ? 0 : (rowdata.REVENUE / rowdata.CONVERSIONS)).toFixed(2)} displayType="text" prefix="$" /> },
                                                                         { title: "CPM", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.IMPRESSIONS === 0 ? 0 : (rowdata.COST / rowdata.IMPRESSIONS) * 1000).toFixed(2)} displayType="text" prefix="$" /> }
                                                                     */
                                                                    ]}
                                                                />

                                                                : <div ><Typography variant='h6' align='center'>Loading Copy Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}
                                                        </div>
                                                    )
                                                },
                                            },
                                            {
                                                icon: () => <MdOutlineCallToAction color={props.themeColor.secondary} size={20} />,
                                                openIcon: () => <MdOutlineCallToAction color={props.themeColor.primary} size={25} />,
                                                tooltip: 'Show CTA',
                                                render: rowData => {
                                                    return (
                                                        <div>
                                                            {(creativeData ?
                                                                  <TableCard
                                                                  title="Call to action"
                                                                  data={query =>
                                                                    new Promise(async (resolve, reject) => {


                                                                        var myHeaders = new Headers();
                                                                        myHeaders.append("Content-Type", "application/json");

                                                                        var raw = JSON.stringify({
                                                                            "agroup": rowData.ADGROUP,
                                                                            "sdate": props.sDate,
                                                                            "edate": props.eDate
                                                                        });

                                                                        var requestOptions = {
                                                                            method: 'POST',
                                                                            headers: myHeaders,
                                                                            body: raw,
                                                                            redirect: 'follow'
                                                                        };

                                                                        const res = await fetch(appVars.api_url + "/meta/creatives/adgroup", requestOptions);
                                                                        //    console.log(props);
                                                                        res
                                                                            .json()
                                                                            .then((creatives) => {
                                                                                console.log(creatives[3]);
                                                                                resolve({
                                                                                    data: creatives[3], // your data array
                                                                                    page: 0, // current page number
                                                                                    totalCount: creatives[3].length// total row number
                                                                                });



                                                                                creatives[0].forEach(async (item) => {
                                                                                    const imageUrl = await fetchImageUrl(item.NAME);
                                                                                    setImageUrls(prevUrls => ({ ...prevUrls, [item.NAME]: imageUrl }));
                                                                                });

                                                                                setLoading(false);


                                                                            })
                                                                            .catch((err) => {
                                                                                console.log(err);
                                                                            });






                                                                        // prepare your data and then call resolve like this:

                                                                    })
                                                                }
                                                                  client={props.client}
                                                                  color={props.themeColor}
                                                                  options={{
                                                                      rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                                      cellStyle: {
                                                                          border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                                          padding: theme.spacing(1)
                                                                      },
                                                                      headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 20), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                                      grouping: false,
                                                                      searchFieldVariant: "outlined",
                                                                      exportButton: true,
                                                                      exportAllData: true,
                                                                      search: false,
                                                                      paging: (creativeData.length > 10 ? true : false),
                                                                      exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                                      pageSizeOptions: [10, 25, 50, 100],
                                                                      showTitle: false,
                                                                      toolbar: true,
                                                                      sort: true
                                                                  }}
                                                                  columns={[
                                                                      {
                                                                          title: "CTA", field: "CTA", render: rowdata => <div>
                                                                              <Typography style={{ textAlign: 'left', minWidth: 300, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CTA}</Typography>
                                                                          </div>
                                                                      },
                                                                      { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                                      {
                                                                          cellStyle: {
                                                                              backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                                              textAlign: 'center',
                                                                              padding: theme.spacing(1)
                      
                                                                          }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                                      },
                                                                      /*     {
                                                                           cellStyle: {
                                                                               backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                                               textAlign: 'center',
                                                                               padding: theme.spacing(1)
                          
                                                                           }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                                       },
                                                                       */
                                                                      {
                                                                          cellStyle: {
                                                                              backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                                              textAlign: "center",
                                                                              padding: theme.spacing(1)
                      
                                                                          },
                                                                          title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                                      },
                                                                      /*  {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                                                textAlign: "center",
                                                                                padding: theme.spacing(1)
                                                                            }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                                        },
                                                                        */
                                                                      { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                                 /* { title: "Avg. Gift", field: "avgGift", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CONVERSIONS === 0 ? 0 : (rowdata.REVENUE / rowdata.CONVERSIONS)).toFixed(2)} displayType="text" prefix="$" /> },
                                                                  { title: "CPM", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.IMPRESSIONS === 0 ? 0 : (rowdata.COST / rowdata.IMPRESSIONS) * 1000).toFixed(2)} displayType="text" prefix="$" /> }
                                                              */   ]}
                                                              />

                                                                : <div ><Typography variant='h6' align='center'>Loading Copy Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}
                                                        </div>
                                                    )
                                                },
                                            },
                                        ]}

                                    />

                                    : <div ><Typography variant='h6' align='center'>Loading Creative Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}

                            </Grid>




                        </Grid>
                    </TabPanel>





                    <TabPanel value={value} index={2}>
                        <Grid container spacing={2} alignContent="space-between">
                            <Grid item sm={6} xs={12} >
                                {(creativeData ?
                                    <TableCard
                                        title="Images"
                                        data={creativeData[1][0]}
                                        count={(creativeData[1][0].length > 10 ? 10 : creativeData[1][0].length)}
                                        client={props.client}
                                        color={props.themeColor}
                                        options={{
                                            rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                            cellStyle: {
                                                border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                padding: theme.spacing(1)
                                            },
                                            headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 20), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                            grouping: false,
                                            searchFieldVariant: "outlined",
                                            exportButton: true,
                                            exportAllData: true,
                                            search: false,
                                            paging: (creativeData.length > 10 ? true : false),
                                            exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                            pageSizeOptions: [10, 25, 50, 100],
                                            showTitle: false,
                                            toolbar: true,
                                            sort: true
                                        }}
                                        columns={[
                                            {
                                                title: "", field: "NAME", render: rowdata => <div>
                                                    {!loading ? (
                                                        <ShowImage thename={rowdata.NAME} imageUrl={imageUrls[rowdata.NAME]} />
                                                    ) : (
                                                        <CircularProgress></CircularProgress> // Or a loading spinner
                                                    )}
                                                </div>
                                            },
                                            { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                    textAlign: 'center',
                                                    padding: theme.spacing(1)

                                                }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                            },
                                            /*     {
                                                     cellStyle: {
                                                         backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                         textAlign: 'center',
                                                         padding: theme.spacing(1)
 
                                                     }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                 },
                                                 */
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                    textAlign: "center",
                                                    padding: theme.spacing(1)

                                                },
                                                title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                            },
                                            /*  {
                                                  cellStyle: {
                                                      backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                      textAlign: "center",
                                                      padding: theme.spacing(1)
                                                  }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                              },
                                              */
                                            { title: "Revenue", field: "REVENUE", type: 'currency' },
                                            /* { title: "Avg. Gift", field: "avgGift", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CONVERSIONS === 0 ? 0 : (rowdata.REVENUE / rowdata.CONVERSIONS)).toFixed(2)} displayType="text" prefix="$" /> },
                                             { title: "CPM", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.IMPRESSIONS === 0 ? 0 : (rowdata.COST / rowdata.IMPRESSIONS) * 1000).toFixed(2)} displayType="text" prefix="$" /> }
                                         */
                                        ]}
                                    />

                                    : <div ><Typography variant='h6' align='center'>Loading Image Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}

                            </Grid>
                            <Grid item sm={6} xs={12} >
                                {(creativeData ?
                                    <div>
                                        <TableCard
                                            title="Title"
                                            data={creativeData[1][1]}
                                            count={(creativeData[1][1].length > 10 ? 10 : creativeData[1][1].length)}
                                            client={props.client}
                                            color={props.themeColor}
                                            options={{
                                                rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                cellStyle: {
                                                    border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                    padding: theme.spacing(1)
                                                },
                                                headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 20), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                exportButton: true,
                                                exportAllData: true,
                                                search: false,
                                                paging: (creativeData.length > 10 ? true : false),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            columns={[
                                                {
                                                    title: "Title", field: "TITLE", render: rowdata => <div>
                                                        <Typography style={{ textAlign: 'left', minWidth: 300, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.TITLE}</Typography>
                                                    </div>
                                                },
                                                { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                },
                                                /*     {
                                                           cellStyle: {
                                                               backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                               textAlign: 'center',
                                                               padding: theme.spacing(1)
       
                                                           }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                       },
                                                       */
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)

                                                    },
                                                    title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                },
                                                /*  {
                                                      cellStyle: {
                                                          backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                          textAlign: "center",
                                                          padding: theme.spacing(1)
                                                      }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                  },
                                                  */
                                                { title: "Revenue", field: "REVENUE", type: 'currency' },
                                               /* { title: "Avg. Gift", field: "avgGift", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CONVERSIONS === 0 ? 0 : (rowdata.REVENUE / rowdata.CONVERSIONS)).toFixed(2)} displayType="text" prefix="$" /> },
                                                { title: "CPM", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.IMPRESSIONS === 0 ? 0 : (rowdata.COST / rowdata.IMPRESSIONS) * 1000).toFixed(2)} displayType="text" prefix="$" /> }
                                            */  ]}
                                        />
                                        <TableCard
                                            title="Call to action"
                                            data={creativeData[1][3]}
                                            count={(creativeData[1][3].length > 10 ? 10 : creativeData[1][3].length)}
                                            client={props.client}
                                            color={props.themeColor}
                                            options={{
                                                rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                cellStyle: {
                                                    border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                    padding: theme.spacing(1)
                                                },
                                                headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 20), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                exportButton: true,
                                                exportAllData: true,
                                                search: false,
                                                paging: (creativeData.length > 10 ? true : false),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            columns={[
                                                {
                                                    title: "CTA", field: "CTA", render: rowdata => <div>
                                                        <Typography style={{ textAlign: 'left', minWidth: 300, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CTA}</Typography>
                                                    </div>
                                                },
                                                { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                },
                                                /*     {
                                                     cellStyle: {
                                                         backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                                         textAlign: 'center',
                                                         padding: theme.spacing(1)
    
                                                     }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                 },
                                                 */
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)

                                                    },
                                                    title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                },
                                                /*  {
                                                      cellStyle: {
                                                          backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                          textAlign: "center",
                                                          padding: theme.spacing(1)
                                                      }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                  },
                                                  */
                                                { title: "Revenue", field: "REVENUE", type: 'currency' },
                                           /* { title: "Avg. Gift", field: "avgGift", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CONVERSIONS === 0 ? 0 : (rowdata.REVENUE / rowdata.CONVERSIONS)).toFixed(2)} displayType="text" prefix="$" /> },
                                            { title: "CPM", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.IMPRESSIONS === 0 ? 0 : (rowdata.COST / rowdata.IMPRESSIONS) * 1000).toFixed(2)} displayType="text" prefix="$" /> }
                                        */   ]}
                                        /></div>
                                    : <div ><Typography variant='h6' align='center'>Loading Title / Headline Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}

                            </Grid>


                            <Grid item sm={12} xs={12} >

                            </Grid>




                            <Grid item sm={6} xs={12} >
                            </Grid>







                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        {(agePie ?
                            <Grid container spacing={3}>
                                <Grid item sm={6}>

                                    <PieChartCard
                                        title="Clicks - By Age"
                                        data={agePie}
                                        client={props.client}
                                        themeColor={props.themeColor}
                                    />
                                </Grid>

                                <Grid item sm={6}>
                                    <PieChartCard
                                        title="Clicks - By Gender"
                                        data={genderData}
                                        client={props.client}
                                        themeColor={props.themeColor}
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <StackedBarChartAgeComponent
                                        themeColor={props.themeColor}
                                        data={ageData}
                                        layout="vertical"
                                    />
                                </Grid>
                            </Grid>
                            : '')}
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        {(devicesData ?
                            <TableCard
                                options={{
                                    rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                    cellStyle: {
                                        border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                        padding: theme.spacing(1)
                                    },
                                    headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 20), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                    grouping: false,
                                    searchFieldVariant: "outlined",
                                    exportButton: true,
                                    exportAllData: true,
                                    search: false,
                                    paging: (devicesData.length > 10 ? true : false),
                                    exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                    pageSizeOptions: [10, 25, 50, 100],
                                    showTitle: false,
                                    toolbar: true,
                                    sort: true
                                }}
                                title=""
                                data={devicesData}
                                count={(devicesData.length > 10 ? 10 : devicesData.length)}
                                client={props.client}
                                color={props.themeColor}
                                columns={[
                                    { title: "Device", field: "DEVICE", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 100, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.DEVICE}</Typography> },
                                    { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                    {
                                        cellStyle: {
                                            backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                            textAlign: 'center',
                                            padding: theme.spacing(1)

                                        }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                    },
                                    {
                                        cellStyle: {
                                            backgroundColor: lightenHexColor(props.themeColor.primary, 30),
                                            textAlign: 'center',
                                            padding: theme.spacing(1)

                                        }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                    },
                                    {
                                        cellStyle: {
                                            backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                            textAlign: "center",
                                            padding: theme.spacing(1)

                                        },
                                        title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                    },
                                    {
                                        cellStyle: {
                                            backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                            textAlign: "center",
                                            padding: theme.spacing(1)
                                        }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                    },
                                    { title: "Revenue", field: "REVENUE", type: 'currency' },

                                    { title: "Avg. Gift", field: "avgGift", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CONVERSIONS === 0 ? 0 : (rowdata.REVENUE / rowdata.CONVERSIONS)).toFixed(2)} displayType="text" prefix="$" /> },

                                    { title: "CPM", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.IMPRESSIONS === 0 ? 0 : (rowdata.COST / rowdata.IMPRESSIONS) * 1000).toFixed(2)} displayType="text" prefix="$" /> }
                                ]}
                            />
                            : <div ><Typography style={{ color: props.themeColor.primary }} variant='h6' align='center'>Loading Device Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                    </TabPanel>

                    <TabPanel value={value} index={5}>
                        <Comments eDate={props.eDate} sDate={props.sDate} color={props.themeColor.primary} admintoken={admintoken} view={(admintoken >= 6 ? "5af0a295-8b57-4ccb-8cc8-d54e5bb278e6" : "a7edd5c0-7a77-49ed-86ad-6fe3066a0f59")} type="meta"></Comments>
                    </TabPanel>
                </div>
            ) : (
                <div style={{ paddingTop: theme.spacing(5) }} ><Typography variant='h6' style={{ color: props.themeColor.primary }} align='center'>Loading {props.title} Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(8) }}></LinearProgress></div>
            ))}












        </div>
    );
};

Meta.propTypes = {

};

export default Meta;
