import React, { useState, useEffect } from 'react';
import { Grid, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import appVars from '../appVars.json';
import { makeStyles } from '@material-ui/core';
import 'date-fns';
import { useTheme } from '@material-ui/core/styles';
import themeFile from '../theme.json';
import PrimaryClient from './primary-client';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        margintop: theme.spacing(2)
    },
    h4: {
        marginBottom: theme.spacing(2)
    },
    paper: {
        padding: '1em',
        margin: '1em 0',
        background: "transparent",
        textAlign: "center",
        color: "white"
    },
    paperError: {
        background: themeFile.palette.feedback.error.background,
        textAlign: "center",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        paddingTop: ".5em"
    },
    paperSuccess: {
        background: themeFile.palette.feedback.success.background,
        textAlign: "center",
        marginBottom: "1em",
        padding: "0em"
    },
    revenue: {
        backgroundColor: themeFile.palette.secondary.main,
        padding: "1em",
        color: "white",
        borderRadius: "7px"
    },
    appBar: {
        background: themeFile.palette.primary.dark,
        color: '#fff',
        padding: '0!important'
    },
}));

const DashBoard = (props) => {

    //const classes = useStyles();
    const classes = useStyles();
    const [error, setErrors] = useState({ status: !1, msg: "No Error!", severity: "error" });
    const [users, setUsers] = useState([]);
    const [errorlog, setErrorLog] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorcount, setErrorCount] = useState(0);
    const [conversions, setConverions] = useState([]);
    const [last5, setLast5] = useState([]);
    const [refreshInterval, setRefreshInterval] = useState(10000); // Adjust the interval as needed (in milliseconds)
    const [selectedDateS, setSelectedDateS] = React.useState(new Date().toISOString().split('T')[0]);
    const [selectedDateE, setSelectedDateE] = React.useState(new Date().toISOString().split('T')[0]);
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };




    async function fetchData() {
        const res = await fetch(appVars.api_url + "/clients");
        res
            .json()
            .then((res) => {
                setUsers(res);
                // console.log(res);
            })
            //  .then(res => setLoading(false))
            .catch(err => setErrors({
                status: true,
                msg: err.message,
                severity: "info"
            }));
    }

    async function fetchStats() {

    }






    useEffect(() => {
        // fetchData();
        document.title = "Main Dashboard";
        const intervalId = setInterval(fetchStats, refreshInterval);
        return () => clearInterval(intervalId);
    }, [refreshInterval]);




    return (
        <div>
            <div className={classes.root}>

                <Grid container xs={12} spacing={0} direction="row" >
                    <Grid item xs={12} md={12} sm={10} >
                        <PrimaryClient></PrimaryClient>
                    </Grid>
                    <Grid item xs={12} sm={2} >

                    </Grid>
                </Grid>
                <Snackbar open={error.status} autoHideDuration={3500} >
                    <Alert severity={error.severity}>
                        {error.msg}
                    </Alert>
                </Snackbar>

            </div>
        </div>
    );
};

DashBoard.propTypes = {

};

export default DashBoard;
