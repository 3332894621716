import { Grid, Typography, Button, Snackbar, AppBar, Tab, Tabs, Box, Breadcrumbs, Link, Toolbar, Paper, LinearProgress } from '@material-ui/core';
import React, { useState, useEffect} from 'react';
import { style } from './index.scss';
import appVars from '../../appVars.json';
import Alert from "@material-ui/lab/Alert";
import ClipboardJS from "clipboard";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import themeFile from '../../theme.json';
import SubClientList from '../sub-client-list';
import { CancelOutlined, Save, Settings, SupervisedUserCircleSharp } from '@material-ui/icons';
import { BsPrinter } from 'react-icons/bs';
import { Formik, Form, Field } from 'formik';
import { TextField } from '@material-ui/core/';
import Users from '../../UserTable';
import { RiUser2Fill } from 'react-icons/ri';
import FileUploads from '../../settings/file-uploads';
import { useLocation } from 'react-router-dom';
import { useToken } from '../../Login/TokenProvider';
import ReportSettings from '../../settings/report-settings';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};





const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  table: {
    marginTop: "1em",
  },
  divider: {
    marginTop: "1em"
  },
  title: {
    borderBottom: "1px solid #000",
  },
  pos: {
    marginBottom: 12,
  },
  clientLogo: {
    maxWidth: "100%",
    marginTop: "1em",
    marginBottom: "1em"
  },
  address: {
    marginTop: "1em",
    marginBottom: "1em"
  },
  appBar: {
    background: themeFile.palette.primary.dark
  },
  paper: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  paperSide: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark
  },
  media: {
    height: "auto",
    maxWidth: "100px",
    margin: theme.spacing(.5),
    backgroundSize: "auto"
  },
  icon: {
    margin: '0px!important',
  },
  tab: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputs: {
    marginBottom: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    minWidth: 120,
  },
  toolbar: {
    margin: '0 0',
    background: theme.palette.text.hint,
    padding: '0 15px',
    color: theme.palette.primary.contrastText
  }

}));


//console.log(id);

const SingleClient = (props) => {

  const location = useLocation();
  var id = location.pathname.split("/")[2];
  // eslint-disable-next-line
  const searchParams = new URLSearchParams(location.search);
  const token = useToken();
  const admintoken = token.token;
  const classes = useStyles();
  const [hasError, setErrors] = useState(false);
    // eslint-disable-next-line
  const [copySuccessShow, setCopySuccessShow] = useState(false);
  const [client, setClient] = useState();
  const [loading, setLoading] = useState(true);
  const [clientData, setClientData] = useState([{
    "clientname": 'Default Name'
  }]);

  const [value, setValue] = React.useState(0);
  const theme = useTheme();
    // eslint-disable-next-line
    const [brandColor, setBrandColor] = useState({ "primary": "#fff", "secondary": "#5791a0" });
  const MyInput = ({ field, form, ...props }) => {
    return <TextField size='small' variant="outlined" shrink {...field} {...props} />;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrors(false);
    setCopySuccessShow(false);
  };



  const childToParent = (childdata) => {
    var f = appVars.api_url + '/imageFiles/' + id + '.png';
    const img = document.getElementById('avatar');
    img.src = f; console.log(f);
  };


  async function fetchData() {
    const res = await fetch(appVars.api_url + "/clients/" + id);
    res
      .json()
      .then(res => {

        //   console.log(res);
        setClient(res);
        setClientData(res[0]);
        setLoading(false);

      })
      .catch(err => setErrors(true));
  }


  useEffect(() => {
    fetchData();
  }, [props]);


  //console.log(admintoken)

  return (
    <div className={style}>




      {(!loading ?
        <div>

          <AppBar className={classes.appBar} position="static" fullWidth>

            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" orientation="horizontal" indicatorColor="secondary" >

              <Tab
                className={classes.tab}
                icon={<SupervisedUserCircleSharp className={classes.icon} ></SupervisedUserCircleSharp>}
                label="Active Client List" />
              <Tab
                className={classes.tab}
                icon={<SupervisedUserCircleSharp className={classes.icon} ></SupervisedUserCircleSharp>}
                label="in-active Client List" />
              {(admintoken.token >= 8 ?
                <Tab
                  className={classes.tab}
                  icon={<RiUser2Fill className={classes.icon}></RiUser2Fill>}
                  label="Account Team" />
                : '')}
              {(admintoken.token >= 8 ?
                <Tab
                  className={classes.tab}
                  icon={<Settings className={classes.icon} ></Settings>}
                  label="Settings" />
                : '')}



            </Tabs>

          </AppBar>

          <TabPanel value={value} index={0}>
            <Typography variant="body2">
              <Grid container spacing={0}>

                <Grid item xs={12}>
                  <Toolbar className={classes.toolbar} variant="regular" fullWidth>
                    <Breadcrumbs style={{ width: '100%', padding: '7px' }} aria-label="breadcrumb">
                      <Link color="textPrimary" href="/" >
                        Dashboard
                      </Link>
                      <Link color="textPrimary" href={"/clientview/" + client[0].clientId} >
                        {client[0].clientname}
                      </Link>
                      <Typography color="primary">
                        Active Clients
                      </Typography>
                    </Breadcrumbs>
                  </Toolbar>
                  <SubClientList themeColor={props.brandColor} admintoken={admintoken} client={id} logo={client[0].logo} status={true}></SubClientList>

                </Grid>

              </Grid>
            </Typography>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography variant="body2">
              <Grid container xl={12} md={12} spacing={0}>

                <Grid item xs={12}>
                  <Toolbar className={classes.toolbar} variant="regular" fullWidth>
                    <Breadcrumbs style={{ width: '100%', padding: '7px' }} aria-label="breadcrumb">
                      <Link color="textPrimary" href="/" >
                        Dashboard
                      </Link>
                      <Link color="textPrimary" href={"/clientview/" + client[0].clientId} >
                        {client[0].clientname}
                      </Link>
                      <Typography color="primary">
                        In-Active Clients
                      </Typography>
                    </Breadcrumbs>
                  </Toolbar>
                  <SubClientList admintoken={admintoken} client={id} logo={client[0].logo} status={false}></SubClientList>
                </Grid>

              </Grid>
            </Typography>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <Typography variant="body2">
              <Grid container spacing={0}>
                <Grid item xs={12}>

                  <Users themeColor={brandColor} admintoken={admintoken} type='support' clientid={client[0].id}></Users>
                </Grid>

              </Grid>
            </Typography>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Typography variant="body2">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Toolbar className={classes.toolbar} variant="regular" fullWidth>
                    <Breadcrumbs style={{ width: '100%', padding: '7px' }} aria-label="breadcrumb">
                      <Link color="textPrimary" href="/" >
                        Dashboard
                      </Link>
                      <Link color="textPrimary" href={"/clientview/" + client[0].clientId} >
                        {client[0].clientname}
                      </Link>
                      <Typography color="primary">
                        Settings
                      </Typography>
                    </Breadcrumbs>
                  </Toolbar>
                </Grid>
              </Grid>
            </Typography>
            <Grid container spacing={2}>
              <Grid item xl={10} md={10}>

                <Paper className={classes.paper}>

                  <Formik enableReinitialize
                    initialValues={clientData}
                    className={classes.root}
                    onSubmit={async (values) => {
                      //console.log(values);
                      // return false;
                      await new Promise((r) => setTimeout(r, 500));
                      var url = appVars.api_url + "/gtm/update/";
                      if (values.pkRecId === "0") {
                        url = appVars.api_url + "/gtm/add/";
                        //url = "http://localhost:7325/gtm/add/";
                      }   //var url = "http://localhost:7325/gtm/add/";


                      const response = fetch(url, {
                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                        body: JSON.stringify(values), // body data type must match "Content-Type" header,
                        headers: {
                          'Content-Type': 'application/json'
                        }
                      });
                      response
                        .then(users => {
                          // window.location.href='/clientview/'+clientData.client;

                          ////console.log(users.body);
                          fetchData();
                        }).err(err => {

                        })




                    }}>
                    <Form>
                      <div>
                        <Field component={MyInput} type="text" id="clientname" label="Display Name" fullWidth name="clientname" className={classes.inputs} helperText="Enter Display Name" />
                      </div>
                      <div>

                        <Field component={MyInput} type="text" id="address" label="Address" fullWidth name="address" className={classes.inputs} helperText="Enter street address" />
                      </div>
                      <div>

                        <Field component={MyInput} type="text" id="city" label="City" style={{ width: 'calc(45%)' }} name="city" className={classes.inputs} helperText="Enter City" />


                        <Field component={MyInput} type="text" id="state" label="State" style={{ width: 'calc(45%)' }} name="state" className={classes.inputs} helperText="Enter State" />


                        <Field component={MyInput} type="text" id="zip" label="Zip" style={{ width: 'calc(10%)' }} name="zip" className={classes.inputs} helperText="Enter Zip" />
                      </div>

                      <div>

                        <Field component={MyInput} type="text" id="email" label="Email" style={{ width: 'calc(100% / 2)' }} name="email" className={classes.inputs} helperText="Enter Email" />


                        <Field component={MyInput} type="text" id="phone" label="Phone" style={{ width: 'calc(100% / 2)' }} name="phone" className={classes.inputs} helperText="Enter Phone" />
                      </div>

                      <div>
                        <Button variant="contained" style={{ marginRight: '7px' }} size="small" color="secondary" href={"/client/new/?clientid=" + id} startIcon={<Save></Save>}>Save</Button>
                        <Button variant="contained" style={{ marginRight: '7px' }} size="small" color="default" startIcon={<BsPrinter ></BsPrinter>}>Print</Button>
                        <Button variant="contained" size="small" color="default" startIcon={<CancelOutlined></CancelOutlined>}>Cancel</Button>
                      </div>
                    </Form>
                  </Formik>

                </Paper>
              </Grid>

              <Grid item xl={2} md={2}>
                <Paper className={classes.paperSide} >
                  {(admintoken.token >= 8 ?
                    <div style={{ padding: theme.spacing(.5) }}>
                      <ReportSettings themeColor={brandColor} title="Settings" client_id={id} />
                    </div>
                    : null)}
                  <img id='avatar' src={client[0].logo} className={classes.media} alt={client[0].clientId} />

                  <FileUploads newid={id} childToParent={childToParent} ></FileUploads>

                </Paper>
              </Grid>

            </Grid>


          </TabPanel >




          <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              The data can not be pulled at this time.
              <Button color="primary" onClick={window.location.reload}>
                Refresh
              </Button>
            </Alert>
          </Snackbar>
        </div>
        : <LinearProgress></LinearProgress>)}

    </div >
  );
};

SingleClient.propTypes = {

};

export default SingleClient;
