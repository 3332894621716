import { Card, CardContent, CardHeader, Grid, Typography, LinearProgress, Tabs, Tab, Box } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import themeFile from '../../theme.json';
import ChartCard from '../../../chart-card';
import { NumericFormat } from 'react-number-format';
import TableCard from '../../../table-card';
import { lightenHexColor,getCookie,darkenHexColor } from '../../../../dwi-functions';
import _ from 'lodash';
import appVars from '../../../../appVars.json';
import moment from 'moment';
import Comments from '../../../../system/control/comments';

var CurrencyFormat = require('react-currency-format');




const fontSize = 'initial';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}






const Bing = (props) => {
    const [value, setValue] = useState(0);
    const [creativeData, setCreativeData] = useState();
    const [topConversionData, setTopConversionData] = useState();
    const [keywordData, setKeywordData] = useState();
    const [regionData, setRegionData] = useState();
    const [campaignData, setCampaignData] = useState();
    const [data, setData] = useState();
    const [admintoken, setAdmin] = useState(sessionStorage.getItem('_buta'));


    const theme = createTheme({
        palette: {
            primary: {
                main: props.themeColor.primary, // Default primary color
            },
            secondary: {
                main: props.themeColor.secondary, // Default primary color
            },
        },
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchReportData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "aid": props.advertiser_id,
            "sdate": props.sDate,
            "edate": props.eDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res4 = await fetch(appVars.api_url + "/bing/data", requestOptions);
        //    console.log(props);
        res4
            .json()
            .then((res4) => {
                setData(res4[0]);

            })
            .catch((err) => {
                console.log(err);
            });

        const campTable = await fetch(appVars.api_url + "/bing/campaigns", requestOptions);
        //    console.log(props);
        campTable
            .json()
            .then((campaigns) => {
                setCampaignData(campaigns);
              //  console.log(campaigns);
            })
            .catch((err) => {
                console.log(err);
            });
        /*
                const creativeTable = await fetch(appVars.api_url+"/bing/creatives", requestOptions);
                //    console.log(props);
                creativeTable
                    .json()
                    .then((creatives) => {
                        setCreativeData(creatives);
        
                    })
                    .catch((err) => {
                        console.log(err);
                    });
        */


        const keywordTable = await fetch(appVars.api_url + "/bing/keyword", requestOptions);
        keywordTable
            .json()
            .then((keyword) => {


                var sd = _(keyword) // Filter out items where CONVERSIONS is 0
                    .groupBy('SEARCH')
                    .map((groupedData, key) => ({ SEARCH: key, CONVERSIONS: _.sumBy(groupedData, 'CONVERSIONS'), IMPRESSIONS: _.sumBy(groupedData, 'IMPRESSIONS'), CLICKS: _.sumBy(groupedData, 'CLICKS'), REVENUE: _.sumBy(groupedData, 'REVENUE'), COST: _.sumBy(groupedData, 'COST') }))
                    .value();
                setKeywordData(sd);

                var cv = _(keyword).filter(item => item.CONVERSIONS !== 0) // Filter out items where CONVERSIONS is 0
                    .groupBy('SEARCH')
                    .map((groupedData, key) => ({ SEARCH: key, CONVERSIONS: _.sumBy(groupedData, 'CONVERSIONS'), CLICKS: _.sumBy(groupedData, 'CLICKS') }))
                    .value();
                setTopConversionData(cv);
            })
            .catch((err) => {
                console.log(err);
            });


        const regionTable = await fetch(appVars.api_url + "/bing/region", requestOptions);
        regionTable
            .json()
            .then((region) => {
                setRegionData(region);
            })
            .catch((err) => {
                console.log(err);
            });




    }

    useEffect(() => {
        // if(!data){
        fetchReportData();
        // console.log(data);
        //  }
    }, [props]);




    return (
        <div >
    


                {(data ?
                    <div>
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="standard" scrollButtons="auto" style={{ marginBottom: theme.spacing(2) }}>
                            <Tab label="Campaign" {...a11yProps(0)} />
                            <Tab label="Search" {...a11yProps(1)} />
                            <Tab label="Top Cities" {...a11yProps(2)} />
                            <Tab label="Notes" {...a11yProps(4)} />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <div>
                                <Grid container spacing={2} alignContent="space-between">

                                    <Grid item xl={3} sm={3} xs={12}>
                                        <ChartCard
                                            title="Impressions"
                                            data={data.impressions}
                                            client={props.client}
                                            color={props.themeColor}
                                        />
                                    </Grid>
                                    <Grid item xl={3} sm={3} xs={12}>
                                        <ChartCard
                                            title="Clicks"
                                            data={data.clicks}
                                            client={props.client}
                                            color={props.themeColor}
                                        />

                                    </Grid>
                                    <Grid item xl={3} sm={3} xs={12}>
                                        <ChartCard
                                            title="CTR"
                                            data={data.ctr}
                                            client={props.client}
                                            color={props.themeColor}
                                        />
                                    </Grid>
                                    <Grid item xl={3} sm={3} xs={12}>
                                        <ChartCard
                                            title="CPC"
                                            data={data.cpc}
                                            client={props.client}
                                            color={props.themeColor}
                                        />

                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} alignContent="space-between">

                                    <Grid item xl={3} sm={3} xs={12} >
                                        <ChartCard
                                            title="Conversions"
                                            data={data.conversions}
                                            client={props.client}
                                            color={props.themeColor}
                                        />

                                    </Grid>
                                    <Grid item xl={3} sm={3} xs={12}>
                                        <ChartCard
                                            title="Revenue"
                                            data={data.revenue}
                                            client={props.client}
                                            color={props.themeColor}
                                        />


                                    </Grid>
                                    <Grid item xl={3} sm={3} xs={12}>
                                        <ChartCard
                                            title="Conversion Rate"
                                            data={data.convrate}
                                            client={props.client}
                                            color={props.themeColor}
                                        />

                                    </Grid>
                                    <Grid item xl={3} sm={3} xs={12}>
                                        <ChartCard
                                            title="Avg Gift"
                                            data={data.avgGift}
                                            client={props.client}
                                            color={props.themeColor}
                                        />

                                    </Grid>
                                </Grid>
                            </div>
                        </TabPanel>




                        <TabPanel value={value} index={0}>
                            <Grid container spacing={2} alignContent="space-between">
                                <Grid item sm={12} xs={12} >

                                    {(campaignData ?

                                        <TableCard
                                            options={{
                                                rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                cellStyle: {
                                                    border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                    padding: theme.spacing(1)
                                                },
                                                headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 0), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                paging: (campaignData.length > 5 ? true : false),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            title="Campaigns"
                                            data={campaignData}
                                            count={(campaignData.length > 5 ? 5 : campaignData.length)}
                                            client={props.client}
                                            color={props.themeColor}
                                            columns={[
                                                { title: "Campaign Name", field: "CAMPAIGN", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 100, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CAMPAIGN}</Typography> },
                                                { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.secondary, 0),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.secondary, 0),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)

                                                    },
                                                    title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)
                                                    }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                },
                                                { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                { title: "Avg. Gift", field: "avgGift", type: 'currency' },
                                                { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS)).toFixed(2)} displayType="text" prefix="$" /> },
                                                { title: "Cost", field: "COST", type: 'currency',hidden: (admintoken>=8 ? false : true) },
                                            ]}
                                        />
                                        : <div ><Typography variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}

                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <Grid container spacing={2} alignContent="space-between">
                                <Grid item sm={12} xs={12} >
                                    {(keywordData ?
                                        <div>
                                            <TableCard
                                                options={{
                                                    rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                    cellStyle: {
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                        padding: theme.spacing(1)
                                                    },
                                                    headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 0), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                    grouping: false,
                                                    searchFieldVariant: "outlined",
                                                    exportButton: true,
                                                    exportAllData: true,
                                                    search: true,
                                                    paging: (campaignData.length > 5 ? true : false),
                                                    exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                    pageSizeOptions: [10, 25, 50, 100],
                                                    showTitle: false,
                                                    toolbar: true,
                                                    sort: true
                                                }}
                                                title="Search"
                                                data={keywordData}
                                                count={5}
                                                client={props.client}
                                                color={props.themeColor}

                                                columns={[
                                                    { title: "TERM", field: "SEARCH", render: rowdata => <Typography style={{ minWidth: 300, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.SEARCH}</Typography> },
                                                    { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                    {
                                                        cellStyle: {
                                                            backgroundColor: lightenHexColor(props.themeColor.secondary, 0),
                                                            textAlign: 'center',
                                                            padding: theme.spacing(1)
    
                                                        }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                    },
                                                    {
                                                        cellStyle: {
                                                            backgroundColor: lightenHexColor(props.themeColor.secondary, 0),
                                                            textAlign: 'center',
                                                            padding: theme.spacing(1)
    
                                                        }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                    },
                                                    {
                                                        cellStyle: {
                                                            backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                            textAlign: "center",
                                                            padding: theme.spacing(1)
    
                                                        },
                                                        title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                    },
                                                    {
                                                        cellStyle: {
                                                            backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                            textAlign: "center",
                                                            padding: theme.spacing(1)
                                                        }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                    },
                                                    { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                    { title: "Avg. Gift", field: "avgGift", type: 'currency' },
                                                    { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS)).toFixed(2)} displayType="text" prefix="$" /> },
                                                    { title: "Cost", field: "COST", type: 'currency',hidden: (admintoken>=8 ? false : true) },
                                                ]}
                                            /></div> : <div style={{ display: 'flex', justifyContent: "center" }}><Typography align='center'>Loading Bing Data</Typography><LinearProgress style={{ margin: theme.spacing(8) }}></LinearProgress></div>)}

                                </Grid>

                                <Grid item sm={12} xs={12}>
                                    {(topConversionData ?
                                        <div>

                                            <TableCard
                                                title="KEYWORD"
                                                data={topConversionData}
                                                count={(topConversionData.length > 5 ? 5 : topConversionData.length)}
                                                options={{
                                                    rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                    cellStyle: {
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                        padding: theme.spacing(1)
                                                    },
                                                    headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 0), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                    grouping: false,
                                                    searchFieldVariant: "outlined",
                                                    exportButton: true,
                                                    exportAllData: true,
                                                    search: true,
                                                    paging: (topConversionData.length > 5 ? true : false),
                                                    exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                    pageSizeOptions: [10, 25, 50, 100],
                                                    showTitle: false,
                                                    toolbar: true,
                                                    sort: true
                                                }}
                                                client={props.client}
                                                color={props.themeColor}
                                                columns={[
                                                    { title: "KEYWORD", field: "SEARCH", render: rowdata => <Typography style={{ minWidth: 450, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.SEARCH}</Typography> },
                                                    { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.secondary, 0),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.secondary, 0),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)

                                                    },
                                                    title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)
                                                    }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                },
                                                { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                { title: "Avg. Gift", field: "avgGift", type: 'currency' },
                                                { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS)).toFixed(2)} displayType="text" prefix="$" /> },
                                                { title: "Cost", field: "COST", type: 'currency',hidden: (admintoken>=8 ? false : true) },
                                                ]}
                                            />
                                        </div> : <div style={{ display: 'flex', justifyContent: "center" }}><Typography align='center'>Loading Bing Data</Typography><LinearProgress style={{ margin: theme.spacing(8) }}></LinearProgress></div>)}
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Grid container spacing={2} alignContent="space-between">

                                <Grid item sm={12} xs={12} >
                                    {(regionData ?
                                        <div>

                                            <TableCard
                                                title="Top Cities"
                                                data={regionData}
                                                count={(regionData.length > 5 ? 5 : regionData.length)}
                                                options={{
                                                    rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
                                                    cellStyle: {
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                        padding: theme.spacing(1)
                                                    },
                                                    headerStyle: { textAlign: "center", backgroundColor: lightenHexColor(props.themeColor.primary, 0), color: "#fff", fontSize: fontSize, border: '1px solid ' + props.themeColor.primary, paddingTop: 5, paddingBottom: 5 },
                                                    grouping: false,
                                                    searchFieldVariant: "outlined",
                                                    exportButton: true,
                                                    exportAllData: true,
                                                    search: true,
                                                    paging: (regionData.length > 10 ? true : false),
                                                    exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                    pageSizeOptions: [10, 25, 50, 100],
                                                    showTitle: false,
                                                    toolbar: true,
                                                    sort: true
                                                }}
                                                client={props.client}
                                                color={props.themeColor}
                                                columns={[
                                                    { title: "CITY", field: "CITY", render: rowdata => <Typography style={{ minWidth: 100, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CITY}</Typography> },
                                                    { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.secondary, 0),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.secondary, 0),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(1)

                                                    }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)

                                                    },
                                                    title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.primary, 60),
                                                        textAlign: "center",
                                                        padding: theme.spacing(1)
                                                    }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                                },
                                                { title: "Revenue", field: "REVENUE", type: 'currency' },
                                                { title: "Avg. Gift", field: "avgGift", type: 'currency' },
                                                { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS)).toFixed(2)} displayType="text" prefix="$" /> },
                                                { title: "Cost", field: "COST", type: 'currency',hidden: (admintoken>=8 ? false : true) },
                                            ]}
                                            /></div> : <div style={{ display: 'flex', justifyContent: "center" }}><Typography align='center'>Loading Bing Data</Typography><LinearProgress style={{ margin: theme.spacing(8) }}></LinearProgress></div>)}


                                </Grid>
                            </Grid>
                        </TabPanel>


                        <TabPanel value={value} index={3}>
                            <Grid item sm={12} xs={12} >
                            <Comments eDate={props.eDate} sDate={props.sDate} color={props.color} admintoken={admintoken} view={(admintoken>=8 ? "5af0a295-8b57-4ccb-8cc8-d54e5bb278e6" : "a7edd5c0-7a77-49ed-86ad-6fe3066a0f59")} type="bing"></Comments>
                            </Grid>
                        </TabPanel>



                    </div>
                    : <div style={{ paddingTop: theme.spacing(5) }} ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading {props.title} Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(8) }}></LinearProgress></div>)}


        

        </div >
    );
};

Bing.propTypes = {

};

export default Bing;
