import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import themeFile from '../theme.json';
import { setCookie } from '../dwi-functions';
import logo from '../Digital-Dashboard-Logo-FINAL.png';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Backdrop } from '@material-ui/core';
import appVars from '../appVars.json';
import { jwtDecode } from "jwt-decode";


import images from '../pexels-burak-the-weekender-186464.jpg';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href={appVars.companyUrl}>
                {appVars.companyName}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        width: '100vw'
    },
    image: {
        backgroundImage: 'url(' + images + ')',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    googleButton: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#4285F4',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#357AE8',
        },
    },
    logo: {
        maxWidth: '300px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    rightside: {
        background: themeFile.palette.appBar.backgroundSign,
        paddingTop: theme.spacing(0),
        [theme.breakpoints.down('lg')]: {
            paddingTop: '10%'
        }
    }
}));

export default function SignInSide() {
    const classes = useStyles();
    const theme = createTheme(themeFile);
    const [hasError, setErrors] = useState(false);
    const [open, setOpen] = useState(false);

    async function postData(url, data) {
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setErrors(false);
    };

    const handleGoogleSuccess = async (response) => {
        console.log(jwtDecode(response.credential).email);
        postData(appVars.api_url + '/validate', JSON.stringify({ token: response.credential,email:jwtDecode(response.credential).email}))
            .then(data => {
                if (data.status === 200 && data.uid.length === 36) {
                    setCookie('_bu', data.uid, 7);
                    localStorage.setItem('_bu', data.uid);
                    localStorage.setItem('gravatar', data.gravatar);
                    setCookie('gravatar', data.gravatar, 7);
                    sessionStorage.setItem('_buta', data.admin.access);
                    setCookie('_buta', data.admin.access, 7);
                    sessionStorage.setItem('_buta_s', data.settings);
                    setCookie('_buta_s', data.settings, 7);
                    setTimeout(() => { setOpen(false); window.location = data.settings.redirect; }, 500);
                } else {
                    setErrors(true);
                }
            });
    };

    const handleGoogleFailure = (response) => {
        console.error('Google Sign-In was unsuccessful. Try again later.');
    };

    return (
        <GoogleOAuthProvider clientId={appVars.google_client_id}>
            <ThemeProvider theme={theme}>
                <Grid container component="main" className={classes.root}>
                    <CssBaseline />
                    <Grid item xs={false} sm={false} md={8} xl={7} className={classes.image} />
                    <Grid item xs={12} sm={12} md={4} xl={5} justifyContent='center' alignContent='center' alignItems='center' component={Paper} className={classes.rightside} elevation={6} square>
                        <div className={classes.paper}>
                            <img src={logo} className={classes.logo} alt="DigitalWerks Logo" />
                            <form className={classes.form} noValidate>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => {
                                        setOpen(true);
                                        const formData = {
                                            "passwd": document.getElementById('password').value,
                                            "email": document.getElementById('email').value
                                        };
                                        postData(appVars.api_url + '/validate', JSON.stringify(formData))
                                            .then(data => {
                                                if (data.status === 200 && data.uid.length === 36) {
                                                    setCookie('_bu', data.uid, 7);
                                                    localStorage.setItem('_bu', data.uid);
                                                    localStorage.setItem('gravatar', data.gravatar);
                                                    setCookie('gravatar', data.gravatar, 7);
                                                    sessionStorage.setItem('_buta', data.admin.access);
                                                    setCookie('_buta', data.admin.access, 7);
                                                    sessionStorage.setItem('_buta_s', data.settings);
                                                    setCookie('_buta_s', data.settings, 7);
                                                    setTimeout(() => { setOpen(false); window.location = data.settings.redirect; }, 500);
                                                } else {
                                                    setErrors(true);
                                                }
                                            });
                                    }}
                                >
                                    Sign In
                                </Button>

                                <Box width="100%" textAlign="center" mt={2}>
                                    <Typography variant="body1" align="center">
                                        or
                                    </Typography>
                                    <GoogleLogin
                                        onSuccess={handleGoogleSuccess}
                                        onError={handleGoogleFailure}

                                    />
                                </Box>
                                <Box textAlign="center" mt={2}>
                                    <Link href="/password-reset" variant="body2">
                                        Forgot password?
                                    </Link>

                                    <Link style={{ display: 'none' }} href="/register" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Box>
                                <Box mt={5}>
                                    <Copyright />
                                </Box>
                            </form>
                        </div>
                    </Grid>
                </Grid>
                <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}  >
                    <Alert onClose={handleClose} severity="error">
                        Your email or password is incorrect, I can't let you in. 403: Access Denied!
                    </Alert>
                </Snackbar>
                <Backdrop open={open} className={classes.backdrop}>
                    <CircularProgress size={48} />
                </Backdrop>
            </ThemeProvider>
        </GoogleOAuthProvider>
    );
}
