import { AppBar, Card, CardContent, CardHeader, Grid, Typography, LinearProgress, Tabs, Tab, Box } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import themeFile from '../../theme.json';
import ChartCard from '../../../chart-card';
import { NumericFormat } from 'react-number-format';
import TableCard from '../../../table-card';
import { lightenHexColor, getCookie, darkenHexColor } from '../../../../dwi-functions';
import _ from 'lodash';
import appVars from '../../../../appVars.json';
import moment from 'moment';
import Comments from '../../../../system/control/comments';

var CurrencyFormat = require('react-currency-format');




const fontSize = '13px';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}






const Bing = (props) => {
    const [value, setValue] = useState(0);
    const [creativeData, setCreativeData] = useState();
    const [topConversionData, setTopConversionData] = useState();
    const [keywordData, setKeywordData] = useState();
    const [regionData, setRegionData] = useState();
    const [campaignData, setCampaignData] = useState();
    const [data, setData] = useState();
    const [admintoken, setAdmin] = useState(props.admintoken);


    const theme = createTheme({
        palette: {
            primary: {
                main: props.themeColor.primary, // Default primary color
            },
            secondary: {
                main: props.themeColor.secondary, // Default primary color
            },
        },
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchReportData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "aid": props.advertiser_id,
            "sdate": props.sDate,
            "edate": props.eDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res4 = await fetch(appVars.api_url + "/bing/data", requestOptions);
        //    console.log(props);
        res4
            .json()
            .then((res4) => {
                setData(res4[0]);

            })
            .catch((err) => {
                console.log(err);
            });

        const campTable = await fetch(appVars.api_url + "/bing/campaigns", requestOptions);
        //    console.log(props);
        campTable
            .json()
            .then((campaigns) => {
                setCampaignData(campaigns);
                //  console.log(campaigns);
            })
            .catch((err) => {
                console.log(err);
            });
        /*
                const creativeTable = await fetch(appVars.api_url+"/bing/creatives", requestOptions);
                //    console.log(props);
                creativeTable
                    .json()
                    .then((creatives) => {
                        setCreativeData(creatives);
        
                    })
                    .catch((err) => {
                        console.log(err);
                    });
        */


        const keywordTable = await fetch(appVars.api_url + "/bing/keyword", requestOptions);
        keywordTable
            .json()
            .then((keyword) => {


                setKeywordData(keyword[0]);
                setTopConversionData(keyword[1]);
                setRegionData(keyword[2]);

            })
            .catch((err) => {
                console.log(err);
            });

        /*
                const regionTable = await fetch(appVars.api_url + "/bing/region", requestOptions);
                regionTable
                    .json()
                    .then((region) => {
                        setRegionData(region);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
        
        */


    }

    useEffect(() => {
        // if(!data){
        fetchReportData();
        // console.log(data);
        //  }
    }, [props]);




    return (
        <div >



            {(data ?
                <div>

                    <AppBar variant="outlined" color='secondary' position="relative" style={{ margin: theme.spacing(0) }} >

                        <Grid container alignItems='center' >
                            <Grid item sm={10}>
                                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="standard" scrollButtons="auto" >
                                    <Tab label="Campaign" {...a11yProps(0)} />
                                    <Tab label="Notes" {...a11yProps(1)} />
                                </Tabs>

                            </Grid>
                            {(admintoken.token >= 8 ?
                                <Grid item sm={1}>
                                    <Typography>Cost: <NumericFormat decimalScale={2} value={data.cost.count} thousandSeparator="," displayType="text" prefix="$" /></Typography>
                                </Grid>
                                : '')}
                            {(admintoken.token >= 8 ?
                                <Grid item sm={1} style={{ display: (admintoken.token >= 8 ? 'initial' : 'none') }}>
                                    <Typography>ROAS: <NumericFormat decimalScale={2} value={((data.revenue.count / data.cost.count))} displayType="text" /></Typography>
                                </Grid>
                                : '')}
                        </Grid>



                    </AppBar>






                    <TabPanel value={value} index={0}>
                        <div>
                            <Grid container spacing={2} alignContent="space-between">

                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Impressions"
                                        data={data.impressions}
                                        client={props.client}
                                        color={props.themeColor}
                                    />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Clicks"
                                        data={data.clicks}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="CTR"
                                        data={data.ctr}
                                        client={props.client}
                                        color={props.themeColor}
                                    />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="CPC"
                                        data={data.cpc}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                            </Grid>

                            <Grid container spacing={2} alignContent="space-between">

                                <Grid item xl={3} sm={3} xs={12} >
                                    <ChartCard
                                        title="Conversions"
                                        data={data.conversions}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Revenue"
                                        data={data.revenue}
                                        client={props.client}
                                        color={props.themeColor}
                                    />


                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Conversion Rate"
                                        data={data.convrate}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard
                                        title="Avg Gift"
                                        data={data.avgGift}
                                        client={props.client}
                                        color={props.themeColor}
                                    />

                                </Grid>
                            </Grid>
                        </div>
    
                        <Grid container spacing={2} alignContent="space-between">
                            <Grid item sm={12} xs={12} >

                                {(campaignData ?

                                    <TableCard
                                        options={{

                                            grouping: false,
                                            searchFieldVariant: "outlined",
                                            exportButton: true,
                                            exportAllData: true,
                                            search: true,
                                            paging: (campaignData.length > 5 ? true : false),
                                            exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                            pageSizeOptions: [10, 25, 50, 100],
                                            showTitle: false,
                                            toolbar: true,
                                            sort: true
                                        }}
                                        title="Campaigns"
                                        data={campaignData}
                                        count={(campaignData.length > 5 ? 5 : campaignData.length)}
                                        client={props.client}
                                        themeColor={props.themeColor}
                                        columns={[
                                            {
                                                title: "Campaign",
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor("#cecece", 0),
                                                    textAlign: 'center',
                                                    width: 300,
                                                    fontSize: fontSize,
                                                    padding: theme.spacing(1),
                                                    border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0),
                                                },
                                                field: "CAMPAIGN", render: rowdata => <Typography align='left'>{rowdata.CAMPAIGN}</Typography>
                                            },
                                        ]}
                                    />
                                    : <div ><Typography color="secondary" variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}

                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignContent="space-between">
                            <Grid item sm={12} xs={12} >
                                {(keywordData ?
                                    <div>
                                        <TableCard
                                            options={{
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                paging: (keywordData.length > 5 ? true : false),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            title="Search"
                                            data={keywordData}
                                            count={(keywordData.length > 5 ? 5 : keywordData.length)}
                                            client={props.client}
                                            themeColor={props.themeColor}

                                            columns={[
                                                { title: "Term",
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor("#cecece", 0),
                                                    textAlign: 'center',
                                                    width: 300,
                                                    fontSize: fontSize,
                                                    padding: theme.spacing(1),
                                                    border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0),
                                                },
                                                 field: "SEARCH", render: rowdata => <Typography align='left' style={{ fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.SEARCH}</Typography> },
                                            ]}
                                        />
                                    </div> : <div ><Typography color="secondary" variant='h6' align='center'>Loading Search Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}

                            </Grid>

                            <Grid item sm={12} xs={12}>
                                {(topConversionData ?
                                    <div>

                                        <TableCard
                                            title="KEYWORD"
                                            data={topConversionData}
                                            count={(topConversionData.length > 5 ? 5 : topConversionData.length)}
                                            options={{
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                paging: (topConversionData.length > 5 ? true : false),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            client={props.client}
                                            themeColor={props.themeColor}
                                            columns={[
                                                { title: "KEYWORD",
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor("#cecece", 0),
                                                    textAlign: 'center',
                                                    width: 300,
                                                    fontSize: fontSize,
                                                    padding: theme.spacing(1),
                                                    border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0),
                                                },
                                                 field: "KEYWORD", render: rowdata => <Typography align='left' style={{ fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.KEYWORD}</Typography> },
                                            ]}
                                        />
                                    </div> : <div ><Typography color="secondary" variant='h6' align='center'>Loading Keyword Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item sm={12} xs={12} >
                                {(regionData ?
                                    <div>

                                        <TableCard
                                            title="Top Cities"
                                            data={regionData}
                                            count={(regionData.length > 5 ? 5 : regionData.length)}
                                            options={{
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                paging: (regionData.length > 10 ? true : false),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            client={props.client}
                                            themeColor={props.themeColor}
                                            columns={[
                                                { title: "City",
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor("#cecece", 0),
                                                    textAlign: 'center',
                                                    width: 300,
                                                    fontSize: fontSize,
                                                    padding: theme.spacing(1),
                                                    border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0),
                                                },
                                                 field: "CITY", render: rowdata => <Typography align='left' >{rowdata.CITY}</Typography> },
                                            ]}
                                        />
                                    </div> : <div ><Typography color="secondary" variant='h6' align='center'>Loading Region Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                            </Grid>
                        </Grid>
                    </TabPanel>



                    <TabPanel value={value} index={1}>
                        <Grid item sm={12} xs={12} >
                            <Comments eDate={props.eDate} sDate={props.sDate} themeColor={props.themeColor} admintoken={admintoken} view={(admintoken.token >= 6 ? "5af0a295-8b57-4ccb-8cc8-d54e5bb278e6" : "a7edd5c0-7a77-49ed-86ad-6fe3066a0f59")} type="bing"></Comments>
                        </Grid>
                    </TabPanel>



                </div> : <div style={{ margin: theme.spacing(8) }} ><Typography color="secondary" variant='h6' align='center'>Loading Bing Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}




        </div >
    );
};

Bing.propTypes = {

};

export default Bing;
