import { AppBar, Box, Button, Grid, Toolbar, Typography, Tab, Tabs, LinearProgress } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import appVars from '../../../appVars.json';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import 'date-fns';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Google from '../monthly-report/google';
import StackAdapt from '../monthly-report/stackadapt';
import Meta from '../monthly-report/meta';
import Bing from '../monthly-report/bing';
import MomentUtils from "@date-io/moment";
import { Print } from '@material-ui/icons';
import Registrations from '../../../clients/registrations';
import { lightenHexColor, darkenHexColor } from '../../../dwi-functions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}





const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //  maxWidth: 1600,
        margin: 'auto'
    },
    data: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    content: {
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
    }
}));

/**
 *Gets the ID needed for the look up, its the last section of the URL
 *
 * @param {*} thePath
 */
const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
var id = getLastItem(window.location.href);
//console.log(id);

const ClientReport = (props) => {
    var _d = new Date();
    //  console.log(props);
    _d.setDate(_d.getDate() - 7);
    //    console.log(_d.toLocaleDateString());
    const [selectedDateS, setSelectedDateS] = useState(props.sdate);
    const [selectedDateE, setSelectedDateE] = useState(props.edate);
    //const [selectedDateS, setSelectedDateS] = useState('2023-01-01');
    //const [selectedDateE, setSelectedDateE] = useState('2023-12-31');
    const theme = useTheme();
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [client, setClient] = useState({});
    const [brandColor, setBrandColor] = useState({ "primary": "#FFF", "secondary": "#5791a0" });
    const [reportProfile, setReportProfile] = useState({});
    const [adsettings, setAdSettings] = useState();
    const [tabs, setTabs] = useState({});
    const admintoken = props.admintoken.token;

//console.log(brandColor);
    const MyTabCreator = ({ field, form, ...props }) => {

        var e = "";

        switch (props.tab) {
            case "meta":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <Meta
                            title='Meta formerly Facebook'
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.meta.id}
                            delta="15"
                            admintoken={admintoken}
                        />
                        : <LinearProgress style={{ margin: theme.spacing(8) }}></LinearProgress>
                    )}
                </TabPanel>
                break;

            case "google_paid":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <Google
                            onParentStateChange={handleDateChangeS}
                            title='Google Paid'
                            which="Paid"
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.google_paid.id}
                            delta="15"
                            type="SEARCH"
                            admintoken={admintoken}
                        />
                        : <LinearProgress style={{ margin: theme.spacing(8) }}></LinearProgress>
                    )}
                </TabPanel>
                break;
            case "google_grant":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <Google
                            onParentStateChange={handleDateChangeS}
                            title='Google Grant'
                            which="Grant"
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.google_grant.id}
                            delta="15"
                            type="SEARCH"
                            admintoken={admintoken}
                        />
                        : <LinearProgress style={{ margin: theme.spacing(8) }}></LinearProgress>
                    )}
                </TabPanel>
                break;

            case "bing":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <Bing
                            title='Bing'
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.bing.id}
                            delta="15"
                            admintoken={admintoken}
                        />
                        : <LinearProgress style={{ margin: theme.spacing(8) }}></LinearProgress>
                    )}
                </TabPanel>
                break;
            case "stackadapt":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <StackAdapt
                            title='StackAdapt'
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.stackadapt.id}
                            delta="15"
                            admintoken={admintoken}
                        />
                        : <LinearProgress style={{ margin: theme.spacing(8) }}></LinearProgress>
                    )}
                </TabPanel>
                break;
            case "donations":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <Registrations sdate={selectedDateS} edate={selectedDateE} clientID={id} pageSize={1000} ></Registrations>
                        : <LinearProgress style={{ margin: theme.spacing(8) }}></LinearProgress>
                    )}
                </TabPanel>
                break;





            default:
                break;
        }


        return (
            <div>{e}</div>
        );
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDateChangeS = (date) => {
        var _d = date.toISOString().split('T')[0];
        //console.log(_d);
        setSelectedDateS(_d);
        fetchData();
    };


    const handleDateChangeE = (date) => {
        var _d = date.toISOString().split('T')[0];
        setSelectedDateE(_d);
        fetchData();
    };


    async function fetchData() {

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };



        const res2 = await fetch(appVars.api_url + "/tagmanager/" + id, requestOptions);
        res2
            .json()
            .then((res2) => {
                setClient(res2);
            })
            .catch((err) => {
                console.log(err);
            });






        const res3 = await fetch(appVars.api_url + "/reportprofile/" + id, requestOptions);
        res3
            .json()
            .then((res3) => {
                setReportProfile(res3);
               // setBrandColor(JSON.parse(res3.color));
                setTabs(JSON.parse(res3.tabs));
                setAdSettings(JSON.parse(res3.settings).ad_sets);
                console.log(JSON.parse(res3.settings).ad_sets);
            })
            .catch((err) => {
                console.log(err);
            });
    }


    useEffect(() => {
        fetchData();
    }, [props]);


    return (
        <div style={{ minHeight: '100vh' }}>
            <div className={classes.root}>

                <Grid container spacing={0} className={classes.content}>
                    <Grid item xl={12} sm={12} >
                        <AppBar position="relative" variant="outlined" style={{ background: lightenHexColor(brandColor.primary, 10) }}>
                            {(tabs.tabs && adsettings ?
                                <Tabs orientation="horizontal" variant="standard" value={value} onChange={handleChange} aria-label="simple tabs example">
                                    {Object.entries(adsettings).map((tab, index) => {
                                        var g = tab[1];
                                        // console.log(g);
                                        var k = (g.id === "0" || g.id === null ?
                                            null :
                                            <Tab label={g['tablabel']} {...a11yProps(index)} />)
                                        return (
                                            k
                                        )
                                    })}


                                </Tabs>
                                : <div ><Typography variant='h6' align='center'>Loading Platforms</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(8) }}></LinearProgress></div>)}




                        </AppBar>
                    </Grid>
                    <Grid item xl={12} className={classes.data} >



                        {(adsettings ?
                            <div style={{ marginLeft: theme.spacing(0), marginRight: theme.spacing(0) }}>
                                {Object.entries(adsettings).map((tab, index) => {
                                    var g = tab[1];
                                    //console.log(index);
                                    var k = (g.id === "0" || g.id === null ?
                                        null :
                                        <MyTabCreator posi={index} tab={tab[0]}></MyTabCreator>
                                    )
                                    return (
                                        k
                                    )
                                })}
                            </div>
                            : <div ><Typography variant='h6' align='center'>Loading Tab Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(8) }}></LinearProgress></div>)}



                    </Grid>

                </Grid>

            </div>


        </div>
    );
};

ClientReport.propTypes = {

};

export default ClientReport;
