import React, { useEffect, useState } from 'react'; // Combine React imports
import { Grid, Link, LinearProgress, Typography } from '@material-ui/core';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import TableCard from '../../reports/table-card';

import { NumericFormat } from 'react-number-format';
import appVars from '../../appVars.json';
import { lightenHexColor } from '../../dwi-functions';
import MaterialTable from 'material-table';

const useStyles = makeStyles((theme) => ({
    root: {

        flexGrow: 1,
    },
    tableRowRoot: {
        '&:nth-of-type(odd)': {
            backgroundColor: "white",
        },
        '&:nth-of-type(even)': {
            backgroundColor: "grey",
        },
    },
    content: {
        margin: theme.spacing(1),
    },
    media: {
        height: 'auto',
        maxHeight: 50,
        textAlign: "left",
    },
    pad: {
        padding: '1em',
    },
    logoArea: {
        background: `linear-gradient(176deg, ${theme.palette.primary.light} 0%, #fff 80%)`,
    },

}));

const PrimaryClient = () => {
    const classes = useStyles();
    const [brandColor, setBrandColor] = useState({ "primary": "#fff", "secondary": "#5791a0" });
    const theme = createTheme({
        palette: {
            primary: {
                main: brandColor.primary, // Assuming darkenHexColor returns a valid color
            },
            secondary: {
                main: brandColor.secondary, // Assuming the same
            },
        },
    });



    const headerStyleObj = {
        textAlign: "center",
        backgroundColor: lightenHexColor(brandColor.secondary, 0),
        color: lightenHexColor(brandColor.primary, 0),
        fontSize: '13px',
        border: '1px solid ' + brandColor.secondary,
        paddingTop: 5,
        paddingBottom: 5,
        maxWidth: 75
    };



    const [clients, setClients] = useState([]); // Initialized as an empty array
    const [isLoading, setIsLoading] = useState(true);

    async function fetchData() {
        fetch(`${appVars.api_url}/clients`)
            .then((response) => response.json())
            .then((res) => {
                setClients(res);
                setIsLoading(false);
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        fetchData();
    }, []);

    // Optional: useEffect to log clients on change
    useEffect(() => {
        // console.log(clients);
    }, [clients]);

    return (
        <div className={classes.content}>
            <Typography variant="h5" color="primary" style={{ borderBottom: '1px solid', borderBottomColor: brandColor.primary }} gutterBottom>
                Agencies
            </Typography>
            <Grid container spacing={1}>
                {isLoading ? (
                    <Grid item xs={12}>
                        <LinearProgress style={{ margin: theme.spacing(8) }} />
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <MaterialTable
                            className={classes.tableRowRoot}
                            themeColor={brandColor}
                            data={clients}
                            options={{
                                cellStyle: { border: '1px solid ' + lightenHexColor(brandColor.primary, 30), textAlign: 'center' },
                                headerStyle: headerStyleObj,
                                grouping: false,
                                searchFieldVariant: "standard",
                                exportButton: true,
                                exportAllData: true,
                                search: false,
                                paging: false,
                                pageSize: 4,
                                pageSizeOptions: [10, 25, 50, 100],
                                showTitle: false,
                                toolbar: false,
                                header: true,
                                sort: true,
                            }}
                            columns={[
                                //  { title: "Platform", field: "name", render: rowdata => <img className={classes.media} src={appVars.api_url + '/imageFiles/' + rowdata.clientId + '.png'} alt='' ></img>, cellStyle: { border: '1px solid ' + lightenHexColor(brandColor.primary, 0), textAlign: 'left',background:brandColor.primary } },
                                { title: "Platform", field: "name", render: rowdata => <div><Link href={'/clientview/' + rowdata.clientId}><Typography color="primary" align='left' >{rowdata.clientname}</Typography></Link></div>, cellStyle: { border: '1px solid ' + lightenHexColor(brandColor.primary, 10), textAlign: 'left', backgroundColor: lightenHexColor(brandColor.primary, 10) } },
                                { title: "# Clients", field: "clientCount", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.clientCount ?? 0} displayType="text" /> },
                                { title: "Google Paid", field: "gp", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.gp ?? 0} displayType="text" /> },
                                { title: "Google Grant", field: "gg", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.gg ?? 0} displayType="text" /> },
                                { title: "Meta", field: "meta", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.meta ?? 0} displayType="text" /> },
                                { title: "Bing", field: "bing", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.bing ?? 0} displayType="text" /> },
                                { title: "StackAdapt", field: "sa", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.sa ?? 0} displayType="text" /> },
                            ]}
                        />
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default PrimaryClient;
