import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { AppBar, Box, Breadcrumbs, Link, Tabs, Tab, TextField, Toolbar, Typography } from '@material-ui/core';
import appVars from '../../appVars.json';
import { useTheme } from '@material-ui/core/styles';
import UserTable from '../../UserTable';
import SiteTypes from "../SiteTypes";
import { useToken } from '../../Login/TokenProvider';
import { Redirect } from 'react-router-dom';
import { getCookie } from '../../dwi-functions';

const av = Object.entries(appVars);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const MyAppVars = ({ field, form, ...props }) => {
    return <div>
        {
            av.map((entry) => (
                <TextField key={entry[1]} label={entry[0]} type="text" InputLabelProps={{ shrink: true }} fullWidth value={entry[1]}></TextField>
            ))}

    </div>;
};





const ControlPanel = (props) => {


    const [value, setValue] = React.useState(0);
    const [step, setstep] = useState('Global Settings');
    const theme = useTheme();
    const token = useToken();
    const [admintoken, setadmintoken] = useState(props.admintoken);

    const handleChange = (event, newValue) => {



        switch (newValue) {
            case 1:
                setstep('Ecommerce Platforms')
                break;
            case 2:
                setstep('Web Site Platforms')
                break;

            default:
                setstep('Global Settings')
                break;
        }




        setValue(newValue);
    };



    useEffect(() => {



    }, []);



    return (
        <div>
            {(admintoken >= 10 ?
                <div>
                    <AppBar style={{ background: theme.palette.text.hint }} position="static">
                        <Toolbar>
                            <Breadcrumbs style={{ width: '100%' }} aria-label="breadcrumb">
                                <Link color="textPrimary" href="/" >
                                    Dashboard
                                </Link>
                                <Link color="textPrimary" href={"/control/"} >
                                    Settings
                                </Link>
                                <Typography color="primary" variant="subtitle1" >{step}</Typography>
                            </Breadcrumbs>
                        </Toolbar>
                    </AppBar>
                    <AppBar position="static" style={{ background: theme.palette.primary.dark }}>
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="Global Settings" {...a11yProps(0)} />
                            <Tab label="ECommerce Platforms" {...a11yProps(1)} />
                            <Tab label="Web Site Platforms" {...a11yProps(2)} />
                            <Tab label="Users" {...a11yProps(3)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>

                        <div style={{ padding: theme.spacing(1) }}>
                            <MyAppVars ></MyAppVars>
                        </div>

                    </TabPanel>
                    <TabPanel value={value} index={1}>



                        <div style={{ padding: theme.spacing(1) }}>
                            <SiteTypes type="ecommerce"></SiteTypes>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>


                        <div style={{ padding: theme.spacing(1) }}>
                            <SiteTypes></SiteTypes>
                        </div>
                    </TabPanel>

                    <TabPanel value={value} index={3}>
                        <UserTable></UserTable>
                    </TabPanel>

                </div>

                : <Redirect to="/" />
            )}
        </div>
    );
};

ControlPanel.propTypes = {

};

export default ControlPanel;
